<template>
  <v-app class="main">
    <v-main>
      <router-view />
    </v-main>
    <!-- <div
      class="position-fixed d-flex align-items-center fixed-bottom z-2 px-2"
      style="z-index: 1040;"
      v-if="globalError"
    >
      <v-alert class="alert-error" type="error">
        Nous n'arrivons pas a contacter le serveur. Veuillez reessayer plus tard
      </v-alert>
    </div> -->
    <!-- <TheFooter></TheFooter> -->
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  data: () => ({
    //
  }),
  mounted() {},
  methods: {},
  watch: {
    authenticated(value, oldvalue) {},
  },
  computed: {
    ...mapGetters(['authenticated', 'globalError']),
  },
}
</script>
<style>
@import 'https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css';
</style>
