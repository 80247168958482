const nationalyties = require('./nationalyties.json');


export function getAlphaCode(nationallity) {

    let item = nationalyties.filter(function(element) {
        return element.nationalite_fr_fr == nationallity;
    });
    return item[0].alpha3;

}