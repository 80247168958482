/* eslint-disable vue/no-side-effects-in-computed-properties */
<template>
  <MainLayout-component>
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-12">
        <div class="text-center mt-2">
          <v-snackbar
            v-model="editSnackbar"
            :color="editSnackbarColor"
            :timeout="3000"
            style="z-index: 1800"
            :top="true"
          >
            {{ snackbar_text }}
          </v-snackbar>
        </div>
        <v-col class="cols" cols="12" sm="12" md="12">
          <v-card
            class="d-flex align-center justify-center mt-2 pa-4 mx-auto"
            min-height="86"
            outlined
          >
            <div class="text-center">
              {{ $t("title.agent_list") }}
            </div>
          </v-card>
        </v-col>
        <v-data-table
          :headers="getHeaders"
          :items="agents"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items-per-page="15"
          class="elevation-2"
          :search="search"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title> </v-toolbar-title>
              <v-row>
                <v-col cols="12" md="9">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('fields.search_text')"
                    single-line
                    class="p-2ss"
                    hide-details
                  ></v-text-field
                ></v-col>
                <v-col cols="12" md="3">
                  <v-dialog
                    :eager="true"
                    v-model="dialog"
                    style="z-index: 1500"
                    max-width="800px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        block
                        color="primary"
                        dark
                        class="mb-4"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ $t("btn.new_agent") }}
                      </v-btn>
                    </template>
                    <v-card>
                      <div class="text-center">
                        <v-snackbar
                          v-model="snackbar"
                          :color="snackbarColor"
                          :timeout="3000"
                          :top="true"
                        >
                          {{ snackbar_text }}
                        </v-snackbar>
                      </div>
                      <v-card-text>
                        <v-container fluid fill-height>
                          <div class="d-flex">
                            <v-card-title
                              class="w-100 card-title mb-2 justify-center"
                            >
                              {{ $t("title.register") }}
                            </v-card-title>
                          </div>

                          <v-form :eager="true" ref="agentform">
                            <v-row>
                              <v-alert v-if="message" type="error">
                                {{ message }}
                              </v-alert>

                              <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                  :label="$t('auth.firstname')"
                                  prepend-icon="mdi-account"
                                  v-model="editedItem.firstname"
                                  required
                                  @blur="$v.editedItem.firstname.$touch()"
                                  :error-messages="firstNameErrors"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                  :label="$t('auth.lastname')"
                                  prepend-icon="mdi-account"
                                  v-model="editedItem.lastname"
                                  @blur="$v.editedItem.lastname.$touch()"
                                  required
                                  :error-messages="lastNameErrors"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  :label="$t('auth.email')"
                                  prepend-icon="mdi-email"
                                  v-model="editedItem.email"
                                  @blur="$v.editedItem.email.$touch()"
                                  required
                                  :disabled="email_desabled"
                                  :error-messages="emailErrors"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <vue-phone-number-input
                                  class="mt-4 mb-4"
                                  ref="phoneinput"
                                  default-country-code="CM"
                                  required
                                  @blur="$v.editedItem.phonenumber.$touch()"
                                  v-model="editedItem.phonenumber"
                                  :error-messages="phonenumberErrors"
                                />
                              </v-col>
                              <v-col>
                                <v-autocomplete
                                  v-model="editedItem.gender"
                                  required
                                  filled
                                  item-text="name"
                                  item-value="value"
                                  @blur="$v.editedItem.gender.$touch()"
                                  :items="genders"
                                  :label="$t('fields.gender')"
                                  :error-messages="genderErrors"
                                ></v-autocomplete>
                              </v-col>
                              <v-col>
                                <v-autocomplete
                                  class="d-flex ml-auto"
                                  cols="12"
                                  v-model="editedItem.role"
                                  :items="roles_items"
                                  filled
                                  item-text="name"
                                  item-value="id"
                                  @blur="$v.editedItem.role.$touch()"
                                  required
                                  label="Role"
                                  :error-messages="roleErrors"
                                ></v-autocomplete>
                              </v-col>
                              <v-col>
                                <v-autocomplete
                                  class="d-flex ml-auto"
                                  cols="12"
                                  v-model="editedItem.unite"
                                  :items="unites_items"
                                  item-text="name"
                                  item-value="id"
                                  filled
                                  :readonly="level3"
                                  @blur="$v.editedItem.unite.$touch()"
                                  required
                                  label="Unite"
                                  :error-messages="uniteErrors"
                                ></v-autocomplete>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">
                          {{ $t("btn.cancel") }}
                        </v-btn>
                        <v-btn
                          :loading="isLoading"
                          color="primary"
                          large
                          :disabled="can_submit"
                          @click.prevent="save"
                          type="submit"
                          text
                        >
                          {{ $t("btn.register") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog
                    style="z-index: 1500"
                    v-model="dialogDelete"
                    max-width="500px"
                  >
                    <v-card>
                      <v-card-title
                        class="text-center"
                        style="text-align: center"
                        v-if="agents[editedIndex]"
                      >
                        {{
                          agents[editedIndex].lastname +
                          " " +
                          agents[editedIndex].firstname
                        }}
                      </v-card-title>
                      <p class="text-center text-h8 p-2">
                        {{ $t("validations.confirmation_block") }}
                      </p>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">
                          {{ $t("btn.cancel") }}
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          :loading="isLoading"
                          @click="blockOrDeblockAgent"
                        >
                          {{ $t("btn.valide") }}
                        </v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon
              color="red"
              v-if="item.deleted_at != null"
              small
              @click="blockAgent(item)"
            >
              mdi-lock
            </v-icon>
            <v-icon
              color="green"
              v-if="item.deleted_at == null"
              small
              @click="blockAgent(item)"
            >
              mdi-lock-open
            </v-icon>
          </template>

          <template v-slot:no-data>
            <v-col>
              <i style="font-size: 30px" class="fa-solid fa-circle-info"></i>
              <p>{{ $t("title.no_data") }}</p>
            </v-col>
            <v-col>
              <v-btn text color="primary" @click="getAgents">
                {{ $t("btn.reset") }}
              </v-btn>
            </v-col>
          </template>
        </v-data-table>
      </div>
    </div>
  </MainLayout-component>
</template>
<script>
import MainLayout from "../MainLayout.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email, helpers } from "vuelidate/lib/validators";
import i18n from "../../i18n";
const nameRegex = helpers.regex(
  'alphaNum',
  /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/,
)

export default {
  name: "UserList",
  mixins: [validationMixin],
  validations: {
    editedItem: {
      email: { required, email },
      firstname: { required, nameRegex },
      lastname: { required, nameRegex },
      unite: { required },
      phonenumber: { required },
      role: { required },
      gender: { required },
    },
  },
  components: {
    "MainLayout-component": MainLayout,
    "vue-phone-number-input": VuePhoneNumberInput,
  },
  watch: {
    "editedItem.role"(val) {
      console.log(val);

      if (val == 5) {
        this.editedItem.unite = 1;
        this.level3 = true;
        this.unites_items = this.unites_items_persist;
      } else {
        this.unites_items = this.filter_unites;
        this.level3 = false;
      }
    },
    registered(value) {
      if (value === true) {
        this.$store.dispatch("clear");
      }
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  data: () => ({
    filter_unites: null,
    unites_items_persist: null,
    level3: false,
    block_status: null,
    isLoading: false,
    snackbar: false,
    editSnackbar: false,
    snackbarColor: "default",
    editSnackbarColor: "default",
    email_desabled: false,
    snackbar_text: "",
    search: null,
    sortBy: "created_at",
    sortDesc: true,
    genders: [
      {
        name: "F",
        value: "F",
      },
      {
        name: "M",
        value: "M",
      },
    ],
    gender: null,
    roles_items: null,
    unites_items: null,
    errorMessages: {},
    message: null,
    dialog: false,
    dialogDelete: false,
    headers: [],
    agents: [],
    editedIndex: -1,
    editedItem: {
      unite_traitements_id: null,
      nationalite: null,
      unite: null,
      role: null,
      roles_value: null,
      firstname: null,
      lastname: null,
      phonenumber: null,
      email: null,
      gender: null,
    },
    defaultItem: {
      unite_traitements_id: null,
      nationalite: null,
      unite: null,
      roles_value: null,
      role: null,
      firstname: null,
      lastname: null,
      phonenumber: null,
      email: null,
      gender: null,
    },
  }),
  computed: {
    can_submit() {
      if (
        this.$v.editedItem.lastname.$invalid ||
        this.$v.editedItem.firstname.$invalid ||
        this.$v.editedItem.email.$invalid ||
        this.$v.editedItem.gender.$invalid ||
        this.$v.editedItem.role.$invalid ||
        this.$v.editedItem.phonenumber.$invalid ||
        this.$v.editedItem.unite.$invalid
      ) {
        return true;
      } else {
        return false;
      }
    },
    getHeaders() {
      this.headers = [
        {
          text: i18n.t("headers.firstname"),
          align: "",
          value: "firstname",
        },
        {
          text: i18n.t("headers.lastname"),
          align: "",
          value: "lastname",
        },
        {
          text: "Email",
          align: "",
          value: "email",
        },
        {
          text: i18n.t("headers.phonenumber"),
          align: "",
          value: "phonenumber",
        },
        { text: i18n.t("headers.role"), value: "roles[0].name" },
        { text: "Actions", value: "actions" },
      ];
      return this.headers;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.editedItem.email.$dirty) return errors;
      !this.$v.editedItem.email.email &&
        errors.push(i18n.t("validations.valid_email"));
      !this.$v.editedItem.email.required &&
        errors.push(i18n.t("validations.required_email"));
      return errors;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.editedItem.firstname.$dirty) return errors;
      !this.$v.editedItem.firstname.required &&
        errors.push(i18n.t("validations.required_firstname"));
      !this.$v.editedItem.firstname.nameRegex &&
        errors.push(i18n.t("validations.caracter_firstname"));
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.editedItem.lastname.$dirty) return errors;
      !this.$v.editedItem.lastname.required &&
        errors.push(i18n.t("validations.required_lastname"));
      !this.$v.editedItem.lastname.nameRegex &&
        errors.push(i18n.t("validations.caracter_lastname"));
      return errors;
    },
    phonenumberErrors() {
      const errors = [];
      if (!this.$v.editedItem.phonenumber.$dirty) return errors;
      !this.$v.editedItem.phonenumber.required &&
        errors.push(i18n.t("validations.required_phonenumber"));
      return errors;
    },
    // homecountryErrors() {
    //   const errors = []
    //   if (!this.$v.editedItem.homecountry.$dirty) return errors
    //   !this.$v.editedItem.homecountry.required &&
    //     errors.push(i18n.t('validations.required_homecountry'))
    //   return errors
    // },
    roleErrors() {
      const errors = [];
      if (!this.$v.editedItem.role.$dirty) return errors;
      !this.$v.editedItem.role.required &&
        errors.push(i18n.t("validations.role_required"));
      return errors;
    },
    uniteErrors() {
      const errors = [];
      if (!this.$v.editedItem.unite.$dirty) return errors;
      !this.$v.editedItem.unite.required &&
        errors.push(i18n.t("validations.role_required"));
      return errors;
    },
    genderErrors() {
      const errors = [];
      if (!this.$v.editedItem.gender.$dirty) return errors;
      !this.$v.editedItem.gender.required &&
        errors.push(i18n.t("validations.required_gender"));
      return errors;
    },
    ...mapGetters(["errors", "registered", "langLocal", "countries"]),
  },

  created() {
    this.$root.$i18n.locale = this.langLocal;

    this.getRoles();
    this.getUnites();
    this.getAgents();
  },

  methods: {
    async blockOrDeblockAgent() {
      this.isLoading = false;
      if (
        this.editedIndex > -1 &&
        this.agents[this.editedIndex]["deleted_at"] == null
      ) {
        const data = new FormData();
        data.append("id", this.agents[this.editedIndex].id);
        data.append("bloque", true);
        await this.$store
          .dispatch("updateAgents", data)
          .then((response) => {
            this.isLoading = false;
            this.editSnackbarColor = "success";
            this.snackbar_text = i18n.t("message.update_success");
            this.editSnackbar = true;
            console.log(this.snackbar);
          })
          .catch((error) => {
            this.isLoading = false;
            this.editSnackbarColor = "red";
            this.snackbar_text = i18n.t("message.error");
            this.editSnackbar = true;
          });
      }
      if (
        this.editedIndex > -1 &&
        this.agents[this.editedIndex]["deleted_at"] != null
      ) {
        const data = new FormData();
        data.append("id", this.agents[this.editedIndex].id);
        data.append("debloque", true);
        await this.$store
          .dispatch("updateAgents", data)
          .then((response) => {
            this.isLoading = false;
            this.editSnackbarColor = "success";
            this.snackbar_text = i18n.t("message.update_success");
            this.editSnackbar = true;
          })
          .catch((error) => {
            this.isLoading = false;
            this.editSnackbarColor = "red";
            this.snackbar_text = i18n.t("message.error");
            this.editSnackbar = true;
          });
      }
      this.dialogDelete = false;
      this.getAgents();
    },
    async getAgents() {
      await this.$store
        .dispatch("getAgents")
        .then((response) => {
          this.agents = response;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async getRoles() {
      await this.$store
        .dispatch("getRoles")
        .then((response) => {
          this.roles_items = response;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getUnites() {
      await this.$store
        .dispatch("getUnites")
        .then((response) => {
          this.unites_items = response;
          this.unites_items_persist = response;
          this.filter_unites = this.unites_items.filter(function (element) {
            return element.id != 1;
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    editItem(item) {
      this.email_desabled = true;
      this.editedIndex = this.agents.indexOf(item);

      item.gender = item.sexe;
      item.role = `${item.roles[0].id}`;
      item.unite = item.unite_traitements_id;

      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    blockAgent(item) {
      this.editedIndex = this.agents.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    blockAgentConfirm() {
      this.agents.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.level3 = false;
      this.email_desabled = false;
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    async save() {
      if (this.editedIndex > -1) {
        Object.assign(this.agents[this.editedIndex], this.editedItem);
        this.isLoading = true;
        this.$v.$touch();
        const data = new FormData();
        data.append("lastname", this.editedItem.lastname);
        data.append("firstname", this.editedItem.firstname);
        data.append("email", this.editedItem.email);
        data.append("phonenumber", this.editedItem.phonenumber);
        data.append("sexe", this.editedItem.gender);
        data.append("unite_traitements_id", this.editedItem.unite);
        data.append("roles", this.editedItem.role);
        data.append("id", this.agents[this.editedIndex].id);

        if (!this.$v.$invalid) {
          await this.$store
            .dispatch("updateAgents", data)
            .then((response) => {
              this.isLoading = false;
              this.snackbarColor = "success";
              this.snackbar_text = i18n.t("message.update_success");
              this.snackbar = true;
              setTimeout(() => {
                this.close();
              }, 3000);
            })
            .catch((error) => {
              this.isLoading = false;
              this.snackbarColor = "red";
              this.snackbar_text = i18n.t("message.error");
              this.snackbar = true;
            });
        }
        this.getAgents();
      } else {
        this.agents.push(this.editedItem);
        this.isLoading = true;
        this.$v.$touch();
        const data = new FormData();
        data.append("lastname", this.editedItem.lastname);
        data.append("firstname", this.editedItem.firstname);
        data.append("email", this.editedItem.email);
        data.append(
          "phonenumber",
          this.$refs.phoneinput.phoneFormatted.replace(/\s+/g, "")
        );
        data.append("sexe", this.editedItem.gender);
        data.append("unite_traitements_id", this.editedItem.unite);
        data.append("roles", this.editedItem.role);

        if (!this.$v.$invalid) {
          await this.$store
            .dispatch("register", data)
            .then((response) => {
              this.isLoading = false;
              this.$refs.agentform.reset();
              this.$v.$reset();
              this.editedItem.phonenumber = null;
              this.snackbarColor = "success";
              this.snackbar_text = i18n.t("message.add_success");
              this.snackbar = true;
              setTimeout(() => {
                this.close();
              }, 3000);
            })
            .catch((error) => {
              this.isLoading = false;
              this.snackbarColor = "red";
              this.snackbar_text = i18n.t("message.error");
              this.snackbar = true;
            });
        }
        this.getAgents();
      }
    },
  },
};
</script>
