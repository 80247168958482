<template>
  <MainLayout-component>
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="row justify-content-center">
          <div class="mt-2 col-lg-12">
            <v-snackbar
              v-model="editSnackbar"
              :color="editSnackbarColor"
              :timeout="3000"
              style="z-index: 1800"
              :top="true"
            >
              {{ snackbar_text }}
            </v-snackbar>
            <v-dialog fullscreen v-model="waiting_dialog">
              <div class="text-center">
                <v-overlay :value="overlay">
                  <v-progress-circular
                    :width="3"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </v-overlay>
              </div>
            </v-dialog>
            <v-row class="">
              <v-col class="text-center" cols="12" md="5">
                <v-text-field
                  v-model="code_input"
                  :label="$t('fields.text_search')"
                  required
                ></v-text-field>
              </v-col>

              <v-col class="text-center" cols="12" md="7">
                <v-row>
                  <v-col class="text-center" cols="12" md="6">
                    <v-btn
                      block
                      class="text-white"
                      :loading="isLoading"
                      color="light-blue"
                      @click.prevent="search_code"
                      :disabled="can_filter"
                      type="submit"
                    >
                      {{ $t("btn.search") }}
                    </v-btn>
                  </v-col>
                  <v-col class="text-center" cols="12" md="6">
                    <v-btn
                      block
                      class="text-white ml-1"
                      :disabled="cancel_search == true || isLoading"
                      color="red"
                      @click.prevent="
                        (cancel_search = true),
                          getDemands(),
                          (code_input = null)
                      "
                      type="submit"
                    >
                      {{ $t("btn.search_cancel") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="3">
                <v-btn
                  block
                  v-if="has_pending == false && cancel_search"
                  class="text-white ml-1"
                  color="primary"
                  @click.prevent="getTraitement()"
                  type="submit"
                >
                  {{ $t("btn.treat_demande") }}
                  <v-icon color="white" class="m-2"> mdi-reload </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <template v-if="has_pending == true && cancel_search">
              <v-tabs ref="toolbar" class="p-3" flat v-model="tab">
                <v-tabs-slider></v-tabs-slider>

                <v-tab> {{ $t("title.data") }} </v-tab>
                <v-tab> {{ $t("title.doc") }} </v-tab>
              </v-tabs>

              <v-tabs-items class="mt-5" v-model="tab">
                <v-tab-item>
                  <v-row class="p-3">
                    <v-col cols="12" sm="12">
                      <div style="background: #e0e0e0">
                        <v-divider> </v-divider>
                        <h4 style="color: #4a4343; margin-left: 10px">
                          {{ $t("title.personal_info") }}
                        </h4>
                        <v-divider> </v-divider>
                      </div>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="lastname"
                        required
                        readonly
                        filled
                        @blur="$v.lastname.$touch()"
                        name="lastname"
                        :label="$t('fields.lastname')"
                        :error-messages="lastnameErrors"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        :disabled="true"
                        readonly
                        v-model="firstname"
                        required
                        filled
                        @blur="$v.firstname.$touch()"
                        name="fristname"
                        :label="$t('fields.firstname')"
                        :error-messages="firstnameErrors"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-autocomplete
                        :disabled="true"
                        readonly
                        v-model="genre"
                        required
                        filled
                        item-text="name"
                        @blur="$v.genre.$touch()"
                        :items="genders"
                        :label="$t('fields.gender')"
                        :error-messages="genderErrors"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <div>
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              disabled
                              filled
                              required
                              readonly
                              v-model="birthday"
                              @blur="$v.birthday.$touch()"
                              :label="$t('fields.birthday')"
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              :error-messages="birthdateErrors"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            :disabled-dates="{ weekdays: [1, 7] }"
                            :locale="langLocal"
                            v-model="birthday"
                            :active-picker.sync="activePicker"
                            :max="birthday_min_date"
                            @change="save_birthday"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                    </v-col>

                    <v-col cols="12" sm="5">
                      <v-autocomplete
                        readonly
                        required
                        v-model="birthcountry"
                        @blur="$v.birthcountry.$touch()"
                        filled
                        :items="birthCountries"
                        :label="$t('fields.birth_country')"
                        :error-messages="birthcountryErrors"
                        item-text="nom_fr_fr"
                        item-value="nom_fr_fr"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-text-field
                        readonly
                        v-model="birthplace"
                        required
                        filled
                        @blur="$v.birthplace.$touch()"
                        name="birthplace"
                        :label="$t('fields.birth_place')"
                        :error-messages="birthplaceErrors"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        readonly
                        v-model="nationalityNow"
                        required
                        item-text="nationalite_fr_fr"
                        item-value="nationalite_fr_fr"
                        @blur="$v.nationalityNow.$touch()"
                        :items="nationalities"
                        filled
                        :label="$t('fields.current_natinalty')"
                        :error-messages="nationalityNowErrors"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        readonly
                        v-model="nationalityOrigin"
                        required
                        item-text="nationalite_fr_fr"
                        item-value="nationalite_fr_fr"
                        @blur="$v.nationalityOrigin.$touch()"
                        :items="nationalities"
                        filled
                        :label="$t('fields.coming_natinalty')"
                        :error-messages="nationalityOriginErrors"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        readonly
                        v-model="familienstand"
                        required
                        item-text="text"
                        item-value="name"
                        @blur="$v.familienstand.$touch()"
                        :items="familienStandList"
                        filled
                        :label="$t('fields.marial_status')"
                        :error-messages="familienstandErrors"
                      ></v-autocomplete>
                    </v-col>

                    <!-- additionnelles -->
                    <v-col cols="12" sm="12">
                      <div style="background: #e0e0e0">
                        <v-divider> </v-divider>
                        <h4 style="color: #4a4343; margin-left: 10px">
                          {{ $t("title.addintional_info") }}
                        </h4>
                        <v-divider> </v-divider>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-text-field
                        readonly
                        v-model="adresse"
                        required
                        filled
                        @blur="$v.adresse.$touch()"
                        name="adress"
                        :label="$t('fields.geo_addresse')"
                        :error-messages="adresseErrors"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-text-field
                        readonly
                        v-model="codepostal"
                        required
                        filled
                        @blur="$v.codepostal.$touch()"
                        name="codepostal"
                        :label="$t('fields.postal_code')"
                        :error-messages="codepostalErrors"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        readonly
                        v-model="town"
                        required
                        filled
                        @blur="$v.town.$touch()"
                        name="town"
                        :label="$t('fields.city')"
                        :error-messages="townErrors"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <vue-phone-number-input
                        disabled
                        class="mt-4 mb-4"
                        ref="phoneinput"
                        :default-country-code="defaultcountry"
                        required
                        :dark="isdark"
                        @blur="$v.phonenumber.$touch()"
                        v-model="phonenumber"
                        :error-messages="phonenumberErrors"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        readonly
                        v-model="work"
                        required
                        filled
                        @blur="$v.work.$touch()"
                        name="work"
                        label="Profession"
                        :error-messages="workErrors"
                      ></v-text-field>
                    </v-col>

                    <!-- voyage -->

                    <v-col cols="12" sm="12">
                      <div style="background: #e0e0e0">
                        <v-divider> </v-divider>
                        <h4 style="color: #4a4343; margin-left: 10px">
                          {{ "title.travel_info" }}
                        </h4>
                        <v-divider> </v-divider>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-text-field
                        readonly
                        v-model="passportnumber"
                        required
                        filled
                        @blur="$v.passportnumber.$touch()"
                        :error-messages="passportnumberErrors"
                        type="text"
                        :label="$t('fields.passportnumber')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        readonly
                        required
                        v-model="delivercountry"
                        @blur="$v.delivercountry.$touch()"
                        filled
                        :items="birthCountries"
                        :status_demandeitems="birthCountries"
                        :label="$t('fields.delivercountry')"
                        :error-messages="delivercountryErrors"
                        item-text="nom_fr_fr"
                        item-value="nom_fr_fr"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <div>
                        <v-menu
                          ref="delivermenu"
                          v-model="delivermenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              disabled
                              filled
                              readonly
                              required
                              v-model="deliverdate"
                              @blur="$v.deliverdate.$touch()"
                              :label="$t('fields.deliverdate')"
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              :error-messages="deliverdateErrors"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            :max="new Date().toISOString().slice(0, 10)"
                            :locale="langLocal"
                            v-model="deliverdate"
                            :active-picker.sync="activeDeliverPicker"
                            @change="deliversave"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-menu
                        ref="expi"
                        v-model="expi"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            disabled
                            readonly
                            required
                            filled
                            v-model="expiredate"
                            @blur="$v.expiredate.$touch()"
                            :label="$t('fields.expiredate')"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            :error-messages="expiredateErrors"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          :min="passport_min_date"
                          :locale="langLocal"
                          v-model="expiredate"
                          :active-picker.sync="editActiveExpirePicker"
                          @change="expi_save"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        readonly
                        required
                        v-model="acreditationcounrty"
                        @blur="$v.acreditationcounrty.$touch()"
                        filled
                        :items="countries"
                        :label="$t('fields.acreditationcounrty')"
                        :error-messages="acreditationcounrtyErrors"
                        item-text="pays_origine"
                        item-value="id"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        readonly
                        required
                        v-model="motifvoyage"
                        @blur="$v.motifvoyage.$touch()"
                        filled
                        :items="typesvisa"
                        :label="$t('fields.traval_reason')"
                        :error-messages="motifErrors"
                        item-text="visa_designation"
                        item-value="id"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <div>
                        <v-menu
                          ref="startmenu"
                          v-model="startmenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              disabled
                              filled
                              readonly
                              required
                              v-model="startdate"
                              @blur="$v.startdate.$touch()"
                              :label="$t('fields.startdate')"
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              :error-messages="startdateErrors"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            :locale="langLocal"
                            v-model="startdate"
                            :active-picker.sync="activeStartPicker"
                            @change="startsave"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-menu
                        ref="endmenu"
                        v-model="endmenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            disabled
                            readonly
                            required
                            filled
                            v-model="enddate"
                            @blur="$v.enddate.$touch()"
                            :label="$t('fields.enddate')"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            :error-messages="enddateErrors"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          :min="passport_min_date"
                          :locale="langLocal"
                          v-model="enddate"
                          :active-picker.sync="activeEndPicker"
                          @change="endsave"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <!-- contact -->

                    <v-col v-if="has_contact == true" cols="12" sm="12">
                      <div style="background: #e0e0e0">
                        <v-divider> </v-divider>
                        <h4 style="color: #4a4343; margin-left: 10px">
                          {{ "title.cam_contact_info" }}
                        </h4>
                        <v-divider> </v-divider>
                      </div>
                    </v-col>
                    <v-col v-if="has_contact == true" cols="12" sm="12">
                      <v-text-field
                        readonly
                        v-model="contactname"
                        required
                        filled
                        @blur="$v.contactname.$touch()"
                        name="contactname"
                        :label="$t('fields.contactname')"
                        :error-messages="contactnameErrors"
                      ></v-text-field>
                    </v-col>

                    <v-col v-if="has_contact == true" cols="12" sm="6">
                      <v-text-field
                        readonly
                        v-model="contactadresse"
                        required
                        filled
                        @blur="$v.contactadresse.$touch()"
                        name="contactadresse"
                        :label="$t('fields.contactadresse')"
                        :error-messages="contactadresseErrors"
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="has_contact == true" cols="12" sm="6">
                      <v-text-field
                        readonly
                        v-model="contacttown"
                        required
                        filled
                        @blur="$v.contacttown.$touch()"
                        name="contacttown"
                        :label="$t('fields.contacttown')"
                        :error-messages="contacttownErrors"
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="has_contact == true" cols="12" sm="6">
                      <vue-phone-number-input
                        disabled
                        ref="contactphone"
                        class="mt-4 mb-4"
                        :default-country-code="defaultcountrycontact"
                        required
                        :dark="isdark"
                        @blur="$v.contactphone.$touch()"
                        v-model="contactphone"
                        :error-messages="contactphoneErrors"
                      />
                    </v-col>
                    <v-col v-if="has_contact == true" cols="12" sm="6">
                      <v-text-field
                        readonly
                        v-model="contactemail"
                        prepend-icon="email"
                        type="email"
                        required
                        filled
                        @blur="$v.contactemail.$touch()"
                        name="contactemail"
                        :label="$t('fields.contactemail')"
                        :error-messages="contactemailErrors"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      style="margin-top: auto; margin-bottom: auto"
                      cols="12"
                      sm="6"
                      v-if="switch1"
                    >
                      <h4>{{ $t("title.has_familly") }}</h4>
                    </v-col>
                    <v-col v-if="switch1" cols="12" sm="4">
                      <v-switch
                        readonly
                        class="ml-2"
                        v-model="switch1"
                        inset
                        :label="has_familly"
                      ></v-switch>
                    </v-col>
                    <v-col v-show="switch1 == true" cols="12" sm="12">
                      <div style="background: #e0e0e0">
                        <v-divider> </v-divider>
                        <h4 style="color: #4a4343; margin-left: 10px">
                          {{ "title.family_contact_info" }}
                        </h4>
                        <v-divider> </v-divider>
                      </div>
                    </v-col>
                    <v-col v-show="switch1 == true" cols="12" sm="12">
                      <v-text-field
                        readonly
                        v-model="famillyname"
                        required
                        filled
                        @blur="$v.famillyname.$touch()"
                        name="famillyname"
                        :label="$t('fields.famillyname')"
                        :error-messages="famillynameErrors"
                      ></v-text-field>
                    </v-col>
                    <v-col v-show="switch1 == true" cols="12" sm="6">
                      <v-text-field
                        readonly
                        v-model="famillyadresse"
                        required
                        filled
                        @blur="$v.famillyadresse.$touch()"
                        name="famillyadresse"
                        :label="$t('fields.famillyadresse')"
                        :error-messages="famillyadresseErrors"
                      ></v-text-field>
                    </v-col>
                    <v-col v-show="switch1 == true" cols="12" sm="6">
                      <v-text-field
                        readonly
                        v-model="famillytown"
                        required
                        filled
                        @blur="$v.famillytown.$touch()"
                        name="contacttown"
                        :label="$t('fields.famillytown')"
                        :error-messages="famillytownErrors"
                      ></v-text-field>
                    </v-col>
                    <v-col v-show="switch1 == true" cols="12" sm="6">
                      <vue-phone-number-input
                        disabled
                        ref="famillyphone"
                        class="mt-4 mb-4"
                        :only-countries="['CM']"
                        :default-country-code="defaultcountryfamilly"
                        required
                        :dark="isdark"
                        @blur="$v.famillyphone.$touch()"
                        v-model="famillyphone"
                        :error-messages="famillyphoneErrors"
                      />
                    </v-col>
                    <v-col v-show="switch1 == true" cols="12" sm="6">
                      <v-text-field
                        readonly
                        v-model="famillyemail"
                        prepend-icon="email"
                        type="email"
                        required
                        filled
                        @blur="$v.famillyemail.$touch()"
                        name="famillyemail"
                        :label="$t('fields.famillyemail')"
                        :error-messages="famillyemailErrors"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <div style="background: #e0e0e0">
                        <v-divider> </v-divider>
                        <h4 style="color: #4a4343; margin-left: 10px">
                          {{ $t("title.local_cam_info") }}
                        </h4>
                        <v-divider> </v-divider>
                      </div>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-text-field
                        readonly
                        v-model="residencename"
                        required
                        filled
                        @blur="$v.residencename.$touch()"
                        name="residencename"
                        :label="$t('fields.residencename')"
                        :error-messages="residencenameErrors"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        readonly
                        v-model="residenceadresse"
                        required
                        filled
                        @blur="$v.residenceadresse.$touch()"
                        name="residenceadresse"
                        :label="$t('fields.residenceadresse')"
                        :error-messages="residenceadresseErrors"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        readonly
                        v-model="residencetown"
                        required
                        filled
                        @blur="$v.residencetown.$touch()"
                        name="residencetown"
                        :label="$t('fields.residencetown')"
                        :error-messages="residencetownErrors"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <vue-phone-number-input
                        disabled
                        class="mt-4 mb-4"
                        ref="residencephone"
                        :only-countries="['CM']"
                        :default-country-code="defaultcountryresidence"
                        required
                        :dark="isdark"
                        @blur="$v.residencephone.$touch()"
                        v-model="residencephone"
                        :error-messages="residencephoneErrors"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        readonly
                        v-model="residenceemail"
                        prepend-icon="email"
                        type="email"
                        required
                        filled
                        @blur="$v.residenceemail.$touch()"
                        name="residenceEmail"
                        :label="$t('fields.residenceemail')"
                        :error-messages="residenceemailErrors"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item>
                  <template>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ $t("title.file_name") }}
                            </th>
                            <th class="text-left">>{{ $t("title.file") }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{{ $t("title.passport_picture") }}</td>
                            <td>
                              <div v-if="filepasseport">
                                <img
                                  class="img-viewer"
                                  @click="show_image(filepasseport_link)"
                                  style="
                                    margin: 5px;
                                    max-width: 50px;
                                    max-height: 50px;
                                    border-radius: 3px;
                                  "
                                  v-bind:src="filepasseport_link"
                                />
                                <viewer
                                  style="display: none"
                                  :images="current_image"
                                >
                                  <img :src="filepasseport" />
                                </viewer>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>{{ $t("title.logement_justify") }}</td>
                            <td>
                              <div v-if="filelogement">
                                <img
                                  class="img-viewer"
                                  @click="show_image(filelogement_link)"
                                  style="
                                    margin: 5px;
                                    max-width: 50px;
                                    max-height: 50px;
                                    border-radius: 3px;
                                  "
                                  v-bind:src="filelogement_link"
                                />
                                <viewer
                                  style="display: none"
                                  :images="current_image"
                                >
                                  <img :src="filelogement" />
                                </viewer>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>{{ $t("title.plan_ticket") }}</td>
                            <td>
                              <div v-if="flugticket">
                                <img
                                  class="img-viewer"
                                  @click="show_image(flugticket_link)"
                                  style="
                                    margin: 5px;
                                    max-width: 50px;
                                    max-height: 50px;
                                    border-radius: 3px;
                                  "
                                  v-bind:src="flugticket_link"
                                />
                                <viewer
                                  style="display: none"
                                  :images="current_image"
                                >
                                  <img :src="flugticket_link" />
                                </viewer>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </v-tab-item>
              </v-tabs-items>
            </template>
            <v-row
              v-if="has_pending == true && cancel_search"
              class="mt-5 mb-5"
            >
              <v-col cols="12">
                <v-btn
                  ref="valider_btn"
                  :loading="isLoading"
                  class="text-white m-2"
                  color="green"
                  @click="save('VALIDER')"
                >
                  {{ $t("btn.valide") }}
                </v-btn>
                <v-btn
                  ref="complement_btn"
                  :disabled="isLoading"
                  class="text-white m-2"
                  color="blue"
                  @click="complement()"
                >
                  {{ $t("btn.complement") }}
                </v-btn>
                <v-btn
                  class="text-white m-2"
                  :disabled="isLoading"
                  color="red "
                  @click="reject()"
                >
                  {{ $t("btn.reject") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-dialog
              persistent
              :eager="true"
              content-class="custimize-dialog"
              transition="dialog-bottom-transition"
              scrollable
              style="z-index: 1500"
              max-width="700px"
              :retain-focus="false"
              v-model="show_dialog"
            >
              <v-card max-width="1000">
                <v-card-title class="text-white text-h8 blue">
                  <v-btn
                    icon
                    small
                    color="red"
                    class="text-white"
                    @click="show_dialog = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  {{ $t("title.reject_raison") }}
                </v-card-title>
                <v-card-text>
                  <v-autocomplete
                    v-model="comment"
                    item-text="raison_postpones"
                    item-value="raison_postpones"
                    :items="raison_list"
                    class="m-4"
                    multiple
                    :label="$t('fields.reason')"
                  ></v-autocomplete>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    ref="valider_btn"
                    :loading="isLoading"
                    class="text-white m-4"
                    color="blue"
                    :disabled="this.$v.comment.$invalid"
                    @click="save(status_demande)"
                  >
                    {{ $t("btn.confirm") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>
        <v-card elevation="0" class="mt-5">
          <v-card-title> </v-card-title>
          <v-card-text>
            <v-row v-if="cancel_search == true" no-gutters>
              <v-col cols="12" class="m-2" sm="3" md="3">
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_debut"
                      :label="$t('fields.date_start')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date_debut"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" class="m-2" sm="3" md="3">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_fin"
                      :label="$t('fields.date_end')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date_fin"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" class="p-2" sm="2" md="2">
                <v-btn
                  block
                  :disabled="can_search || isLoading"
                  @click="search_date"
                  color="primary"
                  class="m-2"
                  :loading="isLoading"
                >
                  {{ $t("btn.filter") }}
                </v-btn>
              </v-col>
              <v-col class="p-2" cols="12" md="3">
                <v-btn
                  block
                  class="text-white m-2"
                  :disabled="can_search"
                  color="red"
                  @click.prevent="
                    (date_debut = null),
                      (date_fin = null),
                      (can_search = false),
                      getDemands()
                  "
                >
                  {{ $t("btn.search_cancel") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-col
              v-if="cancel_search == false"
              class="text-center"
              cols="12"
              sm="12"
            >
              <div style="background: #e0e0e0">
                <v-divider> </v-divider>
                <h4 style="color: #4a4343; margin-left: 10px">
                  {{ $t("title.search_result") }}
                </h4>
                <v-divider> </v-divider>
              </div>
            </v-col>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-radio-group v-model="radios" class="mx-3 mt-5 pt-2" row>
                  <v-row>
                    <v-col cols="12" md="3" sm="3">
                      <v-radio
                        name="radios"
                        :label="$t('title.pending')"
                        value="pending"
                        color="orange"
                      ></v-radio>
                    </v-col>
                    <v-col cols="12" md="3" sm="3">
                      <v-radio
                        color="info"
                        name="radios"
                        :label="$t('fields.complement')"
                        value="complement"
                      ></v-radio>
                    </v-col>
                    <v-col cols="12" md="3" sm="3">
                      <v-radio
                        name="radios"
                        :label="$t('title.rejected')"
                        value="rejeter"
                        color="red"
                      ></v-radio>
                    </v-col>
                    <v-col cols="12" md="3" sm="3">
                      <v-radio
                        color="green"
                        name="radios"
                        :label="$t('title.valided')"
                        value="valider"
                      ></v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-data-table
              :headers="getHeaders"
              :items="demands"
              :search="search"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :items-per-page="5"
              class="elevation-2"
            >
              <template v-slot:top>
                <v-toolbar class="pt-3 mb-5" flat>
                  <v-toolbar-title class="mt-5 mb-5"> </v-toolbar-title>

                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('fields.search_text')"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-toolbar>
              </template>
              <template v-slot:[`item.code_demande`]="{ item }">
                {{ item.code_demande }}
                <v-btn class="text-center" icon>
                  <v-icon
                    small
                    color="green"
                    v-clipboard:copy="item.code_demande"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                  >
                    mdi-clipboard-multiple
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:[`item.status_demande`]="{ item }">
                <span>
                  <v-chip
                    :color="getColor(item.status_demande, item.level)"
                    dark
                  >
                    <span
                      v-if="
                        item.status_demande == 'pending' &&
                        item.level.toLowerCase() == 'niveau2'
                      "
                    >
                      {{ $t("btn.to_valid") }}
                    </span>
                    <span v-else>{{ item.status_demande }}</span>
                  </v-chip>
                  <v-btn
                    icon
                    @click="getHistory(item)"
                    class="mx-2"
                    x-small
                    color="primary"
                  >
                    <v-icon>mdi-account-alert</v-icon>
                  </v-btn>
                </span>
                <v-dialog
                  :retain-focus="false"
                  persistent
                  v-model="history_dialog"
                  width="650"
                  style="z-index: 1900"
                >
                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      <v-btn
                        icon
                        x-small
                        color="primary"
                        text
                        @click="history_dialog = false"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>

                    <v-card-text>
                      <v-timeline align-top dense>
                        <v-timeline-item
                          v-for="item in time_history"
                          :key="item.status_demande"
                          :color="color_time[item.color]['color']"
                          small
                        >
                          <v-row class="pt-1">
                            <v-col cols="3">
                              <strong>{{ item.status_demande }}</strong>
                            </v-col>
                            <v-col>
                              <strong>
                                {{ item.lastname + " " + item.firstname }}
                              </strong>
                              <div class="text-caption">
                                {{ convert(item.created_at) }}
                              </div>
                            </v-col>
                          </v-row>
                        </v-timeline-item>
                      </v-timeline>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
              <template v-slot:[`item.nom`]="{ item }">
                {{ item.nom + " " + item.prenom }}
              </template>
              <template v-slot:[`item.id_type_visas`]="{ item }">
                {{ item.type_visas }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="showDemand(item)">
                  mdi-eye
                </v-icon>
              </template>
              <template v-slot:[`item.updated_at`]="{ item }">
                {{ formatDate(item.updated_at) }}
              </template>

              <template v-slot:[`item.last_time`]="{ item }">
                {{ getDifferenceInDate(item.updated_at) }}
              </template>

              <template v-slot:no-data>
                <v-col>
                  <i
                    style="font-size: 30px"
                    class="fa-solid fa-circle-info"
                  ></i>
                  <p>{{ $t("title.no_data") }}</p>
                </v-col>
                <v-col>
                  <v-btn text color="primary" @click="getDemands">
                    {{ $t("btn.reset") }}
                  </v-btn>
                </v-col>
              </template>
            </v-data-table>
            <v-dialog
              persistent
              v-model="show_modal"
              width="1500"
              style="z-index: 1500"
              content-class="custimize-dialog"
              transition="dialog-bottom-transition"
              :retain-focus="false"
            >
              <v-card>
                <v-card-text>
                  <details-demand
                    v-bind:demand.sync="current_item"
                    :action="'voir'"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="red lighten-1"
                    class="text-white"
                    :disabled="isLoading"
                    @click="show_modal = false"
                  >
                    {{ $t("btn.cancel") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </MainLayout-component>
</template>
<script>
import { getIndicatif } from "../../phoneCodeCountries.js";
import moment from "moment";
import "viewerjs/dist/viewer.css";
import VuetelephoneInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  email,
  sameAs,
  helpers,
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import i18n from "../../i18n";
import MainLayout from "../MainLayout";
import DetailsDemand from "../../components/DetailsDemand.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

const alphaNumAndDotValidator = helpers.regex("alphaNumAndDot", /^[a-z\d.]*$/i);
const regEx = new RegExp(/^.*\.(jpg|JPG|jpeg|JPEG|png|PNG|svg|SVG)$/i);
const nameRegex = helpers.regex(
  "alphaNum",
  /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/
);

const file_size_validation = (value, vm) => {
  if (!value) {
    return false;
  }
  let file = value;
  return file.size < 2000000;
};

export default {
  name: "ListDemands",
  components: {
    "MainLayout-component": MainLayout,
    "vue-phone-number-input": VuetelephoneInput,
    "details-demand": DetailsDemand,
  },
  mixins: [validationMixin],
  validations: {
    comment: { required },
    acreditationcounrty: { required },
    contactname: { required },
    contactadresse: { required },
    contacttown: { required },
    contactphone: { required },
    contactemail: { required, email },
    famillyname: { required },
    famillyadresse: { required },
    famillytown: { required },
    famillyphone: { required },
    famillyemail: { required, email },
    residencename: { required },
    residenceadresse: { required },
    residencetown: { required },
    residencephone: { required },
    residenceemail: { required, email },
    startdate: { required },
    enddate: { required },
    adresse: { required },
    codepostal: { required },
    town: { required },
    work: { required },
    firstname: { required, nameRegex },
    lastname: { required, nameRegex },
    genre: { required },
    birthday: { required },
    visatype: { required },
    birthcountry: { required },
    delivercountry: { required },
    deliverdate: { required },
    birthplace: { required },
    motifvoyage: { required },
    familienstand: { required },
    nationalityOrigin: { required },
    nationalityNow: { required },
    expiredate: { required },
    phonenumber: { required },
    flugticket: { required, file_size_validation },
    filepasseport: { required, file_size_validation },
    filelogement: { required, file_size_validation },
    passportnumber: {
      required,
      alphaNumAndDotValidator,
      minLength: minLength(5),
    },
  },
  data: () => ({
    waiting_dialog: true,
    overlay: true,
    id_demand: null,
    current_demand: null,
    expiredate: null,
    time_history: null,
    action: null,
    code_demande: null,
    filepasseport_link: "",
    filelogement_link: "",
    flugticket_link: "",
    passportnumber: null,
    motifvoyage: null,
    phonenumber: null,
    switch1: false,
    defaultcountry: "CM",
    defaultcountryfamilly: "CM",
    defaultcountryresidence: "CM",
    defaultcountrycontact: "CM",
    birthcountry: null,
    status_demande: null,
    comment: null,
    firstname: null,
    lastname: null,

    id_type_visas: null,

    nationality: null,
    color_time: [
      {
        id: 0,
        color: "pink",
      },
      {
        id: 1,
        color: "teal lighten-3",
      },
    ],
    contactname: null,
    contactadresse: null,
    contacttown: null,
    contactphone: null,
    contactemail: null,
    famillyname: null,
    famillyadresse: null,
    famillytown: null,
    famillyphone: null,
    famillyemail: null,
    residencename: null,
    residenceadresse: null,
    residencetown: null,
    residencephone: null,
    residenceemail: null,
    acreditationcounrty: null,
    delivercountry: null,
    birthplace: null,
    nationalityNow: null,
    nationalityOrigin: null,
    startdate: null,
    enddate: null,
    deliverdate: null,
    adresse: "",
    codepostal: "",
    town: "",
    work: "",
    created_at: null,
    familienstand: null,
    flugticket: null,
    activePicker: null,
    editActivePicker: null,
    activeExpirePicker: null,
    editActiveExpirePicker: null,
    activeDeliverPicker: null,
    activeStartPicker: null,
    activeEndPicker: null,
    menu: false,
    editMenu: false,
    delivermenu: false,
    has_contact: null,
    has_familly: "no",
    startmenu: null,
    endmenu: null,
    all_demands: null,

    birthday_min_date: null,
    passport_min_date: null,
    code_selected: null,
    snackbarColor: "default",
    snackbar: false,
    editSnackbar: false,
    editSnackbarColor: "default",

    snackbar_text: "",
    show_action: null,
    cancel_search: true,
    has_pending: false,
    current_item: null,
    tab: 0,

    current_image: null,
    complement_images: null,
    message: null,
    show: false,
    boolean: 1,

    raison_list: [],

    editedIndex: null,
    show_dialog: false,
    history_dialog: false,
    radios: "pending",
    show_modal: false,
    search_block: false,
    snackbarColor: "default",
    snackbar_text: "",
    snackbar: false,
    editSnackbarColor: "default",
    editSnackbar: false,
    search_form: false,
    code_input: null,
    menu2: false,
    menu1: false,
    date_debut: null,
    date_fin: null,
    length: 0,
    show_comment: false,
    passport_images: [],
    logement_images: [],
    mission_images: [],
    search: null,
    sortBy: "updated_at",
    sortDesc: false,
    filepasseportfilename: null,
    filelogementfilename: null,
    filemissionfilename: null,
    filecomplementname: null,
    menu: false,
    expi: false,
    visa: null,
    isLoading: false,
    activePicker: null,
    activeExpirePicker: null,
    isOkay: false,
    birthday: null,
    dateexpirationpasseport: null,
    pays_origine: null,
    genders: [
      {
        name: "F",
        value: "F",
      },
      {
        name: "M",
        value: "M",
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [],
    demands: [],
    editedIndex: -1,

    prenom: null,
    nom: null,
    genre: null,
    birthday: null,

    pays_origine: null,

    ordremission: null,

    filepasseport: null,
    filemission: null,
    filelogement: null,
    numpasseport: null,
    all_demands: null,

    defaultItem: {},
  }),
  created() {
       this.$root.$i18n.locale = this.langLocal;
    this.$store.dispatch("getnationalities");
    this.$store.dispatch("gettypesvisa");
    this.$store.dispatch("getcountries");
    this.$store.dispatch("getpostpones");
    this.getTraitement();
    this.getDemands();
    this.RefreshDemand();
    moment.locale(this.langLocal);
  },
  methods: {
    getDifferenceInDate(d1) {
      const result = moment(d1).subtract(0, "hour").fromNow();
      return result;
    },
    RefreshDemand() {
      setInterval(this.initialize, 3600000);
    },
    initialize() {
      this.getTraitement();
      this.getDemands();
    },
    save_birthday(date) {
      this.$refs.menu.save(date);
    },

    expi_save(newdate) {
      this.$refs.expi.save(newdate);
    },
    deliversave(newdate) {
      this.$refs.delivermenu.save(newdate);
    },
    startsave(date) {
      this.$refs.startmenu.save(date);
    },
    endsave(date) {
      this.$refs.endmenu.save(date);
    },
    menu_expi(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    save_birthday(date) {
      this.$refs.menu.save(date);
    },
    save_expi(date) {
      this.$refs.menu_expi.save(date);
    },
    show_image(img) {
      if (img) {
        let result = img.includes("https") || img.includes("http");
        if (result) {
          this.current_image = [img];
        } else {
          let url = URL.createObjectURL(img);
          this.current_image = [url];
        }
        this.$viewerApi({
          images: this.current_image,
        });
      }
    },

    async search_code() {
      this.isLoading = true;

      const data = new FormData();
      data.append("code_demande", this.code_input);

      await this.$store
        .dispatch("searchNiveau1Demand", data)
        .then((response) => {
          this.isLoading = false;

          if (response.success && response.data) {
            this.cancel_search = false;
            let getdata = response.data;

            if (typeof getdata == "object") {
              if (getdata.length != 0) {
                this.editSnackbarColor = "success";
                this.editSnackbar = true;
                this.snackbar_text = i18n.t("message.search_success");
              } else {
                this.editSnackbarColor = "warning";
                this.snackbar_text = i18n.t("message.search_failed");
                this.editSnackbar = true;
              }
              this.demands = getdata;
              this.all_demands = getdata;
            } else {
              this.editSnackbarColor = "green";
              this.snackbar_text = i18n.t("message.search_success");
              this.editSnackbar = true;
              this.demands = response.data;
            }

            this.all_demands = response.data;
            let result_search = this.all_demands.filter(function (element) {
              return element.status_demande.toLowerCase() == "pending";
            });
            this.radios = "pending";

            if (result_search.length == 0) {
              result_search = this.all_demands.filter(function (element) {
                return element.status_demande.toLowerCase() == "complement";
              });
              this.radios = "complement";
            }

            if (result_search.length == 0) {
              result_search = this.all_demands.filter(function (element) {
                return element.status_demande.toLowerCase() == "rejeter";
              });
              this.radios = "rejeter";
            }

            if (result_search.length == 0) {
              console.log("fd");
              result_search = this.all_demands.filter(function (element) {
                return element.status_demande.toLowerCase() == "valider";
              });

              this.radios = "valider";
            }

            this.demands = result_search;
          } else {
            this.isLoading = false;
            this.editSnackbarColor = "red";
            this.snackbar_text = i18n.t("message.search_failed");
            this.editSnackbar = true;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.editSnackbarColor = "red";
          this.snackbar_text = "Il s'est produite une erreur";
          this.editSnackbar = true;
        });
    },

    async save(status) {
      this.isLoading = true;

      if (status) {
        this.status_demande = status;
      }
      const data = new FormData();
      data.append("id", this.id_demand);
      data.append("status_demande", this.status_demande);
      data.append("comment", this.comment);

      await this.$store
        .dispatch("updateDemand", data)
        .then((response) => {
          console.log(response);
          if (response.success) {
            this.isLoading = false;
            this.editSnackbarColor = "success";
            this.snackbar_text = i18n.t("message.update_success");
            this.editSnackbar = true;
            this.initialize();
          } else {
            this.isLoading = false;
            this.editSnackbarColor = "red";
            this.snackbar_text = i18n.t("message.error");
            this.editSnackbar = true;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.editSnackbarColor = "red";
          this.snackbar_text = i18n.t("message.error");
          this.editSnackbar = true;
        });

      this.show_dialog = false;
      this.comment = null;
      this.getDemands();

      this.close();
      this.goto("toolbar");
    },

    getColorAttribut(statut) {
      console.log(statut);
      if (statut.toLowerCase() == "valider") return "green";
      else if (statut.toLowerCase() == "pending") return "orange";
      else if (statut.toLowerCase() == "complement") return "info";
      else return "red";
    },
    formatDate(str) {
      return moment(str).subtract(0, "hour").format("L LTS");
    },
    convert(str, format = "fr") {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      if (format == "fr") return [day, mnth, date.getFullYear()].join("-");
      else return [date.getFullYear(), mnth, day].join("-");
    },
    complement() {
      this.show_dialog = true;
      this.comment = "";
      this.status_demande = "COMPLEMENT";

      this.raison_list = this.postpones.filter(function (element) {
        return element.motif.toLowerCase() == "complement";
      });
    },
    reject() {
      this.show_dialog = true;
      this.comment = "";
      this.status_demande = "REJETER";

      this.raison_list = this.postpones.filter(function (element) {
        return element.motif.toLowerCase() == "rejet";
      });
    },

    onCopy(e) {
      this.show = true;
      setTimeout(() => {
        this.show = false;
      }, 400);
    },
    onError(e) {
      this.show = true;
    },

    async getHistory(item) {
      console.log(item.id);
      this.history_dialog = true;

      const data = new FormData();
      data.append("id_demande", item.id);

      await this.$store
        .dispatch("showTraiter", data)
        .then((response) => {
          this.time_history = response.data.data;
          this.time_history.forEach(async (item) => {
            this.boolean = 1 - this.boolean;
            item.color = this.boolean;
          });
          console.log(this.time_history);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async search_date() {
      this.isLoading = true;
      const data = new FormData();
      data.append("date_debut", this.date_debut);
      data.append("date_fin", this.date_fin);

      await this.$store
        .dispatch("getNiveau1AllDemand", data)
        .then((response) => {
          this.isLoading = false;
          if (response.data) {
            if (response.data.length != 0) {
              this.editSnackbarColor = "success";
              this.snackbar_text = i18n.t("message.search_success");
              this.editSnackbar = true;

              this.all_demands = response.data;
              let result_search = this.all_demands.filter(function (element) {
                return element.status_demande.toLowerCase() == "pending";
              });
              this.radios = "pending";

              if (result_search.length == 0) {
                result_search = this.all_demands.filter(function (element) {
                  return element.status_demande.toLowerCase() == "complement";
                });
                this.radios = "complement";
              }

              if (result_search.length == 0) {
                result_search = this.all_demands.filter(function (element) {
                  return element.status_demande.toLowerCase() == "rejeter";
                });
                this.radios = "rejeter";
              }

              if (result_search.length == 0) {
                result_search = this.all_demands.filter(function (element) {
                  return element.status_demande.toLowerCase() == "valider";
                });

                this.radios = "valider";
              }

              this.demands = result_search;
            } else {
              this.editSnackbarColor = "red";
              this.snackbar_text = i18n.t("message.search_failed");
              this.editSnackbar = true;
              this.demands = [];
            }
          } else {
            this.editSnackbarColor = "red";
            this.snackbar_text = "Aucune demande retrouvée";
            this.editSnackbar = true;
            this.demands = [];
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.editSnackbarColor = "red";
          this.snackbar_text = i18n.t("message.error");
          this.editSnackbar = true;
        });
    },

    async getDemands() {
      this.waiting_dialog = true;
      this.overlay = true;
      const data = new FormData();
      data.append("date_debut", this.date_debut);
      data.append("date_fin", this.date_fin);
      await this.$store

        .dispatch("getNiveau1AllDemand", data)
        .then((response) => {
          this.waiting_dialog = false;
          this.overlay = false;
          if (typeof response.data == "object") {
            let array = [];
            array[0] = response.data;
            this.demands = array;
            this.all_demands = array;
          }

          if (this.all_demands.length != 0) {
            this.all_demands = response.data;
            let result_search = this.all_demands.filter(function (element) {
              return element.status_demande.toLowerCase() == "pending";
            });
            this.radios = "pending";

            if (result_search.length == 0) {
              result_search = this.all_demands.filter(function (element) {
                return element.status_demande.toLowerCase() == "complement";
              });
              this.radios = "complement";
            }

            if (result_search.length == 0) {
              result_search = this.all_demands.filter(function (element) {
                return element.status_demande.toLowerCase() == "rejeter";
              });
              this.radios = "rejeter";
            }

            if (result_search.length == 0) {
              console.log("fd");
              result_search = this.all_demands.filter(function (element) {
                return element.status_demande.toLowerCase() == "valider";
              });

              this.radios = "valider";
            }

            this.demands = result_search;
          }
        })
        .catch((error) => {
          console.error(error);
          this.waiting_dialog = false;
          this.overlay = false;
        });
    },
    async getTraitement() {
      await this.$store
        .dispatch("getTraitement")
        .then((response) => {
          console.log(response.success);
          if (response.success) {
            if (response.data) {
              this.has_pending = true;
              this.current_demand = response.data;
              this.id_demand = this.current_demand.id;
              this.lastname = this.current_demand.nom;
              this.firstname = this.current_demand.prenom;
              this.genre = this.current_demand.genre;
              this.birthday = this.current_demand.birthday;
              this.birthcountry = this.current_demand.birth_country;
              this.birthplace = this.current_demand.birth_place;
              this.nationalityNow = this.current_demand.now_nationality;
              this.nationalityOrigin = this.current_demand.origine_nationality;
              this.familienstand = this.current_demand.civility;
              this.adresse = this.current_demand.adresse_geo;
              this.codepostal = this.current_demand.code_postal;
              this.phonenumber = this.current_demand.telephone;
              this.passportnumber = this.current_demand.numpasseport;
              this.delivercountry = this.current_demand.pays_delivrance;
              this.deliverdate = this.current_demand.date_delivrance_passeport;
              this.expiredate = this.current_demand.dateexpirationpasseport;

              this.acreditationcounrty = this.current_demand.pays_origine;
              this.motifvoyage = this.current_demand.id_type_visas;
              this.startdate = this.current_demand.date_depart;
              this.enddate = this.current_demand.date_retour;

              if (this.current_demand.nom_contact_cam) {
                this.contactname = this.current_demand.nom_contact_cam;
                this.contactadresse = this.current_demand.adrese_contact_cam;
                this.contacttown = this.current_demand.ville_contact_cam;
                this.contactphone = this.current_demand.telephone_contact_cam;
                this.contactemail = this.current_demand.email_contact_cam;
              } else {
                this.has_contact = false;
                this.contactname = null;
                this.contactadresse = null;
                this.contacttown = null;
                this.contactphone = null;
                this.contactemail = null;
              }

              if (this.current_demand.nom_famille_cam) {
                this.famillyname = this.current_demand.nom_famille_cam;
                this.famillyemail = this.current_demand.email_famille_cam;
                this.famillyphone = this.current_demand.telephone_famille_cam;
                this.famillyadresse = this.current_demand.adrese_famille_cam;
                this.famillytown = this.current_demand.ville_famille_cam;
              } else {
                this.switch1 = false;
                this.famillyname = null;
                this.famillyemail = null;
                this.famillyphone = null;
                this.famillyadresse = null;
                this.famillytown = null;
              }

              this.residencename = this.current_demand.nom_residence_cam;
              this.residenceadresse = this.current_demand.adrese_residence_cam;
              this.residencetown = this.current_demand.ville_residence_cam;
              this.residencephone = this.current_demand.telephone_residence_cam;
              this.residenceemail = this.current_demand.email_residence_cam;
              this.filepasseport_link = this.current_demand.file_photopasseport;

              this.filelogement_link =
                this.current_demand.file_justificationlogement;
              this.flugticket_link = this.current_demand.file_ticket_avaion;

              this.town = this.current_demand.ville;
              this.work = this.current_demand.profession;
              this.code_demande = this.current_demand.code_demande;
              this.file_complement = this.current_demand.file_complement;
              this.updated_at = this.current_demand.updated_at;

              //telephone personnel
              this.phonenumber = this.current_demand.telephone.substring(
                this.current_demand.telephone.indexOf(" ") + 1
              );

              this.defaultcountry = getIndicatif(
                this.current_demand.telephone.substring(
                  1,
                  this.current_demand.telephone.indexOf(" ")
                )
              );
              //telephone contact

              if (this.current_demand.telephone_famille_cam != null) {
                this.contactphone =
                  this.current_demand.telephone_contact_cam.substring(
                    this.current_demand.telephone_contact_cam.indexOf(" ") + 1
                  );

                this.defaultcountrycontact = getIndicatif(
                  this.current_demand.telephone_contact_cam.substring(
                    1,
                    this.current_demand.telephone_contact_cam.indexOf(" ")
                  )
                );
              }

              //telephone famille
              if (this.current_demand.telephone_famille_cam != null) {
                this.famillyphone =
                  this.current_demand.telephone_famille_cam.substring(
                    this.current_demand.telephone_famille_cam.indexOf(" ") + 1
                  );

                this.defaultcountryfamilly = getIndicatif(
                  this.current_demand.telephone_famille_cam.substring(
                    1,
                    this.current_demand.telephone_famille_cam.indexOf(" ")
                  )
                );
              }

              //telephone resisdence
              if (this.current_demand.telephone_residence_cam != null) {
                this.residencephone =
                  this.current_demand.telephone_residence_cam.substring(
                    this.current_demand.telephone_residence_cam.indexOf(" ") + 1
                  );

                this.defaultcountryresidence = getIndicatif(
                  this.current_demand.telephone_residence_cam.substring(
                    1,
                    this.current_demand.telephone_residence_cam.indexOf(" ")
                  )
                );
              }

              if (this.contactname && this.motifvoyage == 1) {
                this.has_contact = true;
              } else {
                this.has_contact = false;
              }
              if (this.famillyname) {
                this.switch1 = true;
                this.has_familly = i18n.t("yes");
              } else {
                this.switch1 = false;
                this.has_familly = i18n.t("no");
              }

              const passport_file = new File(
                ["blob"],
                this.current_demand.file_photopasseport,
                {
                  lastModified: new Date(Date.now())
                    .toISOString()
                    .substr(0, 10),
                  type: "text/jpg",
                }
              );

              this.filepasseport = passport_file;

              const logement_file = new File(
                ["blob"],
                this.current_demand.file_justificationlogement,
                {
                  lastModified: new Date(Date.now())
                    .toISOString()
                    .substr(0, 10),
                  type: "text/jpg",
                }
              );

              this.filelogement = logement_file;

              const flugticket_file = new File(
                ["blob"],
                this.current_demand.file_ticket_avaion,
                {
                  lastModified: new Date(Date.now())
                    .toISOString()
                    .substr(0, 10),
                  type: "text/jpg",
                }
              );

              this.flugticket = flugticket_file;
            } else {
              this.has_pending = false;
            }
          } else {
            this.has_pending = false;
          }

          this.radios = "pending";
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getColor(statut, level) {
      if (statut.toLowerCase() == "valider" && level.toLowerCase() == "niveau1")
        return "green";
      else if (
        statut.toLowerCase() == "pending" &&
        level.toLowerCase() == "niveau1"
      )
        return "orange";
      else if (
        statut.toLowerCase() == "complement" &&
        level.toLowerCase() == "niveau1"
      )
        return "cyan accent-4";
      else return "red";
    },
    async showDemand(item) {
      const data = new FormData();
      data.append("code_demande", item.code_demande);

      await this.$store
        .dispatch("getDemandByCodeNiveau1", data)
        .then((response) => {
          console.log(response);
          if (response.success) {
            if (response.data) {
              this.current_item = response.data;
              this.show_modal = true;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });

      this.show_modal = true;
    },
    close() {
      this.show_modal = false;
      this.search_form = false;
      this.show_comment = false;
      this.dialog = false;
    },
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },

    async submit() {},
  },
  computed: {
   familienStandList() {
      let array = [
        {
          id: 0,
          name: "Marié",
          text: i18n.t("fields.marry"),
        },

        {
          id: 1,
          name: "Célibataire avec enfant",
          text: i18n.t("fields.with_child"),
        },
        {
          id: 2,
          name: "Célibataire sans enfant",
          text: i18n.t("fields.no_child"),
        },
        {
          id: 3,
          name: "Veuve",
          text: i18n.t("fields.widow"),
        },
      ];

      return array;
    },
    can_search() {
      if (this.date_debut == null || this.date_fin == null) {
        return true;
      } else {
        return false;
      }
    },
    can_filter() {
      if (
        this.code_input == null ||
        this.code_input.replace(/\s+/g, "") == ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    getHeaders() {
      this.headers = [
        {
          text: "N°",
          align: "center",
          value: "code_demande",
        },

        {
          text: i18n.t("headers.fullname"),
          align: "center",
          value: "nom",
        },
        { 
         text: i18n.t("headers.passport_number"),
         align: "center",
          value: "numpasseport" 
          },
        {
          text: i18n.t("headers.country"),
          align: "center",
          value: "pays_origine",
        },
        {
          text: i18n.t("headers.visatype"),
          align: "center",
          value: "type_visas",
        },
        {

          text: i18n.t("headers.statut"),
          align: "center",
          value: "status_demande",
        },
        { text: "Date", align: "center", value: "updated_at" },

         
        {
           text: i18n.t("headers.elapsed_time"),
            align: "center",
             value: "last_time",
        },

        { 
          text: i18n.t("headers.elapsed_time"),
          align: "center",
          value: "last_time" ,  
          },

        { text: "Actions", align: "center", value: "actions" },
      ];
      return this.headers;
    },
    commentErrors() {
      const errors = [];
      if (!this.$v.comment.$dirty) return errors;
      !this.$v.comment.required &&
        errors.push(i18n.t("validations.required_comment"));
      return errors;
    },
    can_submit() {
      let firsthand;
      if (this.switch1) {
        if (
          this.$v.famillyname.$invalid ||
          this.$v.famillyadresse.$invalid ||
          this.$v.famillytown.$invalid ||
          this.$v.famillyphone.$invalid ||
          this.$v.famillyemail.$invalid
        ) {
          firsthand = true;
        } else {
          firsthand = false;
        }
      } else {
        firsthand = false;
      }

      let secondhand;
      if (this.has_contact) {
        if (
          this.$v.contactname.$invalid ||
          this.$v.contactadresse.$invalid ||
          this.$v.contacttown.$invalid ||
          this.$v.contactphone.$invalid ||
          this.$v.contactemail.$invalid
        ) {
          secondhand = true;
        } else {
          secondhand = false;
        }
      } else {
        secondhand = false;
      }

      if (
        firsthand ||
        secondhand ||
        this.$v.residencename.$invalid ||
        this.$v.residenceadresse.$invalid ||
        this.$v.residencetown.$invalid ||
        this.$v.residencephone.$invalid ||
        this.$v.residenceemail.$invalid ||
        this.$v.firstname.$invalid ||
        this.$v.lastname.$invalid ||
        this.$v.genre.$invalid ||
        this.$v.birthday.$invalid ||
        this.$v.birthcountry.$invalid ||
        this.$v.birthplace.$invalid ||
        this.$v.nationalityOrigin.$invalid ||
        this.$v.nationalityNow.$invalid ||
        this.$v.familienstand.$invalid ||
        this.$v.phonenumber.$invalid ||
        this.$v.adresse.$invalid ||
        this.$v.codepostal.$invalid ||
        this.$v.town.$invalid ||
        this.$v.work.$invalid ||
        this.$v.expiredate.$invalid ||
        this.$v.delivercountry.$invalid ||
        this.$v.deliverdate.$invalid ||
        this.$v.passportnumber.$invalid ||
        this.$v.filepasseport.$invalid ||
        this.$v.filelogement.$invalid ||
        this.$v.flugticket.$invalid ||
        this.$v.motifvoyage.$invalid ||
        this.$v.startdate.$invalid ||
        this.$v.enddate.$invalid ||
        this.$v.acreditationcounrty.$invalid
      ) {
        return true;
      } else {
        return false;
      }
    },

    getLocaleMessages() {
      return this.$root.$i18n.messages[this.langLocal];
    },
    phonenumberErrors() {
      const errors = [];
      if (!this.$v.phonenumber.$dirty) return errors;
      !this.$v.phonenumber.required && errors.push("required_phonenumber");
      return errors;
    },
    firstnameErrors() {
      const errors = [];
      if (!this.$v.firstname.$dirty) return errors;
      !this.$v.firstname.required && errors.push("required_firstname");
      !this.$v.firstname.nameRegex && errors.push("caracter_firstname");
      return errors;
    },
    familienstandErrors() {
      const errors = [];
      if (!this.$v.familienstand.$dirty) return errors;
      !this.$v.familienstand.required && errors.push("required_marital_status");
      !this.$v.firstname.nameRegex && errors.push("caracter_firstname");
      return errors;
    },

    lastnameErrors() {
      const errors = [];
      if (!this.$v.lastname.$dirty) return errors;
      !this.$v.lastname.required && errors.push("required_lastname");
      !this.$v.lastname.nameRegex && errors.push("caracter_lastname");
      return errors;
    },
    genderErrors() {
      const errors = [];
      if (!this.$v.genre.$dirty) return errors;
      !this.$v.genre.required && errors.push("required_gender");
      return errors;
    },
    birthdateErrors() {
      const errors = [];
      if (!this.$v.birthday.$dirty) return errors;
      !this.$v.birthday.required && errors.push("required_birthdate");
      return errors;
    },

    deliverdateErrors() {
      const errors = [];
      if (!this.$v.deliverdate.$dirty) return errors;
      !this.$v.deliverdate.required && errors.push("required_deliverdate");
      return errors;
    },
    Errors() {
      const errors = [];
      if (!this.$v.birthdate.$dirty) return errors;
      !this.$v.birthdate.required && errors.push("required_birthdate");
      return errors;
    },
    birthplaceErrors() {
      const errors = [];
      if (!this.$v.birthplace.$dirty) return errors;
      !this.$v.birthplace.required && errors.push("required_birthplace");
      return errors;
    },

    birthcountryErrors() {
      const errors = [];
      if (!this.$v.birthcountry.$dirty) return errors;
      !this.$v.birthcountry.required && errors.push("required_birthcountry");
      return errors;
    },
    visaTypeErrors() {
      const errors = [];
      if (!this.$v.visatype.$dirty) return errors;
      !this.$v.visatype.required && errors.push("required_visatype");
      return errors;
    },
    motifErrors() {
      const errors = [];
      if (!this.$v.motifvoyage.$dirty) return errors;
      !this.$v.motifvoyage.required && errors.push("required_motif");
      return errors;
    },
    nationalityNowErrors() {
      const errors = [];
      if (!this.$v.nationalityNow.$dirty) return errors;
      !this.$v.nationalityNow.required &&
        errors.push("required_current_nationality");
      return errors;
    },
    nationalityOriginErrors() {
      const errors = [];
      if (!this.$v.nationalityOrigin.$dirty) return errors;
      !this.$v.nationalityOrigin.required &&
        errors.push("required_origine_nationality");
      return errors;
    },
    expiredateErrors() {
      const errors = [];
      if (!this.$v.expiredate.$dirty) return errors;
      !this.$v.expiredate.required && errors.push("required_expiredate");
      return errors;
    },
    delivercountryErrors() {
      const errors = [];
      if (!this.$v.delivercountry.$dirty) return errors;
      !this.$v.delivercountry.required &&
        errors.push("required_delivercountry");
      return errors;
    },

    passportnumberErrors() {
      const errors = [];
      if (!this.$v.passportnumber.$dirty) return errors;
      !this.$v.passportnumber.required &&
        errors.push("required_passportnumber");
      !this.$v.passportnumber.alphaNumAndDotValidator &&
        errors.push("caracter_passportnumber");
      !this.$v.passportnumber.minLength && errors.push("min_passportnumber");
      return errors;
    },
    copypassportErrors() {
      const errors = [];
      if (!this.$v.filepasseport.$dirty) return errors;
      !this.$v.filepasseport.required && errors.push("required_copypassport");
      !this.$v.filepasseport.file_size_validation &&
        errors.push("file_too_big");
      return errors;
    },
    justificatiflogementErrors() {
      const errors = [];
      if (!this.$v.filelogement.$dirty) return errors;
      !this.$v.filelogement.required &&
        errors.push("required_justificatiflogement");
      !this.$v.filelogement.file_size_validation && errors.push("file_too_big");
      return errors;
    },
    flugticketErrors() {
      const errors = [];
      if (!this.$v.flugticket.$dirty) return errors;
      !this.$v.flugticket.required && errors.push("required_flugticket");
      !this.$v.flugticket.file_size_validation && errors.push("file_too_big");
      return errors;
    },
    startdateErrors() {
      const errors = [];
      if (!this.$v.startdate.$dirty) return errors;
      !this.$v.startdate.required && errors.push("required_startdate");
      return errors;
    },
    enddateErrors() {
      const errors = [];
      if (!this.$v.enddate.$dirty) return errors;
      !this.$v.enddate.required && errors.push("required_enddate");
      return errors;
    },
    motifvoyageErrors() {
      const errors = [];
      if (!this.$v.motifvoyage.$dirty) return errors;
      !this.$v.motifvoyage.required && errors.push("required_motifvoyage");
      return errors;
    },
    adresseErrors() {
      const errors = [];
      if (!this.$v.adresse.$dirty) return errors;
      !this.$v.adresse.required && errors.push("required_adresse");
      return errors;
    },
    codepostalErrors() {
      const errors = [];
      if (!this.$v.codepostal.$dirty) return errors;
      !this.$v.codepostal.required && errors.push("required_codepostal");
      return errors;
    },
    townErrors() {
      const errors = [];
      if (!this.$v.town.$dirty) return errors;
      !this.$v.town.required && errors.push("required_town");
      return errors;
    },
    workErrors() {
      const errors = [];
      if (!this.$v.work.$dirty) return errors;
      !this.$v.work.required && errors.push("required_work");
      return errors;
    },

    contactnameErrors() {
      const errors = [];
      if (!this.$v.contactname.$dirty) return errors;
      !this.$v.contactname.required && errors.push("required_work");
      return errors;
    },
    contactadresseErrors() {
      const errors = [];
      if (!this.$v.contactadresse.$dirty) return errors;
      !this.$v.contactadresse.required &&
        errors.push("required_contactadresse");
      return errors;
    },
    contacttownErrors() {
      const errors = [];
      if (!this.$v.contacttown.$dirty) return errors;
      !this.$v.contacttown.required && errors.push("required_contacttown");
      return errors;
    },
    contactphoneErrors() {
      const errors = [];
      if (!this.$v.work.$dirty) return errors;
      !this.$v.work.required && errors.push("required_contactphone");
      return errors;
    },
    contactemailErrors() {
      const errors = [];
      if (!this.$v.contactemail.$dirty) return errors;
      !this.$v.contactemail.email && errors.push("valid_email");

      !this.$v.contactemail.required && errors.push("required_contactemail");
      return errors;
    },
    famillynameErrors() {
      const errors = [];
      if (!this.$v.famillyname.$dirty) return errors;
      !this.$v.famillyname.required && errors.push("required_famillyname");
      return errors;
    },
    famillyadresseErrors() {
      const errors = [];
      if (!this.$v.famillyadresse.$dirty) return errors;
      !this.$v.famillyadresse.required &&
        errors.push("required_famillyadresse");
      return errors;
    },
    famillytownErrors() {
      const errors = [];
      if (!this.$v.famillytown.$dirty) return errors;
      !this.$v.famillytown.required && errors.push("required_famillytown");
      return errors;
    },
    famillyphoneErrors() {
      const errors = [];
      if (!this.$v.famillyphone.$dirty) return errors;
      !this.$v.famillyphone.required && errors.push("required_famillyphone");
      return errors;
    },
    famillyemailErrors() {
      const errors = [];
      if (!this.$v.famillyemail.$dirty) return errors;
      !this.$v.famillyemail.email && errors.push("valid_email");

      !this.$v.famillyemail.required && errors.push("required_famillyphone");
      return errors;
    },
    residencenameErrors() {
      const errors = [];
      if (!this.$v.residencename.$dirty) return errors;
      !this.$v.residencename.required && errors.push("required_residencename");
      return errors;
    },
    residenceadresseErrors() {
      const errors = [];
      if (!this.$v.residenceadresse.$dirty) return errors;
      !this.$v.residenceadresse.required &&
        errors.push("required_residenceadresse");
      return errors;
    },
    residencetownErrors() {
      const errors = [];
      if (!this.$v.residencetown.$dirty) return errors;
      !this.$v.residencetown.required && errors.push("required_residencetown");
      return errors;
    },
    residencephoneErrors() {
      const errors = [];
      if (!this.$v.residencephone.$dirty) return errors;
      !this.$v.residencephone.required &&
        errors.push("required_residencephone");
      return errors;
    },
    residenceemailErrors() {
      const errors = [];
      if (!this.$v.residenceemail.$dirty) return errors;
      !this.$v.residenceemail.email && errors.push("valid_email");
      !this.$v.residenceemail.required &&
        errors.push("required_residenceemail");
      return errors;
    },
    acreditationcounrtyErrors() {
      const errors = [];
      if (!this.$v.acreditationcounrty.$dirty) return errors;
      !this.$v.acreditationcounrty.required &&
        errors.push("required_acreditationcounrty");
      return errors;
    },

    ...mapGetters([
      "birthCountries",
      "isdark",
      "langLocal",
      "currentrole",
      "errors",
      "registered",
      "countries",
      "nationalities",
      "typesvisa",
      "postpones",
    ]),
  },
  watch: {
    langLocal(val) {
      moment.locale(val);
      if (this.switch1 == true) {
        this.has_familly = "yes";
      } else {
        this.has_familly = "no";
      }
    },
    // code_input() {
    //  this.can_filter;
    // },
    motifvoyage(val) {
      if (val == 1) {
        this.has_contact = true;
      } else {
        this.has_contact = false;
        this.contactname = null;
        this.contactemail = null;
        this.contactphone = null;
        this.contactadresse = null;
        this.contacttown = null;
      }
    },

    switch1(val) {
      if (val) {
        this.has_familly = "yes";
      } else {
        this.has_familly = "no";
        this.famillyname = null;
        this.famillyemail = null;
        this.famillyphone = null;
        this.famillyadresse = null;
        this.famillytown = null;
      }
    },
    dialog(val) {
      val || this.close();
    },
    comment(val) {
      console.log(val);
    },

    menu(val) {
      watch;
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    editMenu(val) {
      val && setTimeout(() => (this.editActivePicker = "YEAR"));
    },
    expi(val) {
      val && setTimeout(() => (this.activeExpirePicker = "YEAR"));
    },
    editExpi(val) {
      val && setTimeout(() => (this.editActiveExpirePicker = "YEAR"));
    },
    delivermenu(val) {
      val && setTimeout(() => (this.activeDeliverPicker = "YEAR"));
    },
    startmenu(val) {
      val && setTimeout(() => (this.activeStartPicker = "YEAR"));
    },
    endmenu(val) {
      val && setTimeout(() => (this.activeEndPicker = "YEAR"));
    },
    dialog(val) {
      val || this.close();
    },
    radios(val) {
      if (val == "pending") {
        this.radios = "pending";
        if (this.all_demands) {
          let pending_demands = this.all_demands.filter(function (element) {
            return element.status_demande.toLowerCase() == "pending";
          });
          this.demands = pending_demands;
        }
      } else if (val == "rejeter") {
        this.radios = "rejeter";

        if (this.all_demands) {
          let reject_demands = this.all_demands.filter(function (element) {
            return element.status_demande.toLowerCase() == "rejeter";
          });
          this.demands = reject_demands;
        }
      } else if (val == "complement") {
        this.radios = "complement";

        if (this.all_demands) {
          let complement_demands = this.all_demands.filter(function (element) {
            return element.status_demande.toLowerCase() == "complement";
          });
          this.demands = complement_demands;
        }
      } else {
        this.radios = "valider";

        if (this.all_demands) {
          let valids_demands = this.all_demands.filter(function (element) {
            return element.status_demande.toLowerCase() == "valider";
          });
          this.demands = valids_demands;
        }
      }
    },
  },
};
</script>
<style scoped>
.custimize-dialog {
  z-index: 8000;
}
.img-viewer:hover {
  cursor: pointer;
}

.v-dialog {
  z-index: 1500;
  width: fit-content !important;
}
</style>

