<template>
  <auth-layout>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex>
          <div class="row justify-content-center">
            <div class="col-md-5 col-lg-5">
              <div class="">
                <v-card class="login-wrap p-5 p-md-5">
                  <div class="d-flex">
                    <v-card-title class="w-100 card-title mb-2 justify-center">
                      {{ $t('title.verificationcode') }}
                    </v-card-title>
                  </div>
                  <div>
                    <div
                      class="ma-auto position-relative"
                      style="max-width: 300px;"
                    >
                      <v-otp-input
                        v-model="otp"
                        :disabled="isLoading"
                        @finish="onFinish"
                      ></v-otp-input>
                      <v-overlay absolute :value="isLoading">
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-overlay>
                    </div>
                    <v-snackbar
                      v-model="snackbar"
                      :color="snackbarColor"
                      :timeout="2000"
                      :top="true"
                    >
                      {{ text }}
                    </v-snackbar>
                  </div>
                </v-card>
              </div>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </auth-layout>
</template>

<script>
import Dashboard from '../Dashboard.vue'
import AuthLayout from '../auth/AuthLayout'
import { mapGetters } from 'vuex'
import i18n from '../../i18n'

export default {
  name: 'Register',
  data() {
    return {
      token: null,
      isLoading: false,
      snackbar: false,
      snackbarColor: 'default',
      otp: '',
      text: '',
      email: '',
      // expectedOtp: '133707',
    }
  },
  components: {
    'auth-layout': AuthLayout,
  },
  watch: {},
  created() {
    this.$root.$i18n.locale = this.langLocal

    try {
      this.email = this.$route.params.data.email
    } catch (error) {
      this.$router.push({
        name: 'login',
      })
    }
  },
  watch: {
    authenticated(value) {
      // let data = {
      //   user: this.user,
      // }
      if (value == true)
        this.$router
          .push({
            name: 'home',
          })
          .catch(() => {})
    },
  },
  computed: {
    ...mapGetters([
      'errors',
      'countries',
      'authenticated',
      'user',
      'langLocal',
    ]),
  },
  methods: {
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms)
      })
    },
    async attempt(data) {
      this.$store.dispatch('attempt', data)
    },
    async onFinish(rsp) {
      this.isLoading = true
      const formdata = new FormData()

      formdata.append('code', rsp)
      formdata.append('email', this.email)

      await this.sleep(1000)

      await this.$store
        .dispatch('getTokenInfo', formdata)
        .then((response) => {
          if (response.data.success == true) {
            this.snackbarColor = 'success'
            this.text = i18n.t('message.code_success')

            setTimeout(() => {
              this.snackbar = true
              this.isLoading = false
            }, 2000)

            this.attempt(response.data)
          } else {
            this.snackbarColor = 'red'
            this.text = i18n.t('message.code_failed')

            setTimeout(() => {
              this.snackbar = true
              this.isLoading = false
              this.otp = null
            }, 2000)
          }
        })
        .catch((error) => {
          this.isLoading = false
          console.error(error)
        })
    },
  },
}
</script>
<style scoped>
@import '../../../public/assets/css/style.css';

#contact-grid {
  width: 100%;
  min-height: 50vh;
}

.mobile-position {
  margin: 10px;
}
.card-title {
  color: rgb(63, 53, 99);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.w3l-footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  z-index: 100;
  width: 100%;
}
.position-relative {
  position: relative;
}
@media (max-width: 700px) {
}
</style>
