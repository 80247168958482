import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/views/auth/Login'
import CodeVerify from '@/views/auth/CodeVerify'
// import CodeVerify from '@/views/auth/Register'ResetPassword
import ResetPassword from '@/views/auth/ResetPassword'
import ListTraitPost from '../views/post-traitement/ListTraitPost'
import ListTraitUnit from '../views/post-traitement/ListTraitUnit'
import Dashboard from '../views/Dashboard'
import CreateRole from '../views/role/CreateRole'
import i18n from '../i18n'
import store from '../store/index'
import ListAgent from '../views/agent/ListAgent'
import DemandsNiveau1 from '../views/demandes/DemandsNiveau1'
import DemandsNiveau2 from '../views/demandes/DemandsNiveau2'
import AdminDemandsNiveau1 from '../views/demandes/AdminDemandsNiveau1'
import ListRecettes from '../views/recettes/ListRecettes'
import NotAuthorize from '../views/error/NotAuthorize'
import ListPostPones from '../views/postpones/ListPostPones'
import ListRDV from '../views/rdv/ListRdv'
import ListVisas from '../views/visas/ListVisas'
import { Role } from '../helpers/roles'

Vue.use(Router)

const routes = [{
        path: '/',
        name: 'home',
        component: Dashboard,
        meta: {
            title: i18n.t('routes.dashboard'),
            middleware: 'auth',
            authorize: [Role.Admin, Role.Niveau1, Role.Niveau2, Role.Niveau3],
        },
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: i18n.t('routes.login'),
            middleware: 'guest',
            authorize: [Role.Admin, Role.Niveau1, Role.Niveau2, Role.Niveau3],
        },
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            title: i18n.t('routes.dashboard'),
            middleware: 'auth',
            authorize: [Role.Admin, Role.Niveau1, Role.Niveau2, Role.Niveau3],
        },
    },
    {
        path: '/create-role',
        name: 'createrole',
        component: CreateRole,
        meta: {
            title: i18n.t('routes.createrole'),
            middleware: 'auth',
            authorize: [Role.Admin],
        },
    },
    {
        path: '/list-agent',
        name: 'listagent',
        component: ListAgent,
        meta: {
            title: i18n.t('routes.listagent'),
            middleware: 'auth',
            authorize: [Role.Admin],
        },
    },
    {
        path: '/demands-niveau1',
        name: 'demandsniveau1',
        component: DemandsNiveau1,
        meta: {
            title: i18n.t('routes.listdemands'),
            middleware: 'auth',
            authorize: [Role.Admin, Role.Niveau1],
        },
    },
    {
        path: '/demands-niveau2',
        name: 'demandsniveau2',
        component: DemandsNiveau2,
        meta: {
            title: i18n.t('routes.listdemands'),
            middleware: 'auth',
            authorize: [Role.Admin, Role.Niveau2],
        },
    },
    {
        path: '/admin-demands-niveau1',
        name: 'admindemands1',
        component: AdminDemandsNiveau1,
        meta: {
            title: i18n.t('routes.listdemands'),
            middleware: 'auth',
            authorize: [Role.Admin],
        },
    },
    {
        path: '/verify-code',
        name: 'codeverify',
        component: CodeVerify,
        meta: {
            title: 'codeverify',
            middleware: 'guest',
            authorize: [Role.Admin, Role.Niveau1, Role.Niveau2, Role.Niveau3],
        },
    },
    {
        path: '/reset-password',
        name: 'resetpassword',
        component: ResetPassword,
        meta: {
            title: 'resetpassword',
            middleware: 'guest',
            authorize: [Role.Admin, Role.Niveau1, Role.Niveau2, Role.Niveau3],
        },
    },
    {
        path: '/list-trait-unit',
        name: 'list-trait-unit',
        component: ListTraitUnit,
        meta: {
            title: 'list-trait-unit',
            middleware: 'auth',
            authorize: [Role.Admin],
        },
    },
    {
        path: '/list-trait-post',
        name: 'list-trait-post',
        component: ListTraitPost,
        meta: {
            title: 'list-trait-post',
            middleware: 'auth',
            authorize: [Role.Admin],
        },
    },
    {
        path: '/list-recettes',
        name: 'list-recettes',
        component: ListRecettes,
        meta: {
            title: 'list-recettes',
            middleware: 'auth',
            authorize: [Role.Admin],
        },
    },
    {
        path: '/notauthorize',
        name: 'notauthorize',
        component: NotAuthorize,
        meta: {
            title: 'notauthorize',
            middleware: 'auth',
            authorize: [Role.Admin, Role.Niveau1, Role.Niveau2, Role.Niveau3],
        },
    },
    {
        path: '/postpones',
        name: 'postpones',
        component: ListPostPones,
        meta: {
            title: 'postpones',
            middleware: 'auth',
            authorize: [Role.Admin],
        },
    },
    {
        path: '/listrdv',
        name: 'listrdv',
        component: ListRDV,
        meta: {
            title: 'Liste des rdv',
            middleware: 'auth',
            authorize: [Role.Admin, Role.Niveau2, Role.Niveau3],
        },
    },
    {
        path: '/listvisas',
        name: 'listvisas',
        component: ListVisas,
        meta: {
            title: 'Liste des visas',
            middleware: 'auth',
            authorize: [Role.Admin],
        },
    }
]

let router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})
router.beforeEach((to, from, next) => {
    document.title = `E-Visa - ${to.meta.title}`
    store.dispatch('getUser')

    const { authorize } = to.meta
    const currentrole = store.state.currentrole
    const currentUser = store.state.user

  

    //   if (authorize) {
    //     if (!currentUser && !store.state.authenticated) {
    //       // not logged in so redirect to login page with the return url
    //       console.log('etttrt')
    //       return next({ name: 'login' })
    //     }

    //     console.log(currentrole)

    //     if (currentrole != null) {
    //       console.log('step1')
    //       console.log(authorize.length)
    //       console.log(authorize.includes(currentrole[0]))
    //       // check if route is restricted by role
    //       if (authorize.length && !authorize.includes(currentrole[0])) {
    //         try {
    //           store
    //             .dispatch('logout')
    //             .then((response) => {})
    //             .catch((error) => {
    //               console.error(error)
    //             })
    //         } catch (e) {
    //           return next({ name: 'login' })
    //         }
    //       }
    //     }
    //   }
    //   // check if route is restricted by role
    //   if (!authorize) {
    //     // role not authorised so redirect to home page
    //     return next({ name: 'login' })
    //   }

    //   next()

    if (authorize) {
        if (to.meta.middleware == 'auth') {
            if (
                store.state.authenticated &&
                store.state.user &&
                currentrole != null
            ) {
                if (authorize.length && authorize.includes(currentrole[0])) {
                    next()
                } else {
                    next({ name: 'notauthorize' })
                }
            } else {
                next({ name: 'login' })
            }
        } else if (to.meta.middleware == 'guest') {
            if (store.state.authenticated && store.state.user) {
                next({ name: 'home' })
            } else {
                next()
            }
        } else {
            next()
        }
    }
})

export default router