/* eslint-disable vue/no-side-effects-in-computed-properties */
<template>
  <MainLayout-component>
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-12">
        <div class="text-center mt-2">
          <v-card
            class="d-flex align-center justify-center mt-4 pa-4 mx-auto mb-4"
            min-height="96"
            outlined
          >
            <div class="text-center">   {{ $t("headers.raisons_list") }}</div>
          </v-card>
          <v-snackbar
            v-model="editSnackbar"
            :color="editSnackbarColor"
            :timeout="3000"
            style="z-index: 1800"
            :top="true"
          >
            {{ snackbar_text }}
          </v-snackbar>
        </div>
        <v-data-table
          :headers="getHeaders"
          :items="postepones"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items-per-page="15"
          class="elevation-2"
          :search="search"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title></v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>

              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('fields.search_text')"
                single-line
                hide-details
                class="mr-5"
              ></v-text-field>

              <v-dialog
                :eager="true"
                v-model="dialog"
                style="z-index: 1500"
                max-width="600px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                   {{ $t("btn.add_raison") }}
                  </v-btn>
                </template>
                <v-card>
                  <div class="text-center">
                    <v-snackbar
                      v-model="snackbar"
                      :color="snackbarColor"
                      :timeout="3000"
                      :top="true"
                    >
                      {{ snackbar_text }}
                    </v-snackbar>
                  </div>
                  <v-card-text>
                    <v-container fluid>
                      <div class="d-flex">
                        <v-card-title
                          class="w-100 card-title mb-2 justify-center"
                        >
                         {{ $t("headers.add_raison") }}
                        </v-card-title>
                      </div>

                      <v-form :eager="true" ref="postForm">
                         <v-alert v-if="message" type="error">
                            {{ message }}
                          </v-alert>
                          
                        <v-row>
                         

                          <v-col cols="12" sm="12">
                            <v-select
                              required
                              v-model="editedItem.motif"
                              @blur="$v.editedItem.motif.$touch()"
                              :items="motif"
                              :label="$t('fields.raison_type')"
                              :error-messages="RaisonPostponesErrors"
                              item-text="name"
                              item-value="value"
                            ></v-select>
                          </v-col>

                          <v-col cols="12" sm="12">
                            <v-textarea
                              required
                              @keyup="uppercase"
                              v-model="editedItem.raison_postpones"
                                 @blur="$v.editedItem.raison_postpones.$touch()"
                              filled
                              :label="$t('fields.comment')"
                              auto-grow
                              :error-messages="CommentaireErrors"
                            ></v-textarea>
                          </v-col>
                        </v-row>

                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      {{ $t("btn.cancel") }}
                    </v-btn>
                    <v-btn
                      :loading="isLoading"
                      color="primary"
                      large
                      :disabled="can_submit"
                      @click.prevent="save"
                      type="submit"
                      text
                    >
                      {{ $t("btn.register") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                style="z-index: 1500"
                v-model="dialogBlock"
                max-width="500px"
              >
                <v-card>
                  <v-card-title
                    class="text-center"
                    style="text-align: center"
                    v-if="postepones[editedIndex]"
                  >
                    {{ postepones[editedIndex].ville }}
                  </v-card-title>
                  <p class="text-center text-h8 p-2">
                    {{ $t("validations.confirmation_action") }}
                  </p>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeBlock">
                      {{ $t("btn.cancel") }}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      :loading="isLoading"
                      @click="blockOrDeblockUnit"
                    >
                      {{ $t("btn.valide") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon
              color="red"
              v-if="item.deleted_at != null"
              small
              @click="blockPost(item)"
            >
              mdi-lock
            </v-icon>
            <v-icon
              color="green"
              v-if="item.deleted_at == null"
              small
              @click="blockPost(item)"
            >
              mdi-lock-open
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-col>
              <i style="font-size: 30px" class="fa-solid fa-circle-info"></i>
              <p>{{ $t("title.no_data") }}</p>
            </v-col>
            <v-col>
              <v-btn text color="primary" @click="getPostPones">{{
                $t("btn.reset")
              }}</v-btn>
            </v-col>
          </template>
        </v-data-table>
      </div>
    </div>
  </MainLayout-component>
</template>
<script>
import MainLayout from "../MainLayout.vue";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import i18n from "../../i18n";

export default {
  name: "PostList",
  mixins: [validationMixin],
  validations: {
    editedItem: {
      raison_postpones: { required },
      motif: { required },
    },
  },
  components: {
    "MainLayout-component": MainLayout,
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogBlock(val) {
      val || this.closeBlock();
    },
  },
  data: () => ({
    raison_postpones: null,
    motif: [
      {
        name: "COMPLEMENT",
        value: "COMPLEMENT",
      },
      {
        name: "REJET",
        value: "REJET",
      },
    ],
    country_desabled: null,
    postepones_items: null,
    message: null,
    isLoading: false,
    snackbar: false,
    editSnackbar: false,
    snackbarColor: "default",
    editSnackbarColor: "default",
    snackbar_text: "",
    search: null,
    sortBy: "motif",
    sortDesc: false,

    dialog: false,
    dialogBlock: false,
    headers: [],
    postepones: [],
    editedIndex: -1,
    editedItem: {
      id: null,
      pays: null,
      motif: null,
      raison_postpones :null,
    },
    defaultItem: {
      id: null,
      pays: null,
      motif: null,
      raison_postpones :null,
    },
  }),
  computed: {
    can_submit() {
      if (
        this.$v.editedItem.raison_postpones.$invalid ||
        this.$v.editedItem.motif.$invalid
      ) {
        return true;
      } else {
        return false;
      }
    },
    getHeaders() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.headers = [
        {
          text: "Raison",
          align: "",
          value: "motif",
        },
        {
          text: i18n.t('headers.comment'),
          align: "",
          value: "raison_postpones",
        },
        { text: "Actions", value: "actions" },
      ];
      return this.headers;
    },


    CommentaireErrors() {
      const errors = [];
      if (!this.$v.editedItem.raison_postpones.$dirty) return errors;
      !this.$v.editedItem.raison_postpones.required &&
        errors.push(i18n.t("validations.comment_required"));
      return errors;
    },
    RaisonPostponesErrors() {
      const errors = [];
      if (!this.$v.editedItem.motif.$dirty) return errors;
      !this.$v.editedItem.motif.required &&
        errors.push(i18n.t("validations.raison_required"));
      return errors;
    },

    ...mapGetters(["errors", "registered" ,"langLocal"]),
  },

  created() {
 this.$root.$i18n.locale = this.langLocal;

    this.getPostPones();
  },

  methods: {
    uppercase() {
      this.editedItem.raison_postpones = this.editedItem.raison_postpones.toUpperCase();
    },


    async blockOrDeblockUnit() {
      this.isLoading = false;
      if (
        this.editedIndex > -1 &&
        this.postepones[this.editedIndex]["deleted_at"] == null
      ) {
        const data = new FormData();
        data.append("id", this.postepones[this.editedIndex].id);
        data.append("bloque", true);
        await this.$store
          .dispatch("updatePostPones", data)
          .then((response) => {
            this.isLoading = false;
            this.editSnackbarColor = "success";
            this.snackbar_text = i18n.t("message.update_success");
            this.editSnackbar = true;
            console.log(this.snackbar);
          })
          .catch((error) => {
            this.isLoading = false;
            this.editSnackbarColor = "red";
            this.snackbar_text = i18n.t("message.error");
            this.editSnackbar = true;
          });
      }
      if (
        this.editedIndex > -1 &&
        this.postepones[this.editedIndex]["deleted_at"] != null
      ) {
        const data = new FormData();
        data.append("id", this.postepones[this.editedIndex].id);
        data.append("debloque", true);
        await this.$store
          .dispatch("updatePostPones", data)
          .then((response) => {
            this.isLoading = false;
            this.editSnackbarColor = "success";
            this.snackbar_text = i18n.t("message.update_success");
            this.editSnackbar = true;
          })
          .catch((error) => {
            this.isLoading = false;
            this.editSnackbarColor = "red";
            this.snackbar_text = i18n.t("message.error");
            this.editSnackbar = true;
          });
      }
      this.dialogBlock = false;
      this.getPostPones();
    },
    blockPost(item) {
      this.editedIndex = this.postepones.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogBlock = true;
    },
    async getPostPones() {
      await this.$store
        .dispatch("getPostPones")
        .then((response) => {
          this.postepones = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    editItem(item) {
      this.editedIndex = this.postepones.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    blockAgentConfirm() {
      this.postepones.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.country_desabled = false;
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeBlock() {
      this.dialogBlock = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    async save() {
      if (this.editedIndex > -1) {
        this.isLoading = true;
        this.$v.$touch();
        const data = new FormData();
        data.append("motif", this.editedItem.motif);
        data.append("raison_postpones", this.editedItem.raison_postpones);
        data.append("id", this.postepones[this.editedIndex].id);

        if (!this.$v.$invalid) {
          await this.$store
            .dispatch("updatePostPones", data)
            .then((response) => {
              this.isLoading = false;
              this.snackbarColor = "success";
              this.snackbar_text = i18n.t("message.update_success");
              this.snackbar = true;
              setTimeout(() => {
                this.close();
              }, 3000);
            })
            .catch((error) => {
              this.isLoading = false;
              this.snackbarColor = "red";
              this.snackbar_text = i18n.t("message.error");
              this.snackbar = true;
            });
        }
        this.getPostPones();
      } else {
        this.postepones.push(this.editedItem);
        this.isLoading = true;
        this.$v.$touch();
        const data = new FormData();
        data.append("motif", this.editedItem.motif);
        data.append("raison_postpones", this.editedItem.raison_postpones);

        if (!this.$v.$invalid) {
          await this.$store
            .dispatch("addPostPones", data)
            .then((response) => {
              this.isLoading = false;
              this.$refs.postForm.reset();
              this.$v.$reset();
              try {
                if (response.success) {
                  this.snackbarColor = "success";
                  this.snackbar_text = i18n.t("message.add_success");
                  this.snackbar = true;
                  setTimeout(() => {
                    this.close();
                  }, 3000);
                } else {
                  this.snackbarColor = "red";
                  this.snackbar_text = i18n.t("message.error");
                  this.snackbar = true;
                  setTimeout(() => {
                    this.close();
                  }, 3000);
                }
              } catch (error) {
                this.snackbarColor = "red";
                this.snackbar_text = i18n.t("message.error");
                this.snackbar = true;
                setTimeout(() => {
                  this.close();
                }, 3000);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              this.snackbarColor = "red";
              this.snackbar_text = i18n.t("message.error");
              this.snackbar = true;
            });
        }
        this.getPostPones();
      }
    },
  },
};
</script>
