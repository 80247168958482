<template>
  <div class="row justify-content-center">
    <div class="mt-5 col-lg-12">
      <v-snackbar
        v-model="editSnackbar"
        :color="editSnackbarColor"
        :timeout="3000"
        style="z-index: 1800;"
        :top="true"
      >
        {{ snackbar_text }}
      </v-snackbar>

      <v-card elevation="0" color="basil">
        <v-card-title color="blue-grey lighten-5" class="justify-center py-6">
          <v-col cols="12" sm="12">
            <div style="background: #e0e0e0; padding: 10px;">
              <div v-if="last_action != null">
                <h4
                  v-if="
                    last_action != null &&
                    last_action.toLowerCase() == 'complement'
                  "
                  style="color: #4a4343; margin-left: 10px;"
                >
                  {{ $t('title.complement_info') }}
                </h4>

                <h4
                  v-if="
                    last_action != null &&
                    last_action.toLowerCase() == 'rejeter'
                  "
                  style="color: #4a4343; margin-left: 10px;"
                >
                  {{ $t('title.reject_info') }}
                </h4>

                <ul v-if="comment != null && comment_list != []" id="" class="">
                  <li v-for="(raison, id) in comment_list" :key="id">
                    {{ raison }}
                  </li>
                </ul>
              </div>

              <div>
                <h4
                  v-if="demand"
                  style="text-align: center; color: #4a4343; margin-left: 10px;"
                >
                  {{ $t('title.information') }}
                </h4>
              </div>
            </div>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-tabs class="p-3" flat v-model="tab">
            <v-tabs-slider></v-tabs-slider>

            <v-tab>{{ $t('title.data') }}</v-tab>
            <v-tab>{{ $t('title.doc') }}</v-tab>
          </v-tabs>

          <v-tabs-items class="mt-5" v-model="tab">
            <v-tab-item>
              <v-row class="p-3">
                <v-col cols="12" sm="12">
                  <div style="background: #e0e0e0;">
                    <v-divider></v-divider>
                    <h4 style="color: #4a4343; margin-left: 10px;">
                      {{ $t('title.personal_info') }}
                    </h4>
                    <v-divider></v-divider>
                  </div>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="lastname"
                    required
                    :readonly="action == 'voir'"
                    filled
                    @blur="$v.lastname.$touch()"
                    name="lastname"
                    :label="$t('fields.lastname')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :readonly="action == 'voir'"
                    v-model="firstname"
                    required
                    filled
                    @blur="$v.firstname.$touch()"
                    name="fristname"
                    :label="$t('fields.firstname')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-autocomplete
                    :readonly="action == 'voir'"
                    v-model="genre"
                    required
                    filled
                    item-text="name"
                    @blur="$v.genre.$touch()"
                    :items="genders"
                    :label="$t('fields.gender')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4">
                  <div>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :disabled="action == 'voir'"
                          filled
                          required
                          readonly
                          v-model="birthday"
                          @blur="$v.birthday.$touch()"
                          :label="$t('fields.birthday')"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :disabled-dates="{ weekdays: [1, 7] }"
                        :locale="langLocal"
                        v-model="birthday"
                        :active-picker.sync="activePicker"
                        :max="birthday_min_date"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </v-col>

                <v-col cols="12" sm="5">
                  <v-autocomplete
                    :readonly="action == 'voir'"
                    required
                    v-model="birthcountry"
                    @blur="$v.birthcountry.$touch()"
                    filled
                    :items="birthCountries"
                    :label="$t('fields.birth_country')"
                    item-text="nom_fr_fr"
                    item-value="nom_fr_fr"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    :readonly="action == 'voir'"
                    v-model="birthplace"
                    required
                    filled
                    @blur="$v.birthplace.$touch()"
                    name="birthplace"
                    :label="$t('fields.birth_place')"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-autocomplete
                    :readonly="action == 'voir'"
                    v-model="nationalityNow"
                    required
                    item-text="nationalite_fr_fr"
                    item-value="nationalite_fr_fr"
                    @blur="$v.nationalityNow.$touch()"
                    :items="nationalities"
                    filled
                    :label="$t('fields.current_natinalty')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    :readonly="action == 'voir'"
                    v-model="nationalityOrigin"
                    required
                    item-text="nationalite_fr_fr"
                    item-value="nationalite_fr_fr"
                    @blur="$v.nationalityOrigin.$touch()"
                    :items="nationalities"
                    filled
                    :label="$t('fields.coming_natinalty')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    :readonly="action == 'voir'"
                    v-model="familienstand"
                    required
                    item-text="text"
                    item-value="name"
                    @blur="$v.familienstand.$touch()"
                    :items="familienStandList"
                    filled
                    :label="$t('fields.marial_status')"
                  ></v-autocomplete>
                </v-col>

                <!-- additionnelles -->
                <v-col cols="12" sm="12">
                  <div style="background: #e0e0e0;">
                    <v-divider></v-divider>
                    <h4 style="color: #4a4343; margin-left: 10px;">
                      {{ $t('title.addintional_info') }}
                    </h4>
                    <v-divider></v-divider>
                  </div>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field
                    :readonly="action == 'voir'"
                    v-model="adresse"
                    required
                    filled
                    @blur="$v.adresse.$touch()"
                    name="adress"
                    :label="$t('fields.geo_addresse')"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    :readonly="action == 'voir'"
                    v-model="codepostal"
                    required
                    filled
                    @blur="$v.codepostal.$touch()"
                    name="codepostal"
                    :label="$t('fields.postal_code')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :readonly="action == 'voir'"
                    v-model="town"
                    required
                    filled
                    @blur="$v.town.$touch()"
                    name="town"
                    :label="$t('fields.city')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <vue-phone-number-input
                    :disabled="action == 'voir'"
                    class="mt-4 mb-4"
                    ref="phoneinput"
                    :default-country-code="defaultcountry"
                    required
                    :dark="isdark"
                    @blur="$v.phonenumber.$touch()"
                    v-model="phonenumber"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :readonly="action == 'voir'"
                    v-model="work"
                    required
                    filled
                    @blur="$v.work.$touch()"
                    name="work"
                    label="Profession"
                  ></v-text-field>
                </v-col>

                <!-- voyage -->

                <v-col cols="12" sm="12">
                  <div style="background: #e0e0e0;">
                    <v-divider></v-divider>
                    <h4 style="color: #4a4343; margin-left: 10px;">
                      {{ $t('title.travel_info') }}
                    </h4>
                    <v-divider></v-divider>
                  </div>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field
                    :readonly="action == 'voir'"
                    v-model="passportnumber"
                    required
                    filled
                    @blur="$v.passportnumber.$touch()"
                    type="text"
                    :label="$t('fields.passportnumber')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :readonly="action == 'voir'"
                    required
                    v-model="delivercountry"
                    @blur="$v.delivercountry.$touch()"
                    filled
                    :items="birthCountries"
                    :status_demandeitems="birthCountries"
                    :label="$t('fields.delivercountry')"
                    item-text="nom_fr_fr"
                    item-value="nom_fr_fr"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6">
                  <div>
                    <v-menu
                      ref="delivermenu"
                      v-model="delivermenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :disabled="action == 'voir'"
                          filled
                          readonly
                          required
                          v-model="deliverdate"
                          @blur="$v.deliverdate.$touch()"
                          :label="$t('fields.deliverdate')"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :max="new Date().toISOString().slice(0, 10)"
                        :locale="langLocal"
                        v-model="deliverdate"
                        :active-picker.sync="activeDeliverPicker"
                        @change="deliversave"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-menu
                    ref="expi"
                    v-model="expi"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="action == 'voir'"
                        readonly
                        required
                        filled
                        v-model="expiredate"
                        @blur="$v.expiredate.$touch()"
                        :label="$t('fields.expiredate')"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :min="passport_min_date"
                      :locale="langLocal"
                      v-model="expiredate"
                      :active-picker.sync="editActiveExpirePicker"
                      @change="expi_save"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :readonly="action == 'voir'"
                    required
                    v-model="acreditationcounrty"
                    @blur="$v.acreditationcounrty.$touch()"
                    filled
                    :items="countries"
                    :label="$t('fields.acreditationcounrty')"
                    item-text="pays_origine"
                    item-value="id"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :readonly="action == 'voir'"
                    required
                    v-model="motifvoyage"
                    @blur="$v.motifvoyage.$touch()"
                    filled
                    :items="typesvisa"
                    :label="$t('fields.traval_reason')"
                    item-text="visa_designation"
                    item-value="id"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <div>
                    <v-menu
                      ref="startmenu"
                      v-model="startmenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :disabled="action == 'voir'"
                          filled
                          readonly
                          required
                          v-model="startdate"
                          @blur="$v.startdate.$touch()"
                          :label="$t('fields.startdate')"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :locale="langLocal"
                        v-model="startdate"
                        :active-picker.sync="activeStartPicker"
                        @change="startsave"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-menu
                    ref="endmenu"
                    v-model="endmenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="action == 'voir'"
                        readonly
                        required
                        filled
                        v-model="enddate"
                        @blur="$v.enddate.$touch()"
                        :label="$t('fields.enddate')"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :min="passport_min_date"
                      :locale="langLocal"
                      v-model="enddate"
                      :active-picker.sync="activeEndPicker"
                      @change="endsave"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <!-- contact -->

                <v-col v-if="has_contact == true" cols="12" sm="12">
                  <div style="background: #e0e0e0;">
                    <v-divider></v-divider>
                    <h4 style="color: #4a4343; margin-left: 10px;">
                      {{ $t('title.cam_contact_info') }}
                    </h4>
                    <v-divider></v-divider>
                  </div>
                </v-col>
                <v-col v-if="has_contact == true" cols="12" sm="12">
                  <v-text-field
                    :readonly="action == 'voir'"
                    v-model="contactname"
                    required
                    filled
                    @blur="$v.contactname.$touch()"
                    name="contactname"
                    :label="$t('fields.contactname')"
                  ></v-text-field>
                </v-col>

                <v-col v-if="has_contact == true" cols="12" sm="6">
                  <v-text-field
                    :readonly="action == 'voir'"
                    v-model="contactadresse"
                    required
                    filled
                    @blur="$v.contactadresse.$touch()"
                    name="contactadresse"
                    :label="$t('contactadresse')"
                  ></v-text-field>
                </v-col>
                <v-col v-if="has_contact == true" cols="12" sm="6">
                  <v-text-field
                    :readonly="action == 'voir'"
                    v-model="contacttown"
                    required
                    filled
                    @blur="$v.contacttown.$touch()"
                    name="contacttown"
                    :label="$t('fields.contacttown')"
                  ></v-text-field>
                </v-col>
                <v-col v-if="has_contact == true" cols="12" sm="6">
                  <vue-phone-number-input
                    :disabled="action == 'voir'"
                    ref="contactphone"
                    class="mt-4 mb-4"
                    :default-country-code="defaultcountrycontact"
                    required
                    :dark="isdark"
                    @blur="$v.contactphone.$touch()"
                    v-model="contactphone"
                  />
                </v-col>
                <v-col v-if="has_contact == true" cols="12" sm="6">
                  <v-text-field
                    :readonly="action == 'voir'"
                    v-model="contactemail"
                    prepend-icon="email"
                    type="email"
                    required
                    filled
                    @blur="$v.contactemail.$touch()"
                    name="contactemail"
                    :label="$t('fields.contactemail')"
                  ></v-text-field>
                </v-col>
                <v-col
                  v-show="switch1 == true"
                  style="margin-top: auto; margin-bottom: auto;"
                  cols="12"
                  sm="6"
                >
                  <h4>{{ $t('title.has_familly') }}</h4>
                </v-col>
                <v-col v-show="switch1 == true" cols="12" sm="4">
                  <v-switch
                    :readonly="action == 'voir'"
                    class="ml-2"
                    v-model="switch1"
                    inset
                    :label="has_familly"
                  ></v-switch>
                </v-col>
                <v-col v-show="switch1 == true" cols="12" sm="12">
                  <div style="background: #e0e0e0;">
                    <v-divider></v-divider>
                    <h4 style="color: #4a4343; margin-left: 10px;">
                      {{ $t('title.family_contact_info') }}
                    </h4>
                    <v-divider></v-divider>
                  </div>
                </v-col>
                <v-col v-show="switch1 == true" cols="12" sm="12">
                  <v-text-field
                    :readonly="action == 'voir'"
                    v-model="famillyname"
                    required
                    filled
                    @blur="$v.famillyname.$touch()"
                    name="famillyname"
                    :label="$t('fields.famillyname')"
                  ></v-text-field>
                </v-col>
                <v-col v-show="switch1 == true" cols="12" sm="6">
                  <v-text-field
                    :readonly="action == 'voir'"
                    v-model="famillyadresse"
                    required
                    filled
                    @blur="$v.famillyadresse.$touch()"
                    name="famillyadresse"
                    :label="$t('fields.famillyadresse')"
                  ></v-text-field>
                </v-col>
                <v-col v-show="switch1 == true" cols="12" sm="6">
                  <v-text-field
                    :readonly="action == 'voir'"
                    v-model="famillytown"
                    required
                    filled
                    @blur="$v.famillytown.$touch()"
                    name="contacttown"
                    :label="$t('fields.famillytown')"
                  ></v-text-field>
                </v-col>
                <v-col v-show="switch1 == true" cols="12" sm="6">
                  <vue-phone-number-input
                    :disabled="action == 'voir'"
                    ref="famillyphone"
                    class="mt-4 mb-4"
                    :only-countries="['CM']"
                    :default-country-code="defaultcountryfamilly"
                    required
                    :dark="isdark"
                    @blur="$v.famillyphone.$touch()"
                    v-model="famillyphone"
                  />
                </v-col>
                <v-col v-show="switch1 == true" cols="12" sm="6">
                  <v-text-field
                    :readonly="action == 'voir'"
                    v-model="famillyemail"
                    prepend-icon="email"
                    type="email"
                    required
                    filled
                    @blur="$v.famillyemail.$touch()"
                    name="famillyemail"
                    :label="$t('fields.famillyemail')"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12">
                  <div style="background: #e0e0e0;">
                    <v-divider></v-divider>
                    <h4 style="color: #4a4343; margin-left: 10px;">
                      {{ $t('title.local_cam_info') }}
                    </h4>
                    <v-divider></v-divider>
                  </div>
                </v-col>

                <v-col cols="12" sm="12">
                  <v-text-field
                    :readonly="action == 'voir'"
                    v-model="residencename"
                    required
                    filled
                    @blur="$v.residencename.$touch()"
                    name="residencename"
                    :label="$t('fields.residencename')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :readonly="action == 'voir'"
                    v-model="residenceadresse"
                    required
                    filled
                    @blur="$v.residenceadresse.$touch()"
                    name="residenceadresse"
                    :label="$t('fields.residenceadresse')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :readonly="action == 'voir'"
                    v-model="residencetown"
                    required
                    filled
                    @blur="$v.residencetown.$touch()"
                    name="residencetown"
                    :label="$t('fields.residencetown')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <vue-phone-number-input
                    :disabled="action == 'voir'"
                    class="mt-4 mb-4"
                    ref="residencephone"
                    :only-countries="['CM']"
                    :default-country-code="defaultcountryresidence"
                    required
                    :dark="isdark"
                    @blur="$v.residencephone.$touch()"
                    v-model="residencephone"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :readonly="action == 'voir'"
                    v-model="residenceemail"
                    prepend-icon="email"
                    type="email"
                    required
                    filled
                    @blur="$v.residenceemail.$touch()"
                    name="residenceEmail"
                    :label="$t('fields.residenceemail')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <template>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">{{ $t('title.file_name') }}</th>
                        <th class="text-left">
                          {{ $t('title.file') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ $t('title.passport_picture') }}</td>
                        <td>
                          <div v-if="filepasseport">
                            <img
                              class="img-viewer"
                              @click="show_image(filepasseport_link)"
                              style="
                                margin: 5px;
                                max-width: 50px;
                                max-height: 50px;
                                border-radius: 3px;
                              "
                              v-bind:src="filepasseport_link"
                            />
                            <viewer
                              style="display: none;"
                              :images="current_image"
                            >
                              <img :src="filepasseport" />
                            </viewer>
                          </div>
                        </td>
                      </tr>
                      <tr v-show="action != 'voir'">
                        <td align="center" colspan="2">
                          <v-col cols="12" sm="12">
                            <v-file-input
                              show-size
                              required
                              @blur="$v.filepasseport.$touch()"
                              v-model="filepasseport"
                              :label="$t('copypassport')"
                              filled
                              prepend-icon="mdi-camera"
                              accept="image/png, image/jpeg, image/bmp,  image/svg"
                            ></v-file-input>
                          </v-col>
                        </td>
                      </tr>
                      <tr>
                        <td>{{ $t('title.logement_justify') }}</td>
                        <td>
                          <div v-if="filelogement">
                            <img
                              class="img-viewer"
                              @click="show_image(filelogement_link)"
                              style="
                                margin: 5px;
                                max-width: 50px;
                                max-height: 50px;
                                border-radius: 3px;
                              "
                              v-bind:src="filelogement_link"
                            />
                            <viewer
                              style="display: none;"
                              :images="current_image"
                            >
                              <img :src="filelogement" />
                            </viewer>
                          </div>
                        </td>
                      </tr>

                      <tr v-show="action != 'voir'">
                        <td align="center" colspan="2">
                          <v-col cols="12" sm="12">
                            <v-file-input
                              show-size
                              required
                              @blur="$v.filelogement.$touch()"
                              v-model="filelogement"
                              :label="$t('justificatiflogement')"
                              filled
                              prepend-icon="mdi-camera"
                              accept="image/png, image/jpeg, image/bmp,  image/svg"
                            ></v-file-input>
                          </v-col>
                        </td>
                      </tr>
                      <tr>
                        <td>{{ $t('title.plan_ticket') }}</td>
                        <td>
                          <div v-if="flugticket">
                            <img
                              class="img-viewer"
                              @click="show_image(flugticket_link)"
                              style="
                                margin: 5px;
                                max-width: 50px;
                                max-height: 50px;
                                border-radius: 3px;
                              "
                              v-bind:src="flugticket_link"
                            />
                            <viewer
                              style="display: none;"
                              :images="current_image"
                            >
                              <img :src="flugticket_link" />
                            </viewer>
                          </div>
                        </td>
                      </tr>
                      <tr v-show="action != 'voir'">
                        <td align="center" colspan="2">
                          <v-col cols="12" sm="12">
                            <v-file-input
                              show-size
                              required
                              @blur="$v.flugticket.$touch()"
                              v-model="flugticket"
                              :label="$t('flugticket')"
                              filled
                              prepend-icon="mdi-camera"
                              accept="image/png, image/jpeg, image/bmp,  image/svg"
                            ></v-file-input>
                          </v-col>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :loading="isLoading"
            v-if="action != 'voir'"
            color="primary"
            @click="save_change"
          >
            {{ $t('valide_btn') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>
<script>
import { getIndicatif } from '../phoneCodeCountries.js'
import moment from 'moment'
// import "viewerjs/dist/viewer.css";
import { validationMixin } from 'vuelidate'
import { mapGetters } from 'vuex'
import {
  required,
  minLength,
  email,
  sameAs,
  helpers,
} from 'vuelidate/lib/validators'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import i18n from '../i18n'
const alphaNumAndDotValidator = helpers.regex('alphaNumAndDot', /^[a-z\d.]*$/i)
const regEx = new RegExp(/^.*\.(jpg|JPG|jpeg|JPEG|png|PNG|svg|SVG)$/i)
const nameRegex = helpers.regex(
  'alphaNum',
  /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/,
)

const file_size_validation = (value, vm) => {
  if (!value) {
    return false
  }
  let file = value
  return file.size < 2000000
}

export default {
  name: 'DetailsDemands',
  mixins: [validationMixin],
  validations: {
    comment: { required },
    acreditationcounrty: { required },
    contactname: { required },
    contactadresse: { required },
    contacttown: { required },
    contactphone: { required },
    contactemail: { required, email },
    famillyname: { required },
    famillyadresse: { required },
    famillytown: { required },
    famillyphone: { required },
    famillyemail: { required, email },
    residencename: { required },
    residenceadresse: { required },
    residencetown: { required },
    residencephone: { required },
    residenceemail: { required, email },
    startdate: { required },
    enddate: { required },
    adresse: { required },
    codepostal: { required },
    town: { required },
    work: { required },
    firstname: { required, nameRegex },
    lastname: { required, nameRegex },
    genre: { required },
    birthday: { required },
    visatype: { required },
    birthcountry: { required },
    delivercountry: { required },
    deliverdate: { required },
    birthplace: { required },
    motifvoyage: { required },
    familienstand: { required },
    nationalityOrigin: { required },
    nationalityNow: { required },
    expiredate: { required },
    phonenumber: { required },
    flugticket: { required, file_size_validation },
    filepasseport: { required, file_size_validation },
    filelogement: { required, file_size_validation },
    passportnumber: {
      required,
      alphaNumAndDotValidator,
      minLength: minLength(5),
    },
  },
  components: {
    'vue-phone-number-input': VuePhoneNumberInput,
  },
  props: ['demand', 'action'],
  model: {
    prop: 'demand',
    event: 'update_demand',
  },
  data: () => ({
    last_action: null,
    comment_list: null,
    current_demand: null,
    birthcountry: null,
    expiredate: null,
    code_demande: null,
    filepasseport_link: '',
    filelogement_link: '',
    flugticket_link: '',
    passportnumber: null,
    motifvoyage: null,
    phonenumber: null,
    switch1: false,
    defaultcountry: 'CM',
    defaultcountryfamilly: 'CM',
    defaultcountryresidence: 'CM',
    defaultcountrycontact: 'CM',

    status_demande: null,
    comment: null,
    firstname: null,
    lastname: null,
    genre: null,
    birthday: null,
    id_type_visas: null,
    pays_origine: null,
    motif_voyage: null,
    nationality: null,
    dateexpirationpasseport: null,
    telephone: null,
    filepasseport: null,
    filelogement: null,
    numpasseport: null,
    contactname: null,
    contactadresse: null,
    contacttown: null,
    contactphone: null,
    contactemail: null,
    famillyname: null,
    famillyadresse: null,
    famillytown: null,
    famillyphone: null,
    famillyemail: null,
    residencename: null,
    residenceadresse: null,
    residencetown: null,
    residencephone: null,
    residenceemail: null,
    acreditationcounrty: null,
    delivercountry: null,
    birthplace: null,
    nationalityNow: null,
    nationalityOrigin: null,
    startdate: null,
    enddate: null,
    flugticket: null,
    deliverdate: null,
    adresse: '',
    codepostal: '',
    town: '',
    work: '',
    acreditationcounrty: null,
    birthplace: null,
    familienstand: null,
    flugticket: null,
    activePicker: null,
    editActivePicker: null,
    activeExpirePicker: null,
    editActiveExpirePicker: null,
    activeDeliverPicker: null,
    activeStartPicker: null,
    activeEndPicker: null,
    menu: false,
    editMenu: false,
    delivermenu: false,
    has_contact: null,
    has_familly: 'no',
    startmenu: null,
    endmenu: null,
    all_demands: null,
    birthday_min_date: null,
    passport_min_date: null,
    code_selected: null,
    snackbarColor: 'default',
    snackbar: false,
    editSnackbar: false,
    editSnackbarColor: 'default',
    approuve_demand: null,
    visa_dialog: false,
    passport_images: null,
    logement_images: null,
    complement_images: null,
    comment: null,
    snackbar_text: '',
    show_action: null,
    defaultItem: {},
    tab: 0,
    current_image: null,
    message: null,
    show: false,
    boolean: 1,
    time_history: null,
    raison_list: [],
    color_time: [
      {
        id: 0,
        color: 'pink',
      },
      {
        id: 1,
        color: 'teal lighten-3',
      },
    ],
    editedIndex: null,
    show_dialog: false,
    history_dialog: false,
    radios: 'Pending',
    show_modal: false,
    search_form: false,
    code_input: null,
    menu2: false,
    menu1: false,
    date_debut: null,
    date_fin: null,
    length: 0,
    show_comment: false,
    passport_images: [],
    logement_images: [],
    mission_images: [],
    search: null,
    sortBy: 'created_at',
    sortDesc: false,
    menu: false,
    expi: false,
    visa: null,
    isLoading: false,
    activePicker: null,
    activeExpirePicker: null,
    isOkay: false,
    birthday: null,
    dateexpirationpasseport: null,
    pays_origine: null,
    genders: [
      {
        name: 'F',
        value: 'F',
      },
      {
        name: 'M',
        value: 'M',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [],
    demands: [],
  }),

  computed: {
    familienStandList() {
      let array = [
        {
          id: 0,
          name: 'Marié',
          text: i18n.t('fields.marry'),
        },

        {
          id: 1,
          name: 'Célibataire avec enfant',
          text: i18n.t('fields.with_child'),
        },
        {
          id: 2,
          name: 'Célibataire sans enfant',
          text: i18n.t('fields.no_child'),
        },
        {
          id: 3,
          name: 'Veuve',
          text: i18n.t('fields.widow'),
        },
      ]

      return array
    },

    can_search() {
      if (this.date_debut == null || this.date_fin == null) {
        return true
      } else {
        return false
      }
    },
    computed_demand: {
      get: function () {
        return this.demand
      },
      set: function (value) {
        this.$emit('update_demand', value)
      },
    },
    can_submit() {
      let firsthand
      if (this.switch1) {
        if (
          this.$v.famillyname.$invalid ||
          this.$v.famillyadresse.$invalid ||
          this.$v.famillytown.$invalid ||
          this.$v.famillyphone.$invalid ||
          this.$v.famillyemail.$invalid
        ) {
          firsthand = true
        } else {
          firsthand = false
        }
      } else {
        firsthand = false
      }

      let secondhand
      if (this.has_contact) {
        if (
          this.$v.contactname.$invalid ||
          this.$v.contactadresse.$invalid ||
          this.$v.contacttown.$invalid ||
          this.$v.contactphone.$invalid ||
          this.$v.contactemail.$invalid
        ) {
          secondhand = true
        } else {
          secondhand = false
        }
      } else {
        secondhand = false
      }

      if (
        firsthand ||
        secondhand ||
        this.$v.residencename.$invalid ||
        this.$v.residenceadresse.$invalid ||
        this.$v.residencetown.$invalid ||
        this.$v.residencephone.$invalid ||
        this.$v.residenceemail.$invalid ||
        this.$v.firstname.$invalid ||
        this.$v.lastname.$invalid ||
        this.$v.genre.$invalid ||
        this.$v.birthday.$invalid ||
        this.$v.birthcountry.$invalid ||
        this.$v.birthplace.$invalid ||
        this.$v.nationalityOrigin.$invalid ||
        this.$v.nationalityNow.$invalid ||
        this.$v.familienstand.$invalid ||
        this.$v.phonenumber.$invalid ||
        this.$v.adresse.$invalid ||
        this.$v.codepostal.$invalid ||
        this.$v.town.$invalid ||
        this.$v.work.$invalid ||
        this.$v.expiredate.$invalid ||
        this.$v.delivercountry.$invalid ||
        this.$v.deliverdate.$invalid ||
        this.$v.passportnumber.$invalid ||
        this.$v.filepasseport.$invalid ||
        this.$v.filelogement.$invalid ||
        this.$v.flugticket.$invalid ||
        this.$v.motifvoyage.$invalid ||
        this.$v.startdate.$invalid ||
        this.$v.enddate.$invalid ||
        this.$v.acreditationcounrty.$invalid
      ) {
        return true
      } else {
        return false
      }
    },
    ...mapGetters([
      'langLocal',
      'currentrole',
      'errors',
      'registered',
      'countries',
      'nationalities',
      'typesvisa',
      'birthCountries',
      'isdark',
    ]),
  },
  watch: {
    computed_demand(val) {
      this.status_demande = val.status_demande

      // //update data
      this.lastname = val.nom
      this.firstname = val.prenom
      this.genre = val.genre
      this.birthday = val.birthday
      this.birthcountry = val.birth_country
      this.birthplace = val.birth_place
      this.nationalityNow = val.now_nationality
      this.nationalityOrigin = val.origine_nationality
      this.familienstand = val.civility
      this.adresse = val.adresse_geo
      this.codepostal = val.code_postal
      this.passportnumber = val.numpasseport
      this.delivercountry = val.pays_delivrance
      this.deliverdate = val.date_delivrance_passeport
      this.expiredate = val.dateexpirationpasseport
      this.acreditationcounrty = val.pays_origine
      this.motifvoyage = val.id_type_visas
      this.startdate = val.date_depart
      this.enddate = val.date_retour
      this.contactname = val.nom_contact_cam
      this.contactadresse = val.adrese_contact_cam
      this.contacttown = val.ville_contact_cam
      this.contactemail = val.email_contact_cam
      this.famillyname = val.nom_famille_cam
      this.famillyemail = val.email_famille_cam
      this.famillyadresse = val.adrese_famille_cam
      this.famillytown = val.ville_famille_cam
      this.residencename = val.nom_residence_cam
      this.residenceadresse = val.adrese_residence_cam
      this.residencetown = val.ville_residence_cam
      this.residenceemail = val.email_residence_cam
      this.filepasseport_link = val.file_photopasseport
      this.filelogement_link = val.file_justificationlogement
      this.flugticket_link = val.file_ticket_avaion
      this.town = val.ville
      this.work = val.profession
      this.familienstand = val.civility
      this.code_demande = val.code_demande

      if (val.comment) {
        this.comment = val.comment
        this.comment_list = this.comment.split(',')
      }

      this.last_action = val.last_action

      //telephone personnel
      this.phonenumber = val.telephone.substring(val.telephone.indexOf(' ') + 1)

      this.defaultcountry = getIndicatif(
        val.telephone.substring(1, val.telephone.indexOf(' ')),
      )
      // telephone contact

      if (val.telephone_contact_cam != null) {
        this.contactphone = val.telephone_contact_cam.substring(
          val.telephone_contact_cam.indexOf(' ') + 1,
        )

        this.defaultcountrycontact = getIndicatif(
          val.telephone_contact_cam.substring(
            1,
            val.telephone_contact_cam.indexOf(' '),
          ),
        )
      }

      //telephone famille
      if (val.telephone_famille_cam != null) {
        this.famillyphone = val.telephone_famille_cam.substring(
          val.telephone_famille_cam.indexOf(' ') + 1,
        )

        this.defaultcountryfamilly = getIndicatif(
          val.telephone_famille_cam.substring(
            1,
            val.telephone_famille_cam.indexOf(' '),
          ),
        )
      }

      //telephone resisdence
      if (val.telephone_residence_cam != null) {
        this.residencephone = val.telephone_residence_cam.substring(
          val.telephone_residence_cam.indexOf(' ') + 1,
        )

        this.defaultcountryresidence = getIndicatif(
          val.telephone_residence_cam.substring(
            1,
            val.telephone_residence_cam.indexOf(' '),
          ),
        )
      }

      if (this.contactname && this.motifvoyage == 1) {
        this.has_contact = true
      } else {
        this.has_contact = false
      }
      if (this.famillyname) {
        this.switch1 = true
        this.has_familly = 'yes'
      } else {
        this.switch1 = false
        this.has_familly = 'no'
      }

      const passport_file = new File(['blob'], val.file_photopasseport, {
        lastModified: new Date(Date.now()).toISOString().substr(0, 10),
        type: 'text/jpg',
      })

      this.filepasseport = passport_file

      const logement_file = new File(['blob'], val.file_justificationlogement, {
        lastModified: new Date(Date.now()).toISOString().substr(0, 10),
        type: 'text/jpg',
      })

      this.filelogement = logement_file

      const flugticket_file = new File(['blob'], val.file_ticket_avaion, {
        lastModified: new Date(Date.now()).toISOString().substr(0, 10),
        type: 'text/jpg',
      })

      this.flugticket = flugticket_file
    },
    langLocal(val) {
      if (this.switch1 == true) {
        this.has_familly = 'yes'
      } else {
        this.has_familly = 'no'
      }
    },
    motifvoyage(val) {
      if (val == 1) {
        this.has_contact = true
      } else {
        this.has_contact = false
        this.contactname = null
        this.contactemail = null
        this.contactphone = null
        this.contactadresse = null
        this.contacttown = null
      }
    },

    switch1(val) {
      if (val) {
        this.has_familly = 'yes'
      } else {
        this.has_familly = 'no'
        this.famillyname = null
        this.famillyemail = null
        this.famillyphone = null
        this.famillyadresse = null
        this.famillytown = null
      }
    },
    dialog(val) {
      val || this.close()
    },
    comment(val) {
      console.log(val)
    },

    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    editMenu(val) {
      val && setTimeout(() => (this.editActivePicker = 'YEAR'))
    },
    expi(val) {
      val && setTimeout(() => (this.activeExpirePicker = 'YEAR'))
    },
    editExpi(val) {
      val && setTimeout(() => (this.editActiveExpirePicker = 'YEAR'))
    },
    delivermenu(val) {
      val && setTimeout(() => (this.activeDeliverPicker = 'YEAR'))
    },
    startmenu(val) {
      val && setTimeout(() => (this.activeStartPicker = 'YEAR'))
    },
    endmenu(val) {
      val && setTimeout(() => (this.activeEndPicker = 'YEAR'))
    },
  },
  created() {
    this.birthday_min_date = this.yearDiff(14)

    this.passport_min_date = this.subtractMonths(new Date(), +6)

    //  this.show_action = this.action;
    this.$store.dispatch('getcountries')
    this.$store.dispatch('getnationalities')
    this.$store.dispatch('gettypesvisa')
    this.$store.dispatch('getBirthCountries')
    this.initialize()
  },
  methods: {
    initialize() {
      if ((this.computed_demand = !null)) {
        this.status_demande = this.computed_demand.status_demande

        // //update data
        this.lastname = this.computed_demand.nom
        this.firstname = this.computed_demand.prenom
        this.genre = this.computed_demand.genre
        this.birthday = this.computed_demand.birthday
        this.birthcountry = this.computed_demand.birth_country
        this.birthplace = this.computed_demand.birth_place
        this.nationalityNow = this.computed_demand.now_nationality
        this.nationalityOrigin = this.computed_demand.origine_nationality
        this.familienstand = this.computed_demand.civility
        this.adresse = this.computed_demand.adresse_geo
        this.codepostal = this.computed_demand.code_postal
        this.passportnumber = this.computed_demand.numpasseport
        this.delivercountry = this.computed_demand.pays_delivrance
        this.deliverdate = this.computed_demand.date_delivrance_passeport
        this.expiredate = this.computed_demand.dateexpirationpasseport
        this.acreditationcounrty = this.computed_demand.pays_origine
        this.motifvoyage = this.computed_demand.id_type_visas
        this.startdate = this.computed_demand.date_depart
        this.enddate = this.computed_demand.date_retour
        this.contactname = this.computed_demand.nom_contact_cam
        this.contactadresse = this.computed_demand.adrese_contact_cam
        this.contacttown = this.computed_demand.ville_contact_cam
        this.contactemail = this.computed_demand.email_contact_cam
        this.famillyname = this.computed_demand.nom_famille_cam
        this.famillyemail = this.computed_demand.email_famille_cam
        this.famillyadresse = this.computed_demand.adrese_famille_cam
        this.famillytown = this.computed_demand.ville_famille_cam
        this.residencename = this.computed_demand.nom_residence_cam
        this.residenceadresse = this.computed_demand.adrese_residence_cam
        this.residencetown = this.computed_demand.ville_residence_cam
        this.residenceemail = this.computed_demand.email_residence_cam
        this.filepasseport_link = this.computed_demand.file_photopasseport
        this.filelogement_link = this.computed_demand.file_justificationlogement
        this.flugticket_link = this.computed_demand.file_ticket_avaion
        this.town = this.computed_demand.ville
        this.work = this.computed_demand.profession
        this.familienstand = this.computed_demand.civility
        this.code_demande = this.computed_demand.code_demande

        if (this.computed_demand.comment) {
          this.comment = this.computed_demand.comment
          this.comment_list = this.comment.split(',')
        }

        this.last_action = this.computed_demand.last_action

        //telephone personnel
        this.phonenumber = this.computed_demand.telephone.substring(
          this.computed_demand.telephone.indexOf(' ') + 1,
        )

        this.defaultcountry = getIndicatif(
          this.computed_demand.telephone.substring(
            1,
            this.computed_demand.telephone.indexOf(' '),
          ),
        )
        //telephone contact

        if (this.computed_demand.telephone_contact_cam != null) {
          this.contactphone = this.computed_demand.telephone_contact_cam.substring(
            this.computed_demand.telephone_contact_cam.indexOf(' ') + 1,
          )

          this.defaultcountrycontact = getIndicatif(
            this.computed_demand.telephone_contact_cam.substring(
              1,
              this.computed_demand.telephone_contact_cam.indexOf(' '),
            ),
          )
        }

        //telephone famille
        if (this.computed_demand.telephone_famille_cam != null) {
          this.famillyphone = this.computed_demand.telephone_famille_cam.substring(
            this.computed_demand.telephone_famille_cam.indexOf(' ') + 1,
          )

          this.defaultcountryfamilly = getIndicatif(
            this.computed_demand.telephone_famille_cam.substring(
              1,
              this.computed_demand.telephone_famille_cam.indexOf(' '),
            ),
          )
        }

        //telephone resisdence
        if (this.computed_demand.telephone_residence_cam != null) {
          this.residencephone = this.computed_demand.telephone_residence_cam.substring(
            this.computed_demand.telephone_residence_cam.indexOf(' ') + 1,
          )

          this.defaultcountryresidence = getIndicatif(
            this.computed_demand.telephone_residence_cam.substring(
              1,
              this.computed_demand.telephone_residence_cam.indexOf(' '),
            ),
          )
        }

        if (this.contactname && this.motifvoyage == 1) {
          this.has_contact = true
        } else {
          this.has_contact = false
        }
        if (this.famillyname) {
          this.switch1 = true
          this.has_familly = 'yes'
        } else {
          this.switch1 = false
          this.has_familly = 'no'
        }

        const passport_file = new File(
          ['blob'],
          this.computed_demand.file_photopasseport,
          {
            lastModified: new Date(Date.now()).toISOString().substr(0, 10),
            type: 'text/jpg',
          },
        )

        this.filepasseport = passport_file

        const logement_file = new File(
          ['blob'],
          this.computed_demand.file_justificationlogement,
          {
            lastModified: new Date(Date.now()).toISOString().substr(0, 10),
            type: 'text/jpg',
          },
        )

        this.filelogement = logement_file

        const flugticket_file = new File(
          ['blob'],
          this.computed_demand.file_ticket_avaion,
          {
            lastModified: new Date(Date.now()).toISOString().substr(0, 10),
            type: 'text/jpg',
          },
        )

        this.flugticket = flugticket_file
      }
    },

    async save_change() {
      this.isLoading = true

      // step1
      const formdata = new FormData()
      formdata.append('code_demande', this.code_demande)
      formdata.append('nom', this.lastname)
      formdata.append('prenom', this.firstname)
      formdata.append('genre', this.genre)
      formdata.append('birthday', this.birthday)
      formdata.append('birth_country', this.birthcountry)
      formdata.append('birth_place', this.birthplace)
      formdata.append('now_nationality', this.nationalityNow)
      formdata.append('origine_nationality', this.nationalityOrigin)
      formdata.append('civility', this.familienstand)

      // step2
      formdata.append('adresse_geo', this.adresse)
      formdata.append('code_postal', this.codepostal)
      formdata.append('ville', this.town)
      formdata.append(
        'telephone',
        this.$refs.phoneinput.phoneFormatted.replace(/\s+/g, ''),
      )
      formdata.append('profession', this.work)

      // step3
      formdata.append('lieu_obtention_visa', this.acreditationcounrty)
      formdata.append('motif_voyage', this.motifvoyage)
      formdata.append('numpasseport', this.passportnumber)
      formdata.append('dateexpirationpasseport', this.expiredate)
      formdata.append('photopasseport_file', this.filepasseport)
      formdata.append('justificationlogement_file', this.filelogement)
      formdata.append('pays_delivrance', this.delivercountry)
      formdata.append('date_delivrance_passeport', this.deliverdate)
      formdata.append('ticket_avion_file', this.flugticket)
      formdata.append('date_depart', this.startdate)
      formdata.append('date_retour', this.enddate)

      // step4
      formdata.append('nom_contact_cam', this.contactname)
      formdata.append('adrese_contact_cam', this.contactadresse)
      formdata.append('ville_contact_cam', this.contacttown)
      formdata.append('email_contact_cam', this.contactemail)
      formdata.append('nom_famille_cam', this.famillyname)
      formdata.append('adrese_famille_cam', this.famillyadresse)
      formdata.append('ville_famille_cam', this.famillytown)
      formdata.append('email_famille_cam', this.famillyemail)
      formdata.append('nom_residence_cam', this.residencename)
      formdata.append('adrese_residence_cam', this.residenceadresse)
      formdata.append('ville_residence_cam', this.residencetown)
      formdata.append('email_residence_cam', this.residenceemail)

      if (this.contactphone) {
        formdata.append(
          'telephone_contact_cam',
          this.$refs.contactphone.phoneFormatted,
        )
      }
      if (this.famillyphone && this.switch1) {
        formdata.append(
          'telephone_famille_cam',
          this.$refs.famillyphone.phoneFormatted,
        )
      }

      if (this.residencephone) {
        formdata.append(
          'telephone_residence_cam',
          this.$refs.residencephone.phoneFormatted,
        )
      }

      await this.$store
        .dispatch('saveDemandeUpdate', formdata)
        .then((response) => {
          this.isLoading = false
          this.demand = response
          // //update data
          this.lastname = this.demand.nom
          this.firstname = this.demand.prenom
          this.genre = this.demand.genre
          this.birthday = this.demand.birthday
          this.birthcountry = this.demand.birth_country
          this.birthplace = this.demand.birth_place
          this.nationalityNow = this.demand.now_nationality
          this.nationalityOrigin = this.demand.origine_nationality
          this.familienstand = this.demand.civility
          this.adresse = this.demand.adresse_geo
          this.codepostal = this.demand.code_postal
          this.passportnumber = this.demand.numpasseport
          this.delivercountry = this.demand.pays_delivrance
          this.deliverdate = this.demand.date_delivrance_passeport
          this.expiredate = this.demand.dateexpirationpasseport
          this.acreditationcounrty = this.demand.pays_origine
          this.motifvoyage = this.demand.id_type_visas
          this.startdate = this.demand.date_depart
          this.enddate = this.demand.date_retour
          this.contactname = this.demand.nom_contact_cam
          this.contactadresse = this.demand.adrese_contact_cam
          this.contacttown = this.demand.ville_contact_cam
          this.contactemail = this.demand.email_contact_cam
          this.famillyname = this.demand.nom_famille_cam
          this.famillyemail = this.demand.email_famille_cam
          this.famillyadresse = this.demand.adrese_famille_cam
          this.famillytown = this.demand.ville_famille_cam
          this.residencename = this.demand.nom_residence_cam
          this.residenceadresse = this.demand.adrese_residence_cam
          this.residencetown = this.demand.ville_residence_cam
          this.residenceemail = this.demand.email_residence_cam
          this.filepasseport_link = this.demand.file_photopasseport
          this.filelogement_link = this.demand.file_justificationlogement
          this.flugticket_link = this.demand.file_ticket_avaion
          this.town = this.demand.ville
          this.work = this.demand.profession
          this.familienstand = this.demand.civility
          this.code_demande = this.demand.code_demande

          //telephone personnel
          this.phonenumber = this.demand.telephone.substring(
            this.demand.telephone.indexOf(' ') + 1,
          )

          this.defaultcountry = getIndicatif(
            this.demand.telephone.substring(
              1,
              this.demand.telephone.indexOf(' '),
            ),
          )
          //telephone contact

          if (this.demand.telephone_contact_cam != null) {
            this.contactphone = this.demand.telephone_contact_cam.substring(
              this.demand.telephone_contact_cam.indexOf(' ') + 1,
            )

            this.defaultcountrycontact = getIndicatif(
              this.demand.telephone_contact_cam.substring(
                1,
                this.demand.telephone_contact_cam.indexOf(' '),
              ),
            )
          }

          //telephone famille
          if (this.demand.telephone_famille_cam != null) {
            this.famillyphone = this.demand.telephone_famille_cam.substring(
              this.demand.telephone_famille_cam.indexOf(' ') + 1,
            )

            this.defaultcountryfamilly = getIndicatif(
              this.demand.telephone_famille_cam.substring(
                1,
                this.demand.telephone_famille_cam.indexOf(' '),
              ),
            )
          }

          //telephone resisdence
          if (this.demand.telephone_residence_cam != null) {
            this.residencephone = this.demand.telephone_residence_cam.substring(
              this.demand.telephone_residence_cam.indexOf(' ') + 1,
            )

            this.defaultcountryresidence = getIndicatif(
              this.demand.telephone_residence_cam.substring(
                1,
                this.demand.telephone_residence_cam.indexOf(' '),
              ),
            )
          }

          if (this.contactname && this.motifvoyage == 1) {
            this.has_contact = true
          } else {
            this.has_contact = false
          }
          if (this.famillyname) {
            this.switch1 = true
            this.has_familly = 'yes'
          } else {
            this.switch1 = false
            this.has_familly = 'no'
          }

          const passport_file = new File(
            ['blob'],
            this.demand.file_photopasseport,
            {
              lastModified: new Date(Date.now()).toISOString().substr(0, 10),
              type: 'text/jpg',
            },
          )

          this.filepasseport = passport_file

          const logement_file = new File(
            ['blob'],
            this.demand.file_justificationlogement,
            {
              lastModified: new Date(Date.now()).toISOString().substr(0, 10),
              type: 'text/jpg',
            },
          )

          this.filelogement = logement_file

          const flugticket_file = new File(
            ['blob'],
            this.demand.file_ticket_avaion,
            {
              lastModified: new Date(Date.now()).toISOString().substr(0, 10),
              type: 'text/jpg',
            },
          )

          this.flugticket = flugticket_file

          this.editSnackbarColor = 'success'
          this.snackbar_text = 'Modification effectuée avec success'
          this.editSnackbar = true
        })
        .catch((error) => {
          this.isLoading = false
          this.editSnackbarColor = 'red'
          this.snackbar_text = "Une erreure s'est produite"
          this.editSnackbar = true

          console.log(error)
        })
      this.has_passport = false
    },
    yearDiff(number) {
      var date = new Date(),
        mnth = ('0' + (date.getMonth() + 1)).slice(-2),
        day = ('0' + date.getDate()).slice(-2)
      return [date.getFullYear() - number, mnth, day].join('-')
    },
    subtractMonths(date, months) {
      date.setMonth(date.getMonth() + months)
      return this.convert(date)
    },
    save(date) {
      this.$refs.menu.save(date)
    },

    expi_save(newdate) {
      this.$refs.expi.save(newdate)
    },
    deliversave(newdate) {
      this.$refs.delivermenu.save(newdate)
    },
    startsave(date) {
      this.$refs.startmenu.save(date)
    },
    endsave(date) {
      this.$refs.endmenu.save(date)
    },
    menu_expi(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    save_birthday(date) {
      this.$refs.menu.save(date)
    },
    save_expi(date) {
      this.$refs.menu_expi.save(date)
    },
    show_image(img) {
      if (img) {
        let result = img.includes('https') || img.includes('http')
        if (result) {
          this.current_image = [img]
        } else {
          let url = URL.createObjectURL(img)
          this.current_image = [url]
        }
        this.$viewerApi({
          images: this.current_image,
        })
      }
    },

    getColorAttribut(statut) {
      if (statut.toLowerCase() == 'valider') return 'green'
      else if (statut.toLowerCase() == 'pending') return 'orange'
      else if (statut.toLowerCase() == 'complement') return 'info'
      else return 'red'
    },
    formatDate(str) {
      return moment(new Date(str)).format('DD/MM/YYYY')
    },
    convert(str, format = 'fr') {
      var date = new Date(str),
        mnth = ('0' + (date.getMonth() + 1)).slice(-2),
        day = ('0' + date.getDate()).slice(-2)
      if (format == 'fr') return [day, mnth, date.getFullYear()].join('-')
      else return [date.getFullYear(), mnth, day].join('-')
    },
    complement() {
      this.show_dialog = true
      this.comment = ''
      this.status_demande = 'Complement'
    },
    reject() {
      this.show_dialog = true
      this.comment = ''
      this.status_demande = 'Reject'
    },
    onCopy(e) {
      this.show = true
      setTimeout(() => {
        this.show = false
      }, 400)
    },
    onError(e) {
      this.show = true
    },

    getColor(statut, level) {
      if (statut.toLowerCase() == 'valider') return 'green'
      else if (statut.toLowerCase() == 'pending') return 'orange'
      else if (statut.toLowerCase() == 'complement') return 'cyan accent-4'
      else return 'red'
    },
  },
}
</script>
<style scoped>
.custimize-dialog {
  z-index: 8000;
}
.img-viewer:hover {
  cursor: pointer;
}

.v-dialog {
  z-index: 1500;
  width: fit-content !important;
}
li {
  font-size: 16px;
  margin-left: 10px;
  list-style-type: circle;
}
</style>
