<template>
  <auth-layout>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex>
          <div class="row justify-content-center">
            <div class="col-md-5 col-lg-5">
              <v-snackbar
                v-model="snackbar"
                :color="snackbarColor"
                :timeout="3000"
                style="z-index: 1800"
                :top="true"
              >
                {{ snackbar_text }}
              </v-snackbar>
              <div class="">
                <v-card class="login-wrap p-5 p-md-5">
                  <div class="d-flex">
                    <v-card-title class="w-100 card-title mb-2 justify-center">
                      {{ $t("title.login") }}
                    </v-card-title>
                  </div>
                  <v-form>
                    <v-text-field
                      :error-messages="emailErrors"
                      required
                      @blur="$v.email.$touch()"
                      prepend-icon="email"
                      v-model="email"
                      name="email"
                      :label="$t('auth.email')"
                      type="email"
                    ></v-text-field>
                    <v-text-field
                      :error-messages="passwordErrors"
                      required
                      @blur="$v.password.$touch()"
                      v-model="password"
                      id="password"
                      prepend-icon="lock"
                      name="password"
                      :label="$t('auth.password')"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      @click:append="show1 = !show1"
                    ></v-text-field>

                    <v-btn
                      block
                      depressed
                      style="margin-top: 18px; margin-bottom: 18px"
                      :loading="isLOading"
                      :disabled="this.$v.$invalid"
                      @click.prevent="submit"
                      type="submit"
                      color="warning"
                    >
                      {{ $t("btn.login") }}
                    </v-btn>

                    <div class="form-group d-md-flex"></div>
                  </v-form>
                </v-card>
              </div>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </auth-layout>
</template>

<script>
import i18n from "../../i18n";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";
import AuthLayout from "../auth/AuthLayout";

export default {
  name: "Login",
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    password: { required, minLength: minLength(8) },
  },
  components: {
    "auth-layout": AuthLayout,
  },
  data() {
    return {
      show1: false,
      isLOading: false,
      email: null,
      password: null,
      snackbar: false,
      snackbarColor: "default",
      snackbar_text: "",
    };
  },
  methods: {
    submit() {
      this.isLOading = true;
      this.$v.$touch();
      const data = {
        email: this.email,
        password: this.password,
      };
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("login", data)
          .then((response) => {
            if (response.success) {
              this.isLOading = false;
              this.snackbarColor = "green";
              this.snackbar_text = response.message;
              this.snackbar = true;
            } else {
              this.isLOading = false;
              this.snackbarColor = "red";
              this.snackbar_text = i18n.t("message.login_error");
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.isLOading = false;
            this.snackbarColor = "red";
            this.snackbar_text = i18n.t("message.error");
            this.snackbar = true;
            console.log(error);
          });
      }
    },
  },
  beforeCreate() {
    this.$store.dispatch("clear");
  },
  created() {
    this.$root.$i18n.locale = this.langLocal;
  },
  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength &&
        errors.push(i18n.t("validations.min_caracter"));
      !this.$v.password.required &&
        errors.push(i18n.t("validations.required_password"));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(i18n.t("validations.valid_email"));
      !this.$v.email.required &&
        errors.push(i18n.t("validations.required_email"));
      return errors;
    },
    ...mapGetters([
      "errors",
      "authenticated",
      "resetpassword",
      "verifycode",
      "langLocal",
    ]),
  },
  watch: {
    verifycode(value) {
      if (value == true) {
        let data = {
          email: this.email,
        };
        this.$router
          .push({
            name: "codeverify",
            params: { data },
          })
          .catch(() => {});
      }
    },
  },
};
</script>
<style scoped>
@import "../../../public/assets/css/style.css";

#contact-grid {
  width: 100%;
  min-height: 50vh;
}

.mobile-position {
  margin: 10px;
}
.card-title {
  color: rgb(63, 53, 99);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.w3l-footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  z-index: 100;
  width: 100%;
}

@media (max-width: 700px) {
}
</style>
