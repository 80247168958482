import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '../i18n'
import createPersistedState from 'vuex-persistedstate'
import router from '../router/index'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    resetpassword: false,
    authenticated: false,
    currentrole: null,
    verifycode: false,
    registered: false,
    user: {},
    errors: {},
    countries: null,
    globalError: false,
    isdark: false,
    nationalities: null,
    postpones: null,
    typesvisa: null,
    typespost: null,
    birthCountries: null,
    langLocal: 'fr',
  },
  getters: {
    typespost(state) {
      return state.typespost
    },
    birthCountries(state) {
      return state.birthCountries
    },
    resetpassword(state) {
      return state.resetpassword
    },
    authenticated(state) {
      return state.authenticated
    },
    verifycode(state) {
      return state.verifycode
    },
    user(state) {
      return state.user
    },
    registered(state) {
      return state.registered
    },

    errors(state) {
      return state.errors
    },
    countries(state) {
      return state.countries
    },
    langLocal(state) {
      return state.langLocal
    },
    globalError(state) {
      return state.globalError
    },
    isdark(state) {
      return state.isdark
    },
    currentrole(state) {
      return state.currentrole
    },
    nationalities(state) {
      return state.nationalities
    },
    postpones(state) {
      return state.postpones
    },
    typesvisa(state) {
      try {
        let typesvisa = state.typesvisa.map((e) =>
          Object.assign(e, {
            designation: e.designation,
          }),
        )

        let visas = Object.entries(typesvisa).map((arr) => ({
          id: arr[1].id,
          visa_designation: arr[1].designation,
        }))

        return visas
      } catch (error) {}
    },
  },
  mutations: {
    SET_TYPESPOST(state, value) {
      state.typespost = value
    },
    SET_BIRTHCOUNTRIES(state, value) {
      state.birthCountries = value
    },
    SET_RESETPASSWORD(state, value) {
      state.resetpassword = value
    },
    SET_CURRENTROLE(state, value) {
      state.currentrole = value
    },
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value
    },
    SET_VERIFYCODE(state, value) {
      state.verifycode = value
    },
    SET_REGISTERED(state, value) {
      state.registered = value
    },
    SET_USER(state, value) {
      state.user = value
    },
    SET_ERRORS(state, value) {
      state.errors = value
    },
    CLEAR_ERRORS(state) {
      state.errors = {}
    },
    SET_COUNTRIES(state, value) {
      state.countries = value
    },
    SET_GLOBAL_ERROR(state, value) {
      state.globalError = value
    },
    SET_LANGLOCAL(state, value) {
      state.langLocal = value
      i18n.locale = state.langLocal
    },
    SET_ISDARK(state, value) {
      state.isdark = value
    },
    SET_NATIONALITIES(state, value) {
      state.nationalities = value
    },
    SET_POSTPONES(state, value) {
      state.postpones = value
    },
    SET_TYPESVISA(state, value) {
      state.typesvisa = value
    },
  },
  actions: {
    async clear({ commit }) {
      commit('CLEAR_ERRORS')
      commit('SET_VERIFYCODE', false)
      commit('SET_RESETPASSWORD', false)
    },
    async register({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post('backoffice/agents/store', payload)
        commit('SET_REGISTERED', true)
      } catch (e) {
        console.log(e)
      }
    },
    async login({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post('backoffice/login', payload)
        if (response.data.success == true) {
          try {
            if (response.data.data.resetpassword) {
              let data = {
                email: response.data.data.email,
              }

              router.push({
                name: 'resetpassword',
                params: { data },
              })
            }
          } catch (error) {
            commit('SET_VERIFYCODE', true)
          }
        }
        return response.data
      } catch (e) {
        console.log(e)
      }
    },
    async resetPassword({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post('backoffice/change_password', payload)

        return response
      } catch (e) {
        console.log(e)
      }
    },
    async attempt({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie')

      if (payload.success) {
        let user = payload.data.user

        console.log(payload.data.role)
        commit('SET_USER', user)
        commit('SET_AUTHENTICATED', true)
        commit('SET_CURRENTROLE', payload.data.role)
      } else {
        commit('SET_USER', {})
        commit('SET_AUTHENTICATED', false)
      }
    },
    async logout({ commit }) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post('/backoffice/logout')
        if (response.status) {
          commit('SET_USER', {})
          commit('SET_AUTHENTICATED', false)
          commit('SET_CURRENTROLE', null)
          router.push({
            name: 'login',
          })
        } else if ((response.status = 401)) {
          this.$router.push({ name: 'login' })
        } else {
          commit('SET_ERRORS', response.data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getcountries({ commit }) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post('/backoffice/posttraitement')
        let countries = response.data.data
        commit('SET_COUNTRIES', countries)
      } catch (e) {
        console.log(e)
      }
    },
    async setlanguage({ commit }, payload) {
      commit('SET_LANGLOCAL', payload)
    },
    async getUser({ commit }) {
      await axios.get('sanctum/csrf-cookie')
      let response = await axios.get('/api/agent/me')
      let { connected } = response.data
      commit('SET_AUTHENTICATED', connected)
    },

    async getAgents({ commit }) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post('backoffice/agents/index')
        return response.data.data
      } catch (error) {
        console.log(error)
      }
    },
    async updateAgents({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post(
          'backoffice/updateagent/' + payload.get('id'),
          payload,
        )
        return response.data.data
      } catch (error) {
        console.log(error)
      }
    },
    async getRoles({ commit }) {
      await axios.get('sanctum/csrf-cookie')
      let response = await axios.post('backoffice/getroles')
      let role = Object.entries(response.data.data).map((arr) => ({
        id: arr[0],
        name: arr[1],
      }))
      return role
    },

    async getUnites({ commit }) {
      await axios.get('sanctum/csrf-cookie')
      let response = await axios.post('backoffice/unitetraitment')

      let unites = Object.entries(response.data.data).map((arr) => ({
        id: arr[1].id,
        pays: arr[1].pays,
        type_poste: arr[1].type_poste,
        name: arr[1].pays + ' -- ' + arr[1].type_poste,
        ville: arr[1].ville,
        deleted_at: arr[1].deleted_at,
        configrdv: arr[1].configrdv,
        nombre_agent_traitant: arr[1].nombre_agent_traitant,
      }))
  
      return unites
    },

    async getPostPones({ commit }) {
      await axios.get('sanctum/csrf-cookie')
      let response = await axios.post('backoffice/postpones')
      return response.data
    },

    async getPosts({ commit }) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post('backoffice/posttraitement ')
     
        let posts = Object.entries(response.data.data).map((arr) => ({
          id: arr[1].id,
          pays_origine: arr[1].pays_origine,
          unite_traitement: arr[1].pays + ' -- ' + arr[1].designation,
          unite_traitement_id: arr[1].unite_traitement_id,
          deleted_at: arr[1].deleted_at,
        }))
        return posts
      } catch (error) {
        console.log(error)
      }
    },

    async getTokenInfo({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post('backoffice/code_login', payload)
        return response
      } catch (e) {
        console.log(e)
      }
    },

    async getNiveau1AllDemand({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post(
          'backoffice/niveau1/listeDemande',
          payload,
        )
        if (response.data) {
          console.log(response.data)
          return response.data
        }
      } catch (e) {
        return {
          error: e,
        }
      }
    },
    async getNiveau2AllDemand({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post(
          'backoffice/niveau2/listeDemande',
          payload,
        )

        if (response.data.success == true) {
          return response.data
        }
      } catch (e) {
        return {
          error: e,
        }
      }
    },
    async addPostTrait({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post(
          'backoffice/posttraitement_add',
          payload,
        )
        return response.data
      } catch (error) {
        console.log(error)
      }
    },

    async updatePostTrait({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post(
          'backoffice/posttraitement_update',
          payload,
        )
        return response.data.data
      } catch (error) {
        console.log(error)
      }
    },
    async addUnitTrait({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post(
          'backoffice/unitetraitment_add',
          payload,
        )
        return response
      } catch (error) {
        console.log(error)
      }
    },

    async addPostPones({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post('backoffice/storepostpones', payload)
        return response.data
      } catch (error) {
        console.log(error)
      }
    },

    async updateUnitTrait({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post(
          'backoffice/unitetraitment_update',
          payload,
        )
        return response.data
      } catch (error) {
        console.log(error)
      }
    },

    async updatePostPones({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post('backoffice/updatepostpones', payload)
        return response.data.data
      } catch (error) {
        console.log(error)
      }
    },

    async getnationalities({ commit }) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post('nationalites')
        console.log(response)
        let nationalities = response.data
        commit('SET_NATIONALITIES', nationalities)
      } catch (e) {
        console.log(e)
      }
    },

    async gettypesvisa({ commit }) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post('typevisas')
        let typesvisa = Object.entries(response.data.data).map((arr) => ({
          id: arr[1].id,
          designation: arr[1].designation,
        }))

        commit('SET_TYPESVISA', typesvisa)
      } catch (e) {
        console.log(e)
      }
    },
    async updateDemand({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post('backoffice/updateDemande  ', payload)
        return response.data
      } catch (error) {
        console.log(error)
      }
    },
    async searchNiveau1Demand({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post(
          'backoffice/niveau1/searchlistdemande',
          payload,
        )

        return response.data
      } catch (error) {
        return {
          error: error,
        }
      }
    },

    async getDemandByCodeNiveau1({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post(
          'backoffice/niveau1/searchDemande',
          payload,
        )

        return response.data
      } catch (error) {
        return {
          error: error,
        }
      }
    },
    async searchNiveau2Demand({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post(
          'backoffice/niveau2/searchDemande',
          payload,
        )

        return response.data
      } catch (error) {
        return {
          error: error,
        }
      }
    },

    async getDemandMonth({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post(
          'backoffice/listeDemandethismonth',
          payload,
        )

        if (response.data.data) {
          return response.data.data
        } else {
          return []
        }
      } catch (error) {
        return {
          error: error,
        }
      }
    },
    async showTraiter({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post('backoffice/showTraiter', payload)
        if (response.data.success) {
          return response
        }
      } catch (error) {
        return {
          error: error,
        }
      }
    },

    async getNbreDashboard({ commit }) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post('backoffice/showNbreDash')

        if (response.data.success) {
          return response.data.data
        }
      } catch (error) {
        console.log(error)
      }
    },

    async getBirthCountries({ commit }) {
      try {
        let response = await axios.post('backoffice/pays')
        let birthCountries = response.data
        commit('SET_BIRTHCOUNTRIES', birthCountries)
      } catch (e) {
        console.log(e)
      }
    },
    async getTraitement() {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post('backoffice/niveau1/traitementDemande')
        return response.data
      } catch (error) {
        return {
          error: error,
        }
      }
    },

    async getpostpones({ commit }) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post('backoffice/postpones')
        let postpones = response.data.data
        commit('SET_POSTPONES', postpones)
      } catch (e) {
        console.log(e)
      }
    },
    async getTypesPost({ commit }) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post('backoffice/typeunitetaitrement')
        let typespost = response.data.data

        commit('SET_TYPESPOST', typespost)
      } catch (e) {
        console.log(e)
      }
    },

    async getListRDV({ commit }) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post('backoffice/listerdv')
        return response.data
      } catch (e) {
        console.log(e)
      }
    },

    async getListVisas({ commit }) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post('backoffice/visalist')
        return response.data
      } catch (e) {
        console.log(e)
      }
    },
    async getUnitesRecettes({ commit }) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post('backoffice/statistiqueinfo')
        return response.data.data
      } catch (error) {
        console.log(error)
      }
    },

    async getUnitesRecettesByDate({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie')
      try {
        let response = await axios.post('backoffice/statistiqueinfo', payload)
        return response.data
      } catch (error) {
        console.log(error)
      }
    },
  },
  async getVisaInfo({ commit }, payload) {
    await axios.get('sanctum/csrf-cookie')

    try {
      let response = await axios.post(
        'showdemande/' + payload.get('code_demande'),
        payload,
      )
      if (response.status == 200) {
        return response.data
      }
    } catch (error) {
      return {
        error: error,
      }
    }
  },
  plugins: [createPersistedState()],
  modules: {},
})

export default store
