<template>
  <MainLayout-component>
    <div class="row justify-content-center">
      <div class="mt-5 col-md-12 col-lg-12">
        <v-snackbar
          v-model="editSnackbar"
          :color="editSnackbarColor"
          :timeout="3000"
          style="z-index: 1800;"
          :top="true"
        >
          {{ snackbar_text }}
        </v-snackbar>
        <v-dialog fullscreen v-model="waiting_dialog">
          <div class="text-center">
            <v-overlay :value="overlay">
              <v-progress-circular
                :width="3"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-overlay>
          </div>
        </v-dialog>
        <v-row v-show="true" class="">
          <v-col class="text-center" cols="12" md="9">
            <v-text-field
              v-model="code_input"
              label="Rechercher une demande"
              required
            ></v-text-field>
          </v-col>

          <v-col class="text-center" cols="12" md="3">
            <v-btn
              :disabled="isLoading"
              class="text-white"
              :loading="isLoading"
              color="light-blue"
              @click.prevent="search_code"
              type="submit"
            >
              {{ $t('btn.search') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-right" cols="12" sm="12git" md="12">
            <v-dialog
              persistent
              :eager="true"
              content-class="custimize-dialog"
              transition="dialog-bottom-transition"
              scrollable
              v-model="dialog"
              style="z-index: 1500;"
              max-width="100%"
              :retain-focus="false"
            >
              <!-- <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="text-white"
                  color="teal lighten2"
                  @click="search_block = true"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="mr-2 text-white" right> mdi-magnify </v-icon>
                  {{ $t("btn.search_demand") }}
                </v-btn>
              </template> -->

              <template>
                <v-card>
                  <v-snackbar
                    v-model="snackbar"
                    :color="snackbarColor"
                    :timeout="3000"
                    :top="true"
                  >
                    {{ snackbar_text }}
                  </v-snackbar>
                  <v-card-title
                    v-if="
                      last_action != null &&
                      last_action.toLowerCase() != 'valider'
                    "
                  >
                    <v-col cols="12" sm="12">
                      <div
                        v-if="last_action != null"
                        style="background: #e0e0e0; padding: 10px;"
                      >
                        <h4
                          v-if="
                            last_action != null &&
                            last_action.toLowerCase() == 'complement'
                          "
                          style="color: #4a4343; margin-left: 10px;"
                        >
                          {{ $t('title.complement_info') }}
                        </h4>

                        <h4
                          v-if="
                            last_action != null &&
                            last_action.toLowerCase() == 'rejeter'
                          "
                          style="color: #4a4343; margin-left: 10px;"
                        >
                          {{ $t('title.reject_info') }}
                        </h4>

                        <ul
                          v-if="comment != null && comment_list != []"
                          id=""
                          class=""
                        >
                          <li v-for="raison in comment_list" :key="raison">
                            {{ raison }}
                          </li>
                        </ul>
                      </div>
                    </v-col>
                  </v-card-title>
                  <v-card-text>
                    <v-container
                      row
                      justify-center
                      align-center
                      class="mx-auto mt-1"
                    >
                      <v-flex xs12 sm12 md12 lg12 xl12>
                        <div
                          v-show="search_form"
                          style="background: #e0e0e0; padding: 10px;"
                        >
                          <h4
                            style="
                              text-align: center;
                              color: #4a4343;
                              margin-left: 10px;
                            "
                          >
                            {{ $t('title.information') }}
                          </h4>
                        </div>
                        <div v-show="search_form" ref="comment_fields">
                          <template>
                            <v-tabs
                              ref="toolbar"
                              class="p-2"
                              flat
                              v-model="tab"
                            >
                              <v-tabs-slider color="primay"></v-tabs-slider>

                              <v-tab>{{ $t('title.data') }}</v-tab>
                              <v-tab>{{ $t('title.doc') }}</v-tab>
                            </v-tabs>

                            <v-tabs-items class="mt-5" v-model="tab">
                              <v-tab-item>
                                <v-row class="p-3">
                                  <v-col cols="12" sm="12">
                                    <div style="background: #e0e0e0;">
                                      <v-divider></v-divider>
                                      <h4
                                        style="
                                          color: #4a4343;
                                          margin-left: 10px;
                                        "
                                      >
                                        {{ $t('title.personal_info') }}
                                      </h4>
                                      <v-divider></v-divider>
                                    </div>
                                  </v-col>

                                  <v-col cols="12" sm="6">
                                    <v-text-field
                                      v-model="lastname"
                                      required
                                      readonly
                                      filled
                                      @blur="$v.lastname.$touch()"
                                      name="lastname"
                                      :label="$t('fields.lastname')"
                                      :error-messages="lastnameErrors"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6">
                                    <v-text-field
                                      :disabled="true"
                                      readonly
                                      v-model="firstname"
                                      required
                                      filled
                                      @blur="$v.firstname.$touch()"
                                      name="fristname"
                                      :label="$t('fields.firstname')"
                                      :error-messages="firstnameErrors"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="3">
                                    <v-autocomplete
                                      :disabled="true"
                                      readonly
                                      v-model="genre"
                                      required
                                      filled
                                      item-text="name"
                                      @blur="$v.genre.$touch()"
                                      :items="genders"
                                      :label="$t('fields.gender')"
                                      :error-messages="genderErrors"
                                    ></v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" sm="4">
                                    <div>
                                      <v-menu
                                        ref="menu"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-text-field
                                            disabled
                                            filled
                                            required
                                            readonly
                                            v-model="birthday"
                                            @blur="$v.birthday.$touch()"
                                            :label="$t('fields.birthday')"
                                            prepend-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            :error-messages="birthdateErrors"
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                          :disabled-dates="{ weekdays: [1, 7] }"
                                          :locale="langLocal"
                                          v-model="birthday"
                                          :active-picker.sync="activePicker"
                                          :max="birthday_min_date"
                                          @change="save_birthday"
                                        ></v-date-picker>
                                      </v-menu>
                                    </div>
                                  </v-col>

                                  <v-col cols="12" sm="5">
                                    <v-autocomplete
                                      readonly
                                      required
                                      v-model="birthcountry"
                                      @blur="$v.birthcountry.$touch()"
                                      filled
                                      :items="birthCountries"
                                      :label="$t('fields.birth_country')"
                                      :error-messages="birthcountryErrors"
                                      item-text="nom_fr_fr"
                                      item-value="nom_fr_fr"
                                    ></v-autocomplete>
                                  </v-col>

                                  <v-col cols="12" sm="6">
                                    <v-text-field
                                      readonly
                                      v-model="birthplace"
                                      required
                                      filled
                                      @blur="$v.birthplace.$touch()"
                                      name="birthplace"
                                      :label="$t('fields.birth_place')"
                                      :error-messages="birthplaceErrors"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6">
                                    <v-autocomplete
                                      readonly
                                      v-model="nationalityNow"
                                      required
                                      item-text="nationalite_fr_fr"
                                      item-value="nationalite_fr_fr"
                                      @blur="$v.nationalityNow.$touch()"
                                      :items="nationalities"
                                      filled
                                      :label="$t('fields.current_natinalty')"
                                      :error-messages="nationalityNowErrors"
                                    ></v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" sm="6">
                                    <v-autocomplete
                                      readonly
                                      v-model="nationalityOrigin"
                                      required
                                      item-text="nationalite_fr_fr"
                                      item-value="nationalite_fr_fr"
                                      @blur="$v.nationalityOrigin.$touch()"
                                      :items="nationalities"
                                      filled
                                      :label="$t('fields.coming_natinalty')"
                                      :error-messages="nationalityOriginErrors"
                                    ></v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" sm="6">
                                    <v-autocomplete
                                      readonly
                                      v-model="familienstand"
                                      required
                                      item-text="text"
                                      item-value="name"
                                      @blur="$v.familienstand.$touch()"
                                      :items="familienStandList"
                                      filled
                                      :label="$t('fields.marial_status')"
                                      :error-messages="familienstandErrors"
                                    ></v-autocomplete>
                                  </v-col>

                                  <!-- additionnelles -->
                                  <v-col cols="12" sm="12">
                                    <div style="background: #e0e0e0;">
                                      <v-divider></v-divider>
                                      <h4
                                        style="
                                          color: #4a4343;
                                          margin-left: 10px;
                                        "
                                      >
                                        {{ $t('title.addintional_info') }}
                                      </h4>
                                      <v-divider></v-divider>
                                    </div>
                                  </v-col>
                                  <v-col cols="12" sm="12">
                                    <v-text-field
                                      readonly
                                      v-model="adresse"
                                      required
                                      filled
                                      @blur="$v.adresse.$touch()"
                                      name="adress"
                                      :label="$t('fields.geo_addresse')"
                                      :error-messages="adresseErrors"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6">
                                    <v-text-field
                                      readonly
                                      v-model="codepostal"
                                      required
                                      filled
                                      @blur="$v.codepostal.$touch()"
                                      name="codepostal"
                                      :label="$t('fields.postal_code')"
                                      :error-messages="codepostalErrors"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6">
                                    <v-text-field
                                      readonly
                                      v-model="town"
                                      required
                                      filled
                                      @blur="$v.town.$touch()"
                                      name="town"
                                      :label="$t('fields.city')"
                                      :error-messages="townErrors"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6">
                                    <vue-phone-number-input
                                      disabled
                                      class="mt-4 mb-4"
                                      ref="phoneinput"
                                      :default-country-code="defaultcountry"
                                      required
                                      :dark="isdark"
                                      @blur="$v.phonenumber.$touch()"
                                      v-model="phonenumber"
                                      :error-messages="phonenumberErrors"
                                    />
                                  </v-col>
                                  <v-col cols="12" sm="6">
                                    <v-text-field
                                      readonly
                                      v-model="work"
                                      required
                                      filled
                                      @blur="$v.work.$touch()"
                                      name="work"
                                      label="Profession"
                                      :error-messages="workErrors"
                                    ></v-text-field>
                                  </v-col>

                                  <!-- voyage -->

                                  <v-col cols="12" sm="12">
                                    <div style="background: #e0e0e0;">
                                      <v-divider></v-divider>
                                      <h4
                                        style="
                                          color: #4a4343;
                                          margin-left: 10px;
                                        "
                                      >
                                        {{ $t('title.travel_info') }}
                                      </h4>
                                      <v-divider></v-divider>
                                    </div>
                                  </v-col>
                                  <v-col cols="12" sm="12">
                                    <v-text-field
                                      readonly
                                      v-model="passportnumber"
                                      required
                                      filled
                                      @blur="$v.passportnumber.$touch()"
                                      :error-messages="passportnumberErrors"
                                      type="text"
                                      :label="$t('fields.passportnumber')"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12">
                                    <v-autocomplete
                                      readonly
                                      required
                                      v-model="delivercountry"
                                      @blur="$v.delivercountry.$touch()"
                                      filled
                                      :items="birthCountries"
                                      :status_demandeitems="birthCountries"
                                      :label="$t('fields.delivercountry')"
                                      :error-messages="delivercountryErrors"
                                      item-text="nom_fr_fr"
                                      item-value="nom_fr_fr"
                                    ></v-autocomplete>
                                  </v-col>

                                  <v-col cols="12" sm="6">
                                    <div>
                                      <v-menu
                                        ref="delivermenu"
                                        v-model="delivermenu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-text-field
                                            disabled
                                            filled
                                            readonly
                                            required
                                            v-model="deliverdate"
                                            @blur="$v.deliverdate.$touch()"
                                            :label="$t('fields.deliverdate')"
                                            prepend-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            :error-messages="deliverdateErrors"
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                          :max="
                                            new Date()
                                              .toISOString()
                                              .slice(0, 10)
                                          "
                                          :locale="langLocal"
                                          v-model="deliverdate"
                                          :active-picker.sync="
                                            activeDeliverPicker
                                          "
                                          @change="deliversave"
                                        ></v-date-picker>
                                      </v-menu>
                                    </div>
                                  </v-col>
                                  <v-col cols="12" sm="6">
                                    <v-menu
                                      ref="expi"
                                      v-model="expi"
                                      :close-on-content-click="false"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="auto"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-text-field
                                          disabled
                                          readonly
                                          required
                                          filled
                                          v-model="expiredate"
                                          @blur="$v.expiredate.$touch()"
                                          :label="$t('fields.expiredate')"
                                          prepend-icon="mdi-calendar"
                                          v-bind="attrs"
                                          v-on="on"
                                          :error-messages="expiredateErrors"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        :min="passport_min_date"
                                        :locale="langLocal"
                                        v-model="expiredate"
                                        :active-picker.sync="
                                          editActiveExpirePicker
                                        "
                                        @change="expi_save"
                                      ></v-date-picker>
                                    </v-menu>
                                  </v-col>
                                  <v-col cols="12" sm="12">
                                    <v-autocomplete
                                      readonly
                                      required
                                      v-model="acreditationcounrty"
                                      @blur="$v.acreditationcounrty.$touch()"
                                      filled
                                      :items="countries"
                                      :label="$t('fields.acreditationcounrty')"
                                      :error-messages="
                                        acreditationcounrtyErrors
                                      "
                                      item-text="pays_origine"
                                      item-value="id"
                                    ></v-autocomplete>
                                  </v-col>

                                  <v-col cols="12" sm="12">
                                    <v-autocomplete
                                      readonly
                                      required
                                      v-model="motifvoyage"
                                      @blur="$v.motifvoyage.$touch()"
                                      filled
                                      :items="typesvisa"
                                      :label="$t('fields.traval_reason')"
                                      :error-messages="motifErrors"
                                      item-text="visa_designation"
                                      item-value="id"
                                    ></v-autocomplete>
                                  </v-col>

                                  <v-col cols="12" sm="6">
                                    <div>
                                      <v-menu
                                        ref="startmenu"
                                        v-model="startmenu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-text-field
                                            disabled
                                            filled
                                            readonly
                                            required
                                            v-model="startdate"
                                            @blur="$v.startdate.$touch()"
                                            :label="$t('fields.startdate')"
                                            prepend-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            :error-messages="startdateErrors"
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                          :locale="langLocal"
                                          v-model="startdate"
                                          :active-picker.sync="
                                            activeStartPicker
                                          "
                                          @change="startsave"
                                        ></v-date-picker>
                                      </v-menu>
                                    </div>
                                  </v-col>
                                  <v-col cols="12" sm="6">
                                    <v-menu
                                      ref="endmenu"
                                      v-model="endmenu"
                                      :close-on-content-click="false"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="auto"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-text-field
                                          disabled
                                          readonly
                                          required
                                          filled
                                          v-model="enddate"
                                          @blur="$v.enddate.$touch()"
                                          :label="$t('fields.enddate')"
                                          prepend-icon="mdi-calendar"
                                          v-bind="attrs"
                                          v-on="on"
                                          :error-messages="enddateErrors"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        :min="passport_min_date"
                                        :locale="langLocal"
                                        v-model="enddate"
                                        :active-picker.sync="activeEndPicker"
                                        @change="endsave"
                                      ></v-date-picker>
                                    </v-menu>
                                  </v-col>

                                  <!-- contact -->

                                  <v-col
                                    v-if="has_contact == true"
                                    cols="12"
                                    sm="12"
                                  >
                                    <div style="background: #e0e0e0;">
                                      <v-divider></v-divider>
                                      <h4
                                        style="
                                          color: #4a4343;
                                          margin-left: 10px;
                                        "
                                      >
                                        {{ 'title.cam_contact_info' }}
                                      </h4>
                                      <v-divider></v-divider>
                                    </div>
                                  </v-col>
                                  <v-col
                                    v-if="has_contact == true"
                                    cols="12"
                                    sm="12"
                                  >
                                    <v-text-field
                                      readonly
                                      v-model="contactname"
                                      required
                                      filled
                                      @blur="$v.contactname.$touch()"
                                      name="contactname"
                                      :label="$t('fields.contactname')"
                                      :error-messages="contactnameErrors"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col
                                    v-if="has_contact == true"
                                    cols="12"
                                    sm="6"
                                  >
                                    <v-text-field
                                      readonly
                                      v-model="contactadresse"
                                      required
                                      filled
                                      @blur="$v.contactadresse.$touch()"
                                      name="contactadresse"
                                      :label="$t('fields.contactadresse')"
                                      :error-messages="contactadresseErrors"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    v-if="has_contact == true"
                                    cols="12"
                                    sm="6"
                                  >
                                    <v-text-field
                                      readonly
                                      v-model="contacttown"
                                      required
                                      filled
                                      @blur="$v.contacttown.$touch()"
                                      name="contacttown"
                                      :label="$t('fields.contacttown')"
                                      :error-messages="contacttownErrors"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    v-if="has_contact == true"
                                    cols="12"
                                    sm="6"
                                  >
                                    <vue-phone-number-input
                                      disabled
                                      ref="contactphone"
                                      class="mt-4 mb-4"
                                      :default-country-code="
                                        defaultcountrycontact
                                      "
                                      required
                                      :dark="isdark"
                                      @blur="$v.contactphone.$touch()"
                                      v-model="contactphone"
                                      :error-messages="contactphoneErrors"
                                    />
                                  </v-col>
                                  <v-col
                                    v-if="has_contact == true"
                                    cols="12"
                                    sm="6"
                                  >
                                    <v-text-field
                                      readonly
                                      v-model="contactemail"
                                      prepend-icon="email"
                                      type="email"
                                      required
                                      filled
                                      @blur="$v.contactemail.$touch()"
                                      name="contactemail"
                                      :label="$t('fields.contactemail')"
                                      :error-messages="contactemailErrors"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    style="
                                      margin-top: auto;
                                      margin-bottom: auto;
                                    "
                                    cols="12"
                                    sm="6"
                                    v-if="switch1"
                                  >
                                    <h4>{{ $t('title.has_familly') }}</h4>
                                  </v-col>
                                  <v-col v-if="switch1" cols="12" sm="4">
                                    <v-switch
                                      readonly
                                      class="ml-2"
                                      v-model="switch1"
                                      inset
                                      :label="has_familly"
                                    ></v-switch>
                                  </v-col>
                                  <v-col
                                    v-show="switch1 == true"
                                    cols="12"
                                    sm="12"
                                  >
                                    <div style="background: #e0e0e0;">
                                      <v-divider></v-divider>
                                      <h4
                                        style="
                                          color: #4a4343;
                                          margin-left: 10px;
                                        "
                                      >
                                        {{ 'title.family_contact_info' }}
                                      </h4>
                                      <v-divider></v-divider>
                                    </div>
                                  </v-col>
                                  <v-col
                                    v-show="switch1 == true"
                                    cols="12"
                                    sm="12"
                                  >
                                    <v-text-field
                                      readonly
                                      v-model="famillyname"
                                      required
                                      filled
                                      @blur="$v.famillyname.$touch()"
                                      name="famillyname"
                                      :label="$t('fields.famillyname')"
                                      :error-messages="famillynameErrors"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    v-show="switch1 == true"
                                    cols="12"
                                    sm="6"
                                  >
                                    <v-text-field
                                      readonly
                                      v-model="famillyadresse"
                                      required
                                      filled
                                      @blur="$v.famillyadresse.$touch()"
                                      name="famillyadresse"
                                      :label="$t('fields.famillyadresse')"
                                      :error-messages="famillyadresseErrors"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    v-show="switch1 == true"
                                    cols="12"
                                    sm="6"
                                  >
                                    <v-text-field
                                      readonly
                                      v-model="famillytown"
                                      required
                                      filled
                                      @blur="$v.famillytown.$touch()"
                                      name="contacttown"
                                      :label="$t('fields.famillytown')"
                                      :error-messages="famillytownErrors"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    v-show="switch1 == true"
                                    cols="12"
                                    sm="6"
                                  >
                                    <vue-phone-number-input
                                      disabled
                                      ref="famillyphone"
                                      class="mt-4 mb-4"
                                      :only-countries="['CM']"
                                      :default-country-code="
                                        defaultcountryfamilly
                                      "
                                      required
                                      :dark="isdark"
                                      @blur="$v.famillyphone.$touch()"
                                      v-model="famillyphone"
                                      :error-messages="famillyphoneErrors"
                                    />
                                  </v-col>
                                  <v-col
                                    v-show="switch1 == true"
                                    cols="12"
                                    sm="6"
                                  >
                                    <v-text-field
                                      readonly
                                      v-model="famillyemail"
                                      prepend-icon="email"
                                      type="email"
                                      required
                                      filled
                                      @blur="$v.famillyemail.$touch()"
                                      name="famillyemail"
                                      :label="$t('fields.famillyemail')"
                                      :error-messages="famillyemailErrors"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="12">
                                    <div style="background: #e0e0e0;">
                                      <v-divider></v-divider>
                                      <h4
                                        style="
                                          color: #4a4343;
                                          margin-left: 10px;
                                        "
                                      >
                                        {{ $t('title.local_cam_info') }}
                                      </h4>
                                      <v-divider></v-divider>
                                    </div>
                                  </v-col>

                                  <v-col cols="12" sm="12">
                                    <v-text-field
                                      readonly
                                      v-model="residencename"
                                      required
                                      filled
                                      @blur="$v.residencename.$touch()"
                                      name="residencename"
                                      :label="$t('fields.residencename')"
                                      :error-messages="residencenameErrors"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6">
                                    <v-text-field
                                      readonly
                                      v-model="residenceadresse"
                                      required
                                      filled
                                      @blur="$v.residenceadresse.$touch()"
                                      name="residenceadresse"
                                      :label="$t('fields.residenceadresse')"
                                      :error-messages="residenceadresseErrors"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6">
                                    <v-text-field
                                      readonly
                                      v-model="residencetown"
                                      required
                                      filled
                                      @blur="$v.residencetown.$touch()"
                                      name="residencetown"
                                      :label="$t('fields.residencetown')"
                                      :error-messages="residencetownErrors"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6">
                                    <vue-phone-number-input
                                      disabled
                                      class="mt-4 mb-4"
                                      ref="residencephone"
                                      :only-countries="['CM']"
                                      :default-country-code="
                                        defaultcountryresidence
                                      "
                                      required
                                      :dark="isdark"
                                      @blur="$v.residencephone.$touch()"
                                      v-model="residencephone"
                                      :error-messages="residencephoneErrors"
                                    />
                                  </v-col>
                                  <v-col cols="12" sm="6">
                                    <v-text-field
                                      readonly
                                      v-model="residenceemail"
                                      prepend-icon="email"
                                      type="email"
                                      required
                                      filled
                                      @blur="$v.residenceemail.$touch()"
                                      name="residenceEmail"
                                      :label="$t('fields.residenceemail')"
                                      :error-messages="residenceemailErrors"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-tab-item>
                              <v-tab-item>
                                <template>
                                  <v-simple-table>
                                    <template v-slot:default>
                                      <thead>
                                        <tr>
                                          <th class="text-left">
                                            {{ $t('title.file_name') }}
                                          </th>
                                          <th class="text-left">Fichier</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>
                                            {{ $t('title.passport_picture') }}
                                          </td>
                                          <td>
                                            <div v-if="filepasseport">
                                              <img
                                                class="img-viewer"
                                                @click="
                                                  show_image(filepasseport_link)
                                                "
                                                style="
                                                  margin: 5px;
                                                  max-width: 50px;
                                                  max-height: 50px;
                                                  border-radius: 3px;
                                                "
                                                v-bind:src="filepasseport_link"
                                              />
                                              <viewer
                                                style="display: none;"
                                                :images="current_image"
                                              >
                                                <img :src="filepasseport" />
                                              </viewer>
                                            </div>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>
                                            {{ $t('title.logement_justify') }}
                                          </td>

                                          <td>
                                            <div v-if="filelogement">
                                              <img
                                                class="img-viewer"
                                                @click="
                                                  show_image(filelogement_link)
                                                "
                                                style="
                                                  margin: 5px;
                                                  max-width: 50px;
                                                  max-height: 50px;
                                                  border-radius: 3px;
                                                "
                                                v-bind:src="filelogement_link"
                                              />
                                              <viewer
                                                style="display: none;"
                                                :images="current_image"
                                              >
                                                <img :src="filelogement" />
                                              </viewer>
                                            </div>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>{{ $t('title.plan_ticket') }}</td>

                                          <td>
                                            <div v-if="flugticket">
                                              <img
                                                class="img-viewer"
                                                @click="
                                                  show_image(flugticket_link)
                                                "
                                                style="
                                                  margin: 5px;
                                                  max-width: 50px;
                                                  max-height: 50px;
                                                  border-radius: 3px;
                                                "
                                                v-bind:src="flugticket_link"
                                              />
                                              <viewer
                                                style="display: none;"
                                                :images="current_image"
                                              >
                                                <img :src="flugticket_link" />
                                              </viewer>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </template>
                                  </v-simple-table>
                                </template>
                              </v-tab-item>
                            </v-tabs-items>
                          </template>
                        </div>
                      </v-flex>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="orange lighten-1"
                      class="text-white"
                      :disabled="isLoading"
                      @click="close"
                    >
                      {{ $t('btn.cancel') }}
                    </v-btn>

                    <v-btn
                      :disabled="isLoading"
                      ref="valider_btn"
                      :loading="isLoading"
                      v-show="show_btn == true"
                      class="text-white"
                      color="green"
                      @click="save('VALIDER')"
                    >
                      {{ $t('btn.valide') }}
                    </v-btn>
                    <v-btn
                      ref="complement_btn"
                      :disabled="isLoading"
                      v-show="show_btn == true"
                      class="text-white"
                      color="blue"
                      @click="complement()"
                    >
                      {{ $t('btn.complement') }}
                    </v-btn>
                    <v-dialog persistent v-model="show_dialog" width="500">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="text-white ml-1"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="isLoading"
                          v-show="show_btn == true"
                          color="red "
                          @click="reject()"
                        >
                          {{ $t('btn.reject') }}
                        </v-btn>
                      </template>

                      <v-card max-width="1000">
                        <v-card-title class="text-white text-h8 blue">
                          <v-btn
                            icon
                            small
                            color="red"
                            class="text-white"
                            @click="show_dialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                          {{ $t('title.reject_raison') }}
                        </v-card-title>
                        <v-card-text>
                          <v-autocomplete
                            v-model="comment"
                            item-text="raison_postpones"
                            item-value="raison_postpones"
                            :items="raison_list"
                            class="m-4"
                            multiple
                            :label="$t('fields.reason')"
                          ></v-autocomplete>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn
                            ref="valider_btn"
                            :loading="isLoading"
                            class="text-white m-4"
                            color="blue"
                            :disabled="this.$v.comment.$invalid"
                            @click="save(status_demande)"
                          >
                            Confirmer
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
          <!-- <v-col class="cols" cols="12" sm="12" md="12">
            <v-card
              class="d-flex align-center justify-center mt-2 pa-4 mx-auto"
              min-height="86"
              outlined
            >
              <div class="text-center">
                {{ $t("title.list_demands") }}
              </div>
            </v-card>
          </v-col> -->
        </v-row>
        <v-card
          class="d-flex align-center justify-center mt-4 pa-4 mx-auto mb-2"
          outlined
        >
          <v-row no-gutters>
            <v-col cols="12" class="m-2" sm="3" md="3">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_debut"
                    :label="$t('fields.date_start')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_debut"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" class="m-2" sm="3" md="3">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_fin"
                    :label="$t('fields.date_end')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_fin"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" class="p-2" sm="2" md="2">
              <v-btn
                block
                :disabled="can_search || isLoading"
                @click="search_date"
                color="primary"
                class="m-2"
                :loading="isLoading"
              >
                {{ $t('btn.filter') }}
              </v-btn>
            </v-col>
            <v-col class="p-2" cols="12" md="3">
              <v-btn
                block
                class="text-white m-2"
                :disabled="can_search"
                color="red"
                @click.prevent="
                  ;(date_debut = null), (date_fin = null), getDemands()
                "
              >
                {{ $t('btn.search_cancel') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-row>
          <v-col cols="12" md="12" sm="12">
            <v-radio-group v-model="radios" class="mx-3 mt-5 pt-2" row>
              <v-row>
                <v-col cols="12" md="3" sm="3">
                  <v-radio
                    name="radios"
                    :label="$t('title.pending')"
                    value="pending"
                    color="orange"
                  ></v-radio>
                </v-col>
                <v-col cols="12" md="3" sm="3">
                  <v-radio
                    color="info"
                    name="radios"
                    label="Complement"
                    value="complement"
                  ></v-radio>
                </v-col>
                <v-col cols="12" md="3" sm="3">
                  <v-radio
                    name="radios"
                    :label="$t('title.rejected')"
                    value="reject"
                    color="red"
                  ></v-radio>
                </v-col>
                <v-col cols="12" md="3" sm="3">
                  <v-radio
                    color="green"
                    name="radios"
                    :label="$t('title.valided')"
                    value="valide"
                  ></v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-data-table
          :headers="getHeaders"
          :items="demands"
          :search="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items-per-page="5"
          class="elevation-2"
        >
          <template v-slot:top>
            <v-toolbar class="pt-3 mb-5" flat>
              <v-toolbar-title class="mt-5 mb-5"></v-toolbar-title>

              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('fields.search_text')"
                single-line
                hide-details
              ></v-text-field>
            </v-toolbar>
          </template>

          <template v-slot:[`item.code_demande`]="{ item }">
            {{ item.code_demande }}
            <v-btn class="text-center" icon>
              <v-icon
                small
                color="green"
                v-clipboard:copy="item.code_demande"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                mdi-clipboard-multiple
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.status_demande`]="{ item }">
            <span>
              <v-chip :color="getColor(item.status_demande, item.level)" dark>
                <span
                  v-if="
                    item.status_demande == 'pending' &&
                    item.level.toLowerCase() == 'niveau2'
                  "
                >
                  valider
                </span>
                <span v-else>{{ item.status_demande }}</span>
              </v-chip>
              <v-btn
                icon
                @click="getHistory(item)"
                class="mx-2"
                x-small
                color="primary"
              >
                <v-icon>mdi-account-alert</v-icon>
              </v-btn>
            </span>
            <v-dialog
              :retain-focus="false"
              persistent
              v-model="history_dialog"
              width="650"
              style="z-index: 1900;"
            >
              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  <v-btn
                    icon
                    x-small
                    color="primary"
                    text
                    @click="history_dialog = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-text>
                  <v-timeline align-top dense>
                    <v-timeline-item
                      v-for="item in time_history"
                      :key="item.status_demande"
                      :color="color_time[item.color]['color']"
                      small
                    >
                      <v-row class="pt-1">
                        <v-col cols="3">
                          <strong>{{ item.status_demande }}</strong>
                        </v-col>
                        <v-col>
                          <strong>
                            {{ item.lastname + ' ' + item.firstname }}
                          </strong>
                          <div class="text-caption">
                            {{ convert(item.created_at) }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-timeline-item>
                  </v-timeline>
                </v-card-text>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.nom`]="{ item }">
            {{ item.nom + ' ' + item.prenom }}
          </template>
          <template v-slot:[`item.id_type_visas`]="{ item }">
            {{ item.type_visas }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="showDemand(item)">
              mdi-eye
            </v-icon>
            <v-icon
              small
              @click="view_demand(item)"
              v-if="
                item.status_demande.toLowerCase() == 'valider' &&
                item.level.toLowerCase() == 'niveau2'
              "
              color="green"
              class="mr-2"
            >
              mdi-passport
            </v-icon>
          </template>
          <template v-slot:[`item.updated_at`]="{ item }">
            {{ formatDate(item.updated_at, 'L LTS') }}
          </template>

          <template v-slot:[`item.last_time`]="{ item }">
            {{ getDifferenceInDate(item.updated_at) }}
          </template>

          <template v-slot:no-data>
            <v-col>
              <i style="font-size: 30px;" class="fa-solid fa-circle-info"></i>
              <p>{{ $t('title.no_data') }}</p>
            </v-col>
            <v-col>
              <v-btn text color="primary" @click="getDemands">
                {{ $t('btn.reset') }}
              </v-btn>
            </v-col>
          </template>
        </v-data-table>

        <v-dialog
          persistent
          v-model="show_modal"
          width="1500"
          style="z-index: 1500;"
          content-class="custimize-dialog"
          transition="dialog-bottom-transition"
          :retain-focus="false"
        >
          <v-card>
            <v-card-text>
              <details-demand
                v-bind:demand.sync="current_item"
                :action="'voir'"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red lighten-1"
                class="text-white"
                :disabled="isLoading"
                @click="show_modal = false"
              >
                {{ $t('btn.cancel') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="visa_dialog" width="900" style="z-index: 1888;">
          <v-btn
            class="mb-2"
            :loading="isLoading"
            color="primary"
            @click="generatePdf"
          >
            Télécharger / Download
          </v-btn>

          <v-card>
            <v-card-title>
              <v-btn
                small
                icon
                color="red"
                class="mr-2"
                @click="visa_dialog = false"
              >
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text
              v-show="true"
              id="visa_dialog_print"
              ref="vignette"
              v-if="approuve_demand"
            >
              <v-row class="visa_dialog_print_margin" cols="12">
                <v-col class="padding-0" cols="8">
                  <span class="title_span">
                    PLACE OF ISSUE / LIEU DE DELIVRANCE : <br />
                  </span>
                  <span class="contains_span">
                    {{
                      approuve_demand.designation +
                      ' de ' +
                      approuve_demand.pays
                    }}
                  </span>
                </v-col>

                <v-col class="padding-0" cols="4"
                  ><span class="title_span"> TYPE/TYPE : <br /> </span>
                  <span class="contains_span">
                    {{
                      typesvisa.filter((element) => {
                        return element.id == approuve_demand.id_type_visas
                      })[0].visa_designation
                    }}
                  </span>
                </v-col>

                <!-- seconde line  -->

                <v-col class="padding-0" cols="4"
                  ><span class="title_span">
                    DOCUMENT/N° DOCUMENT : <br />
                  </span>
                  <span class="contains_span">
                    {{ approuve_demand.code_demande }}
                  </span>
                </v-col>

                <v-col class="padding-0" cols="4"
                  ><span class="title_span">
                    VALIDE FROM / VALIDE DU : <br />
                  </span>
                  <span class="contains_span">
                    {{ formatDate(approuve_demand.date_depart, "L") }}
                  </span>
                </v-col>


                <v-col class="padding-0" cols="4"
                  ><span class="title_span">
                    VALIDE UNTIL / VALIDE AU : <br />
                  </span>
                  <span class="contains_span">
                    {{ formatDate(approuve_demand.valideted_at, "L") }}
                  </span>
                </v-col>

                <!-- third line -->
                 <v-col class="padding-0" cols="8"
                  ><span class="title_span">
                    SURNAME, GIVEN NAME/NOM, PRENOM : <br />
                  </span>
                  <span class="contains_span">
                    {{ approuve_demand.nom + " " + approuve_demand.prenom }}
                  </span>
                </v-col>

              
                <v-col class="padding-0" cols="4"
                  ><span class="title_span">
                    NATIONALITY / NATIONALITÉ : <br />
                  </span>
                  <span class="contains_span">
                    {{ approuve_demand.origine_nationality }}
                  </span>
                </v-col>


                 <!-- forth line -->
                <v-col class="padding-0" cols="4"
                  ><span class="title_span">
                    DATE OF BIRTH /DATE DE NAISSANCE : <br />
                  </span>
                  <span class="contains_span">
                    {{ formatDate(approuve_demand.birthday, "L") }}
                  </span>
                </v-col>

                <v-col class="padding-0" cols="4"
                  ><span class="title_span">
                    PASSPORT No /N° DU PASSPORT : <br />
                  </span>
                  <span class="contains_span">
                    {{ approuve_demand.numpasseport }}
                  </span>
                </v-col>

                <v-col class="padding-0" cols="2"
                  ><span class="title_span"> SEXE/ SEX : <br /> </span>
                  <span class="contains_span">
                    {{ approuve_demand.genre }}
                  </span>
                </v-col>

                
                <v-col class="padding-0" cols="12">
                  <span
                    style="
                      font-weight: bold;
                      font-size: 24px;
                      letter-spacing: 2px;
                      justify-content: center;
                      line-height: normal;
                      font-family: monospace;
                    "
                  >
                    {{ code_mrz_user }} <br />
                    {{ code_mrz_visas }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </MainLayout-component>
</template>
<script>
import SockJS from 'sockjs-client'
import Stomp from 'webstomp-client'
import { getIndicatif } from '../../phoneCodeCountries.js'
import { getAlphaCode } from '../../nationalitiesHelper.js'
import moment from 'moment'
import 'viewerjs/dist/viewer.css'
import VuetelephoneInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { validationMixin } from 'vuelidate'
import VueQRCodeComponent from 'vue-qrcode-component'
import html2pdf from 'html2pdf.js'

import {
  required,
  minLength,
  email,
  sameAs,
  helpers,
} from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import i18n from '../../i18n'
import MainLayout from '../MainLayout'
import DetailsDemand from '../../components/DetailsDemand.vue'
const alphaNumAndDotValidator = helpers.regex('alphaNumAndDot', /^[a-z\d.]*$/i)
const regEx = new RegExp(/^.*\.(jpg|JPG|jpeg|JPEG|png|PNG|svg|SVG)$/i)
const nameRegex = helpers.regex(
  'alphaNum',
  /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/,
)
const file_size_validation = (value, vm) => {
  if (!value) {
    return false
  }
  let file = value
  return file.size < 2000000
}

export default {
  name: 'ListDemands',
  components: {
    'MainLayout-component': MainLayout,
    'vue-phone-number-input': VuetelephoneInput,
    'details-demand': DetailsDemand,
    'qr-code': VueQRCodeComponent,
  },
  mixins: [validationMixin],
  validations: {
    last_time: null,
    comment: { required },
    acreditationcounrty: { required },
    contactname: { required },
    contactadresse: { required },
    contacttown: { required },
    contactphone: { required },
    contactemail: { required, email },
    famillyname: { required },
    famillyadresse: { required },
    famillytown: { required },
    famillyphone: { required },
    famillyemail: { required, email },
    residencename: { required },
    residenceadresse: { required },
    residencetown: { required },
    residencephone: { required },
    residenceemail: { required, email },
    startdate: { required },
    enddate: { required },
    adresse: { required },
    codepostal: { required },
    town: { required },
    work: { required },
    firstname: { required, nameRegex },
    lastname: { required, nameRegex },
    genre: { required },
    birthday: { required },
    visatype: { required },
    birthcountry: { required },
    delivercountry: { required },
    deliverdate: { required },
    birthplace: { required },
    motifvoyage: { required },
    familienstand: { required },
    nationalityOrigin: { required },
    nationalityNow: { required },
    expiredate: { required },
    phonenumber: { required },
    flugticket: { required, file_size_validation },
    filepasseport: { required, file_size_validation },
    filelogement: { required, file_size_validation },
    passportnumber: {
      required,
      alphaNumAndDotValidator,
      minLength: minLength(5),
    },
  },
  data: () => ({
    isLoading: false,
    visa_dialog: false,
    connected: false,
    socket: null,
    waiting_dialog: true,
    overlay: true,
    comment_list: [],
    status_demande: null,
    raison_list: [],
    approuve_demand: null,
    last_action: null,
    has_contact: null,
    has_familly: 'no',
    snackbar: false,
    editSnackbar: false,
    editSnackbarColor: 'default',
    acreditationcounrty: null,
    snackbar_text: '',
    flugticket: null,
    activePicker: null,
    editActivePicker: null,
    activeExpirePicker: null,
    editActiveExpirePicker: null,
    activeDeliverPicker: null,
    activeStartPicker: null,
    activeEndPicker: null,
    menu: false,
    editMenu: false,
    startmenu: null,
    endmenu: null,
    adresse: null,
    codepostal: null,
    town: null,
    work: null,
    delivermenu: false,

    delivercountry: null,
    familienstand: null,
    flugticket: null,
    activePicker: null,

    birthcountry: null,
    birthplace: null,
    contactname: null,
    contactadresse: null,
    contacttown: null,
    contactphone: null,
    contactemail: null,
    famillyname: null,
    famillyadresse: null,
    famillytown: null,
    famillyphone: null,
    famillyemail: null,
    residencename: null,
    residenceadresse: null,
    residencetown: null,
    residencephone: null,
    residenceemail: null,
    deliverdate: null,
    expiredate: null,
    startdate: null,
    enddate: null,
    birthday_min_date: null,
    passport_min_date: null,
    nationalityNow: null,
    nationalityOrigin: null,
    filepasseport_link: '',
    filelogement_link: '',
    flugticket_link: '',
    passportnumber: null,
    motifvoyage: null,
    phonenumber: null,
    switch1: false,
    defaultcountry: 'CM',
    defaultcountryfamilly: 'CM',
    defaultcountryresidence: 'CM',
    defaultcountrycontact: 'CM',
    tab: null,
    show_modal: false,
    current_item: null,
    firstname: null,
    lastname: null,
    message: null,
    show: false,
    boolean: 1,
    time_history: null,
    color_time: [
      {
        id: 0,
        color: 'pink',
      },
      {
        id: 1,
        color: 'teal lighten-3',
      },
    ],
    show_dialog: null,
    history_dialog: false,
    radios: 'pending',
    show_btn: false,
    search_block: true,
    snackbarColor: 'default',
    snackbar_text: '',
    snackbar: false,
    editSnackbarColor: 'default',
    editSnackbar: false,
    search_form: false,
    code_input: null,
    menu2: false,
    menu1: false,
    date_debut: null,
    date_fin: null,
    demand_status: 'valider',
    length: 0,
    show_comment: false,
    passport_images: [],
    logement_images: [],
    mission_images: [],
    search: null,
    sortBy: 'created_at',
    sortDesc: false,
    filepasseportfilename: null,
    filelogementfilename: null,
    filemissionfilename: null,
    filecomplementname: null,
    menu: false,
    expi: false,
    visa: null,

    activePicker: null,
    activeExpirePicker: null,
    isOkay: false,
    birthday: null,
    dateexpirationpasseport: null,
    pays_origine: null,
    genders: [
      {
        name: 'F',
        value: 'F',
      },
      {
        name: 'M',
        value: 'M',
      },
    ],
    code_mrz: null,
    code_mrz_user: null,
    code_mrz_visas: null,
    current_image: null,
    dialog: false,
    dialogDelete: false,
    headers: [],
    demands: [],
    editedIndex: -1,
    comment: null,
    prenom: null,
    nom: null,
    genre: null,
    birthday: null,
    id_type_visas: null,
    pays_origine: null,
    motif_voyage: null,
    ordremission: null,
    nationality: null,
    dateexpirationpasseport: null,
    telephone: null,
    filepasseport: null,
    filemission: null,
    filelogement: null,
    numpasseport: null,
    all_demands: null,
    defaultItem: {},
  }),

  watch: {
    langLocal(val) {
      moment.locale(val)
    },
    dialog(val) {
      val || this.close()
    },
    radios(val) {
      if (val == 'pending') {
        this.radios = 'pending'
        if (this.all_demands) {
          let pending_demands = this.all_demands.filter(function (element) {
            return element.status_demande.toLowerCase() == 'pending'
          })
          this.demands = pending_demands
        }
      } else if (val == 'reject') {
        this.radios = 'reject'
        if (this.all_demands) {
          let reject_demands = this.all_demands.filter(function (element) {
            return element.status_demande.toLowerCase() == 'rejeter'
          })
          this.demands = reject_demands
        }
      } else if (val == 'complement') {
        this.radios = 'complement'
        if (this.all_demands) {
          let complement_demands = this.all_demands.filter(function (element) {
            return element.status_demande.toLowerCase() == 'complement'
          })
          this.demands = complement_demands
        }
      } else {
        this.radios = 'valide'
        if (this.all_demands) {
          let complement_demands = this.all_demands.filter(function (element) {
            return element.status_demande.toLowerCase() == 'valider'
          })
          this.demands = complement_demands
        }
      }
    },
  },

  mounted() {},

  created() {
    this.$root.$i18n.locale = this.langLocal
    this.$store.dispatch('getnationalities')
    this.$store.dispatch('gettypesvisa')
    this.$store.dispatch('getcountries')
    this.$store.dispatch('getpostpones')
    this.getDemands()
    this.RefreshDemand()
    moment.locale(this.langLocal)
  },

  methods: {
    async generatePdf() {
      this.isLoading = true
      var element = this.$refs.vignette
      var opt = {
        margin: 0.1,
        filename: 'Vignette' + this.approuve_demand.code_demande + '.pdf',
        image: { type: 'jpeg', quality: 1 },
        html2canvas: { scale: 2, width: 700, height: 700 },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
      }
      await html2pdf().from(element).set(opt).save()
      this.isLoading = false
    },
    async view_demand(item) {
      this.waiting_dialog = true
      this.overlay = true
      const formdata = new FormData()
      formdata.append('code_demande', item.code_demande)
      await this.$store
        .dispatch('searchNiveau2Demand', formdata)
        .then((response) => {
          console.log(response.data)
          this.approuve_demand = response.data
          this.code_mrz = this.approuve_demand.code_mrz.split('\n')

          console.log(this.code_mrz)

          this.code_mrz_user = this.code_mrz[0]
          this.code_mrz_visas = this.code_mrz[1]

          this.waiting_dialog = false
          this.overlay = false
          this.visa_dialog = true
        })
        .catch((error) => {
          this.waiting_dialog = false
          this.overlay = false
          this.visa_dialog = false
          console.log(error)
        })
    },
    getLocaleMessages() {
      return this.$root.$i18n.messages[this.langLocal]
    },
    async showDemand(item) {
      const data = new FormData()
      data.append('code_demande', item.code_demande)

      await this.$store
        .dispatch('searchNiveau2Demand', data)
        .then((response) => {
          if (response.success) {
            if (response.data) {
              this.current_item = response.data

              if (this.current_item.comment) {
                this.comment = this.current_item.comment
                this.comment_list = this.comment.split(',')
              }
              if (this.current_item.last_action) {
                this.last_action = this.current_item.last_action
              }
              this.show_modal = true
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })

      this.show_modal = true
    },
    formatDate(str, format) {
      return moment(str).subtract(0, 'hour').format(format)
    },
    getDifferenceInDate(d1) {
      const result = moment(d1).subtract(1, 'hour').fromNow()
      return result
    },
    RefreshDemand() {
      setInterval(this.initialize, 3600000)
    },
    initialize() {
      this.getDemands()
    },
    convert(str, format = 'fr') {
      var date = new Date(str),
        mnth = ('0' + (date.getMonth() + 1)).slice(-2),
        day = ('0' + date.getDate()).slice(-2)
      if (format == 'fr') return [day, mnth, date.getFullYear()].join('-')
      else return [date.getFullYear(), mnth, day].join('-')
    },

    complement() {
      this.show_dialog = true
      this.show_comment = true
      this.comment = ''
      this.status_demande = 'COMPLEMENT'

      this.raison_list = this.postpones.filter(function (element) {
        return element.motif.toLowerCase() == 'complement'
      })
    },
    reject() {
      this.show_comment = true
      this.comment = ''
      this.status_demande = 'REJETER'

      this.raison_list = this.postpones.filter(function (element) {
        return element.motif.toLowerCase() == 'rejet'
      })
    },
    onCopy(e) {
      this.show = true
      setTimeout(() => {
        this.show = false
      }, 400)
    },
    onError(e) {
      this.show = true
    },
    async getValidedDemands(item) {
      const data = new FormData()
      data.append('date_debut', this.date_debut)
      data.append('date_fin', this.date_fin)

      await this.$store
        .dispatch('showNiveau2Valided', data)
        .then((response) => {
          if (response.success) {
            console.log(this.demands)

            try {
              this.demands = response.data
            } catch (error) {
              this.demands = []
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },

    async getHistory(item) {
      console.log(item.id)
      this.history_dialog = true

      const data = new FormData()
      data.append('id_demande', item.id)

      await this.$store
        .dispatch('showTraiter', data)
        .then((response) => {
          this.time_history = response.data.data
          this.time_history.forEach(async (item) => {
            this.boolean = 1 - this.boolean
            item.color = this.boolean
          })
          console.log(this.time_history)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async search_date() {
      this.isLoading = true
      const data = new FormData()
      data.append('date_debut', this.date_debut)
      data.append('date_fin', this.date_fin)

      await this.$store
        .dispatch('getNiveau2AllDemand', data)
        .then((response) => {
          this.isLoading = false
          if (response.data) {
            if (response.data.length != 0) {
              this.editSnackbarColor = 'success'
              this.snackbar_text = i18n.t('message.search_success')
              this.editSnackbar = true

              this.all_demands = response.data
              let result_search = this.all_demands.filter(function (element) {
                return element.status_demande.toLowerCase() == 'pending'
              })
              this.radios = 'pending'

              if (result_search.length == 0) {
                result_search = this.all_demands.filter(function (element) {
                  return element.status_demande.toLowerCase() == 'complement'
                })
                this.radios = 'complement'
              }

              if (result_search.length == 0) {
                result_search = this.all_demands.filter(function (element) {
                  return element.status_demande.toLowerCase() == 'rejeter'
                })
                this.radios = 'reject'
              }

              if (result_search.length == 0) {
                result_search = this.all_demands.filter(function (element) {
                  return element.status_demande.toLowerCase() == 'valider'
                })

                this.radios = 'valider'
              }

              this.demands = result_search
            } else {
              this.editSnackbarColor = 'red'
              this.snackbar_text = i18n.t('message.search_failed')
              this.editSnackbar = true
              this.demands = []
            }
          } else {
            this.editSnackbarColor = 'red'
            this.snackbar_text = 'Aucune demande retrouvée'
            this.editSnackbar = true
            this.demands = []
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.editSnackbarColor = 'red'
          this.snackbar_text = i18n.t('message.error')
          this.editSnackbar = true
        })
    },

    async search_code() {
      this.isLoading = true

      const data = new FormData()

      data.append('code_demande', this.code_input)

      await this.$store
        .dispatch('searchNiveau2Demand', data)
        .then((response) => {
          if (response.success) {
            if (response.data) {
              this.isLoading = false
              this.editSnackbarColor = 'success'
              this.snackbar_text = i18n.t('message.search_success')
              this.editSnackbar = true
              this.dialog = true
              this.search_form = true

              this.show_btn = true

              this.has_pending = true
              this.current_demand = response.data

              this.id_demand = this.current_demand.id
              this.lastname = this.current_demand.nom
              this.firstname = this.current_demand.prenom
              this.genre = this.current_demand.genre
              this.birthday = this.current_demand.birthday
              this.birthcountry = this.current_demand.birth_country
              this.birthplace = this.current_demand.birth_place
              this.nationalityNow = this.current_demand.now_nationality
              this.nationalityOrigin = this.current_demand.origine_nationality
              this.familienstand = this.current_demand.civility
              this.adresse = this.current_demand.adresse_geo
              this.codepostal = this.current_demand.code_postal
              this.phonenumber = this.current_demand.telephone
              this.passportnumber = this.current_demand.numpasseport
              this.delivercountry = this.current_demand.pays_delivrance
              this.deliverdate = this.current_demand.date_delivrance_passeport
              this.expiredate = this.current_demand.dateexpirationpasseport

              if (this.current_demand.last_action) {
                this.last_action = this.current_demand.last_action
              }
              this.acreditationcounrty = this.current_demand.pays_origine
              this.motifvoyage = this.current_demand.id_type_visas
              this.startdate = this.current_demand.date_depart
              this.enddate = this.current_demand.date_retour

              if (this.current_demand.nom_contact_cam) {
                this.contactname = this.current_demand.nom_contact_cam
                this.contactadresse = this.current_demand.adrese_contact_cam
                this.contacttown = this.current_demand.ville_contact_cam
                this.contactphone = this.current_demand.telephone_contact_cam
                this.contactemail = this.current_demand.email_contact_cam
              } else {
                this.has_contact = false
                this.contactname = null
                this.contactadresse = null
                this.contacttown = null
                this.contactphone = null
                this.contactemail = null
              }

              if (this.current_demand.nom_famille_cam) {
                this.famillyname = this.current_demand.nom_famille_cam
                this.famillyemail = this.current_demand.email_famille_cam
                this.famillyphone = this.current_demand.telephone_famille_cam
                this.famillyadresse = this.current_demand.adrese_famille_cam
                this.famillytown = this.current_demand.ville_famille_cam
              } else {
                this.switch1 = false
                this.famillyname = null
                this.famillyemail = null
                this.famillyphone = null
                this.famillyadresse = null
                this.famillytown = null
              }

              this.residencename = this.current_demand.nom_residence_cam
              this.residenceadresse = this.current_demand.adrese_residence_cam
              this.residencetown = this.current_demand.ville_residence_cam
              this.residencephone = this.current_demand.telephone_residence_cam
              this.residenceemail = this.current_demand.email_residence_cam
              this.filepasseport_link = this.current_demand.file_photopasseport

              this.filelogement_link = this.current_demand.file_justificationlogement

              this.flugticket_link = this.current_demand.file_ticket_avaion

              this.town = this.current_demand.ville
              this.work = this.current_demand.profession
              this.code_demande = this.current_demand.code_demande
              this.file_complement = this.current_demand.file_complement
              this.updated_at = this.current_demand.updated_at

              //telephone personnel
              this.phonenumber = this.current_demand.telephone.substring(
                this.current_demand.telephone.indexOf(' ') + 1,
              )

              this.defaultcountry = getIndicatif(
                this.current_demand.telephone.substring(
                  1,
                  this.current_demand.telephone.indexOf(' '),
                ),
              )
              //telephone contact

              if (this.current_demand.telephone_famille_cam != null) {
                this.contactphone = this.current_demand.telephone_contact_cam.substring(
                  this.current_demand.telephone_contact_cam.indexOf(' ') + 1,
                )

                this.defaultcountrycontact = getIndicatif(
                  this.current_demand.telephone_contact_cam.substring(
                    1,
                    this.current_demand.telephone_contact_cam.indexOf(' '),
                  ),
                )
              }

              //telephone famille
              if (this.current_demand.telephone_famille_cam != null) {
                this.famillyphone = this.current_demand.telephone_famille_cam.substring(
                  this.current_demand.telephone_famille_cam.indexOf(' ') + 1,
                )

                this.defaultcountryfamilly = getIndicatif(
                  this.current_demand.telephone_famille_cam.substring(
                    1,
                    this.current_demand.telephone_famille_cam.indexOf(' '),
                  ),
                )
              }

              //telephone resisdence
              if (this.current_demand.telephone_residence_cam != null) {
                this.residencephone = this.current_demand.telephone_residence_cam.substring(
                  this.current_demand.telephone_residence_cam.indexOf(' ') + 1,
                )

                this.defaultcountryresidence = getIndicatif(
                  this.current_demand.telephone_residence_cam.substring(
                    1,
                    this.current_demand.telephone_residence_cam.indexOf(' '),
                  ),
                )
              }

              if (this.contactname && this.motifvoyage == 1) {
                this.has_contact = true
              } else {
                this.has_contact = false
              }
              if (this.famillyname) {
                this.switch1 = true
                this.has_familly = i18n.t('yes')
              } else {
                this.switch1 = false
                this.has_familly = i18n.t('no')
              }

              const passport_file = new File(
                ['blob'],
                this.current_demand.file_photopasseport,
                {
                  lastModified: new Date(Date.now())
                    .toISOString()
                    .substr(0, 10),
                  type: 'text/jpg',
                },
              )

              this.filepasseport = passport_file

              console.log('zefaefazef', this.filepasseport)

              const logement_file = new File(
                ['blob'],
                this.current_demand.file_justificationlogement,
                {
                  lastModified: new Date(Date.now())
                    .toISOString()
                    .substr(0, 10),
                  type: 'text/jpg',
                },
              )

              this.filelogement = logement_file

              const flugticket_file = new File(
                ['blob'],
                this.current_demand.file_ticket_avaion,
                {
                  lastModified: new Date(Date.now())
                    .toISOString()
                    .substr(0, 10),
                  type: 'text/jpg',
                },
              )

              this.flugticket = flugticket_file
            } else {
            }

            this.radios = 'pending'
          } else {
            this.isLoading = false
            this.editSnackbarColor = 'red'
            this.snackbar_text = i18n.t('message.search_failed')
            this.editSnackbar = true
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.editSnackbarColor = 'red'
          this.snackbar_text = i18n.t('message.search_failed')
          this.editSnackbar = true
        })
      if (this.status_demande == 'complement') {
        this.show_btn = false
      }
    },

    async getDemands() {
      this.waiting_dialog = true
      this.overlay = true
      const data = new FormData()
      data.append('date_debut', this.date_debut)
      data.append('date_fin', this.date_fin)

      await this.$store
        .dispatch('getNiveau2AllDemand', data)
        .then((response) => {
          this.waiting_dialog = false
          this.overlay = false
          if (typeof response.data == 'object') {
            let array = []
            array[0] = response.data
            this.demands = array
            this.all_demands = array
          }

          this.all_demands = response.data

          let result_search = this.all_demands.filter(function (element) {
            return element.status_demande.toLowerCase() == 'pending'
          })
          this.radios = 'pending'

          if (result_search.length == 0) {
            result_search = this.all_demands.filter(function (element) {
              return element.status_demande.toLowerCase() == 'complement'
            })
            this.radios = 'complement'
          }

          if (result_search.length == 0) {
            result_search = this.all_demands.filter(function (element) {
              return element.status_demande.toLowerCase() == 'rejeter'
            })
            this.radios = 'reject'
          }

          if (result_search.length == 0) {
            result_search = this.all_demands.filter(function (element) {
              return element.status_demande.toLowerCase() == 'valider'
            })

            this.radios = 'valide'
          }

          this.demands = result_search
        })
        .catch((error) => {
          this.overlay = false
          console.error(error)
        })
    },

    getColor(statut, level) {
      if (statut.toLowerCase() == 'valider' && level.toLowerCase() == 'niveau2')
        return 'green'
      else if (
        statut.toLowerCase() == 'pending' &&
        level.toLowerCase() == 'niveau2'
      )
        return 'orange'
      else if (
        statut.toLowerCase() == 'complement' &&
        level.toLowerCase() == 'niveau2'
      )
        return 'cyan accent-4'
      else return 'red'
    },

    close() {
      this.show_btn = false
      this.search_form = false
      this.show_comment = false
      this.dialog = false
      this.code_input = null
    },

    goto(refName) {
      var element = this.$refs[refName]
      var top = element.offsetTop
      window.scrollTo(0, top)
    },

    async save(status) {
      this.isLoading = true

      if (status) {
        this.status_demande = status
      }
      const data = new FormData()
      data.append('id', this.id_demand)
      data.append('status_demande', this.status_demande)
      data.append('comment', this.comment)

      await this.$store
        .dispatch('updateDemand', data)
        .then((response) => {
          console.log(response)
          if (response.success) {
            this.isLoading = false
            this.editSnackbarColor = 'success'
            this.snackbar_text = i18n.t('message.update_success')
            this.editSnackbar = true
            this.initialize()
          } else {
            this.isLoading = false
            this.editSnackbarColor = 'red'
            this.snackbar_text = i18n.t('message.error')
            this.editSnackbar = true
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.editSnackbarColor = 'red'
          this.snackbar_text = i18n.t('message.error')
          this.editSnackbar = true
        })

      this.show_dialog = false
      this.comment = null
      this.getDemands()

      this.close()
      this.goto('toolbar')
    },

    save_birthday(date) {
      this.$refs.menu.save(date)
    },

    expi_save(newdate) {
      this.$refs.expi.save(newdate)
    },
    deliversave(newdate) {
      this.$refs.delivermenu.save(newdate)
    },
    startsave(date) {
      this.$refs.startmenu.save(date)
    },
    endsave(date) {
      this.$refs.endmenu.save(date)
    },
    menu_expi(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },

    save_expi(date) {
      this.$refs.menu_expi.save(date)
    },
    show_image(img) {
      if (img) {
        let result = img.includes('https') || img.includes('http')
        if (result) {
          this.current_image = [img]
        } else {
          let url = URL.createObjectURL(img)
          visa_dialog_print
          this.current_image = [url]
        }
        this.$viewerApi({
          images: this.current_image,
        })
      }
    },

    // connect() {
    //   this.socket = new SockJS("http://localhost:8080/websocket");
    //   this.stompClient = Stomp.over(this.socket);
    //   this.socket.onopen = function () {
    //     console.log("open");
    //     this.socket.send("test");
    //   };

    //   this.socket.onmessage = function (e) {
    //     console.log("message", e.data);
    //     this.socket.close();
    //   };

    //   this.socket.onclose = function () {
    //     console.log("close");
    //   };

    //   this.stompClient.connect(
    //     {},
    //     (frame) => {
    //       console.log("Connected: " + frame);
    //       this.connected = true;
    //       console.log(frame);
    //       this.stompClient.subscribe("/realtime/passport", (tick) => {
    //         var myPassport = JSON.parse(tick.body);
    //         console.log(myPassport.MRTDs, myPassport.DocumentNo);
    //         this.code_input = myPassport.DocumentNo;

    //         if (this.code_input != null || this.code_input != "") {
    //           this.search_code();
    //         }
    //         // tick.ack();
    //       });
    //     },
    //     (error) => {
    //       console.log(error);
    //       this.connected = false;
    //     }
    //   );
    // },
    // disconnect() {
    //   if (this.stompClient) {
    //     this.stompClient.disconnect();
    //   }
    //   this.connected = false;
    // },
    // tickleConnection() {
    //   this.connected ? this.disconnect() : this.connect();
    // },
  },
  computed: {
    familienStandList() {
      let array = [
        {
          id: 0,
          name: 'Marié',
          text: i18n.t('fields.marry'),
        },

        {
          id: 1,
          name: 'Célibataire avec enfant',
          text: i18n.t('fields.with_child'),
        },
        {
          id: 2,
          name: 'Célibataire sans enfant',
          text: i18n.t('fields.no_child'),
        },
        {
          id: 3,
          name: 'Veuve',
          text: i18n.t('fields.widow'),
        },
      ]

      return array
    },
    can_search() {
      if (this.date_debut == null || this.date_fin == null) {
        return true
      } else {
        return false
      }
    },
    getHeaders() {
      this.headers = [
        {
          text: 'N°',
          align: 'center',
          value: 'code_demande',
        },

        {
          text: i18n.t('headers.fullname'),
          align: 'center',
          value: 'nom',
        },

        {
          text: i18n.t('headers.passport_number'),
          align: 'center',
          value: 'numpasseport',
        },

        {
          text: i18n.t('headers.country'),
          align: 'center',
          value: 'pays_origine',
        },
        {
          text: i18n.t('headers.visatype'),
          align: 'center',
          value: 'type_visas',
        },
        {
          text: i18n.t('headers.statut'),
          align: 'center',
          value: 'status_demande',
        },
        { text: 'Date', align: 'center', value: 'updated_at' },

        {
          text: i18n.t('headers.elapsed_time'),
          align: 'center',
          value: 'last_time',
        },

        { text: 'Actions', align: 'center', value: 'actions' },
      ]
      return this.headers
    },
    commentErrors() {
      const errors = []
      if (!this.$v.comment.$dirty) return errors
      !this.$v.comment.required &&
        errors.push(i18n.t('validations.required_comment'))
      return errors
    },
    phonenumberErrors() {
      const errors = []
      if (!this.$v.phonenumber.$dirty) return errors
      !this.$v.phonenumber.required && errors.push('required_phonenumber')
      return errors
    },
    firstnameErrors() {
      const errors = []
      if (!this.$v.firstname.$dirty) return errors
      !this.$v.firstname.required && errors.push('required_firstname')
      !this.$v.firstname.nameRegex && errors.push('caracter_firstname')
      return errors
    },
    familienstandErrors() {
      const errors = []
      if (!this.$v.familienstand.$dirty) return errors
      !this.$v.familienstand.required && errors.push('required_marital_status')
      !this.$v.firstname.nameRegex && errors.push('caracter_firstname')
      return errors
    },

    lastnameErrors() {
      const errors = []
      if (!this.$v.lastname.$dirty) return errors
      !this.$v.lastname.required && errors.push('required_lastname')
      !this.$v.lastname.nameRegex && errors.push('caracter_lastname')
      return errors
    },
    genderErrors() {
      const errors = []
      if (!this.$v.genre.$dirty) return errors
      !this.$v.genre.required && errors.push('required_gender')
      return errors
    },
    birthdateErrors() {
      const errors = []
      if (!this.$v.birthday.$dirty) return errors
      !this.$v.birthday.required && errors.push('required_birthdate')
      return errors
    },

    deliverdateErrors() {
      const errors = []
      if (!this.$v.deliverdate.$dirty) return errors
      !this.$v.deliverdate.required && errors.push('required_deliverdate')
      return errors
    },
    Errors() {
      const errors = []
      if (!this.$v.birthdate.$dirty) return errors
      !this.$v.birthdate.required && errors.push('required_birthdate')
      return errors
    },
    birthplaceErrors() {
      const errors = []
      if (!this.$v.birthplace.$dirty) return errors
      !this.$v.birthplace.required && errors.push('required_birthplace')
      return errors
    },

    birthcountryErrors() {
      const errors = []
      if (!this.$v.birthcountry.$dirty) return errors
      !this.$v.birthcountry.required && errors.push('required_birthcountry')
      return errors
    },
    visaTypeErrors() {
      const errors = []
      if (!this.$v.visatype.$dirty) return errors
      !this.$v.visatype.required && errors.push('required_visatype')
      return errors
    },
    motifErrors() {
      const errors = []
      if (!this.$v.motifvoyage.$dirty) return errors
      !this.$v.motifvoyage.required && errors.push('required_motif')
      return errors
    },
    nationalityNowErrors() {
      const errors = []
      if (!this.$v.nationalityNow.$dirty) return errors
      !this.$v.nationalityNow.required &&
        errors.push('required_current_nationality')
      return errors
    },
    nationalityOriginErrors() {
      const errors = []
      if (!this.$v.nationalityOrigin.$dirty) return errors
      !this.$v.nationalityOrigin.required &&
        errors.push('required_origine_nationality')
      return errors
    },
    expiredateErrors() {
      const errors = []
      if (!this.$v.expiredate.$dirty) return errors
      !this.$v.expiredate.required && errors.push('required_expiredate')
      return errors
    },
    delivercountryErrors() {
      const errors = []
      if (!this.$v.delivercountry.$dirty) return errors
      !this.$v.delivercountry.required && errors.push('required_delivercountry')
      return errors
    },

    passportnumberErrors() {
      const errors = []
      if (!this.$v.passportnumber.$dirty) return errors
      !this.$v.passportnumber.required && errors.push('required_passportnumber')
      !this.$v.passportnumber.alphaNumAndDotValidator &&
        errors.push('caracter_passportnumber')
      !this.$v.passportnumber.minLength && errors.push('min_passportnumber')
      return errors
    },
    copypassportErrors() {
      const errors = []
      if (!this.$v.filepasseport.$dirty) return errors
      !this.$v.filepasseport.required && errors.push('required_copypassport')
      !this.$v.filepasseport.file_size_validation && errors.push('file_too_big')
      return errors
    },
    justificatiflogementErrors() {
      const errors = []
      if (!this.$v.filelogement.$dirty) return errors
      !this.$v.filelogement.required &&
        errors.push('required_justificatiflogement')
      !this.$v.filelogement.file_size_validation && errors.push('file_too_big')
      return errors
    },
    flugticketErrors() {
      const errors = []
      if (!this.$v.flugticket.$dirty) return errors
      !this.$v.flugticket.required && errors.push('required_flugticket')
      !this.$v.flugticket.file_size_validation && errors.push('file_too_big')
      return errors
    },
    startdateErrors() {
      const errors = []
      if (!this.$v.startdate.$dirty) return errors
      !this.$v.startdate.required && errors.push('required_startdate')
      return errors
    },
    enddateErrors() {
      const errors = []
      if (!this.$v.enddate.$dirty) return errors
      !this.$v.enddate.required && errors.push('required_enddate')
      return errors
    },
    motifvoyageErrors() {
      const errors = []
      if (!this.$v.motifvoyage.$dirty) return errors
      !this.$v.motifvoyage.required && errors.push('required_motifvoyage')
      return errors
    },
    adresseErrors() {
      const errors = []
      if (!this.$v.adresse.$dirty) return errors
      !this.$v.adresse.required && errors.push('required_adresse')
      return errors
    },
    codepostalErrors() {
      const errors = []
      if (!this.$v.codepostal.$dirty) return errors
      !this.$v.codepostal.required && errors.push('required_codepostal')
      return errors
    },
    townErrors() {
      const errors = []
      if (!this.$v.town.$dirty) return errors
      !this.$v.town.required && errors.push('required_town')
      return errors
    },
    workErrors() {
      const errors = []
      if (!this.$v.work.$dirty) return errors
      !this.$v.work.required && errors.push('required_work')
      return errors
    },

    contactnameErrors() {
      const errors = []
      if (!this.$v.contactname.$dirty) return errors
      !this.$v.contactname.required && errors.push('required_work')
      return errors
    },
    contactadresseErrors() {
      const errors = []
      if (!this.$v.contactadresse.$dirty) return errors
      !this.$v.contactadresse.required && errors.push('required_contactadresse')
      return errors
    },
    contacttownErrors() {
      const errors = []
      if (!this.$v.contacttown.$dirty) return errors
      !this.$v.contacttown.required && errors.push('required_contacttown')
      return errors
    },
    contactphoneErrors() {
      const errors = []
      if (!this.$v.work.$dirty) return errors
      !this.$v.work.required && errors.push('required_contactphone')
      return errors
    },
    contactemailErrors() {
      const errors = []
      if (!this.$v.contactemail.$dirty) return errors
      !this.$v.contactemail.email && errors.push('valid_email')

      !this.$v.contactemail.required && errors.push('required_contactemail')
      return errors
    },
    famillynameErrors() {
      const errors = []
      if (!this.$v.famillyname.$dirty) return errors
      !this.$v.famillyname.required && errors.push('required_famillyname')
      return errors
    },
    famillyadresseErrors() {
      const errors = []
      if (!this.$v.famillyadresse.$dirty) return errors
      !this.$v.famillyadresse.required && errors.push('required_famillyadresse')
      return errors
    },
    famillytownErrors() {
      const errors = []
      if (!this.$v.famillytown.$dirty) return errors
      !this.$v.famillytown.required && errors.push('required_famillytown')
      return errors
    },
    famillyphoneErrors() {
      const errors = []
      if (!this.$v.famillyphone.$dirty) return errors
      !this.$v.famillyphone.required && errors.push('required_famillyphone')
      return errors
    },
    famillyemailErrors() {
      const errors = []
      if (!this.$v.famillyemail.$dirty) return errors
      !this.$v.famillyemail.email && errors.push('valid_email')

      !this.$v.famillyemail.required && errors.push('required_famillyphone')
      return errors
    },
    residencenameErrors() {
      const errors = []
      if (!this.$v.residencename.$dirty) return errors
      !this.$v.residencename.required && errors.push('required_residencename')
      return errors
    },
    residenceadresseErrors() {
      const errors = []
      if (!this.$v.residenceadresse.$dirty) return errors
      !this.$v.residenceadresse.required &&
        errors.push('required_residenceadresse')
      return errors
    },
    residencetownErrors() {
      const errors = []
      if (!this.$v.residencetown.$dirty) return errors
      !this.$v.residencetown.required && errors.push('required_residencetown')
      return errors
    },
    residencephoneErrors() {
      const errors = []
      if (!this.$v.residencephone.$dirty) return errors
      !this.$v.residencephone.required && errors.push('required_residencephone')
      return errors
    },
    residenceemailErrors() {
      const errors = []
      if (!this.$v.residenceemail.$dirty) return errors
      !this.$v.residenceemail.email && errors.push('valid_email')
      !this.$v.residenceemail.required && errors.push('required_residenceemail')
      return errors
    },
    acreditationcounrtyErrors() {
      const errors = []
      if (!this.$v.acreditationcounrty.$dirty) return errors
      !this.$v.acreditationcounrty.required &&
        errors.push('required_acreditationcounrty')
      return errors
    },

    ...mapGetters([
      'birthCountries',
      'isdark',
      'langLocal',
      'currentrole',
      'errors',
      'registered',
      'countries',
      'nationalities',
      'typesvisa',
      'postpones',
    ]),
  },
}
</script>
<style scoped>
.custimize-dialog {
  z-index: 8000;
}

li {
  font-size: 16px;
  margin-left: 10px;
  list-style-type: circle;
}
#visa_dialog {
  margin-top: 0px;
  margin-left: 0px;
  background-image: url(../../../public/cameroun.jpg) !important;
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 700px;
  max-height: 470px;
}
#visa_dialog_print {
  /* background-image: url(../../../public/cameroun.jpg) !important;*/
  margin-top: 0px;
  margin-left: 0px;
  font-weight: 400 !important;
  color: black;
  background-size: cover;
  background-repeat: no-repeat;
}

#visa_dialog_print .title_span {
  font-weight: 400;
  font-size: 7px;
}

#visa_dialog_print .contains_span {
  font-size: 12px;
}

.title_span {
  font-size: 10px !important;
}
.padding-0 {
  padding-right: 0;
  padding-left: 0;
}

.visa_dialog_print_margin {
  margin-top: 15%;
}

@media print {
  body * {
    visibility: hidden;
  }

  #visa_dialog,
  #visa_dialog * {
    visibility: visible;
    border: none;
  }
}
</style>
