var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MainLayout-component',[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-12 col-lg-12"},[_c('div',{staticClass:"text-center mt-2"},[_c('v-card',{staticClass:"d-flex align-center justify-center mt-4 pa-4 mx-auto mb-4",attrs:{"min-height":"96","outlined":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("title.recip_list")))])])],1)],1),_c('v-card',{staticClass:"d-flex align-center justify-center mt-4 pa-4 mx-auto mb-4",attrs:{"min-height":"96","outlined":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-snackbar',{staticStyle:{"z-index":"1800"},attrs:{"color":_vm.snackbarColor,"timeout":3000,"top":true},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")]),_c('v-col',{staticClass:"m-2",attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('fields.date_start'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date_debut),callback:function ($$v) {_vm.date_debut=$$v},expression:"date_debut"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.date_debut),callback:function ($$v) {_vm.date_debut=$$v},expression:"date_debut"}})],1)],1),_c('v-col',{staticClass:"m-2",attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('fields.enddate'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date_fin),callback:function ($$v) {_vm.date_fin=$$v},expression:"date_fin"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.date_fin),callback:function ($$v) {_vm.date_fin=$$v},expression:"date_fin"}})],1)],1),_c('v-col',{staticClass:"m-2",attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-btn',{staticClass:"m-2",attrs:{"disabled":_vm.$v.$invalid,"block":"","color":"primary"},on:{"click":_vm.search_date}},[_vm._v(" Filtrer ")])],1),_c('v-col',{staticClass:"m-2",attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-btn',{staticClass:"text-white m-2",attrs:{"disabled":_vm.cancel_search,"block":"","color":"red"},on:{"click":function($event){_vm.cancel_search = true;
                  _vm.date_debut = null;
                  _vm.date_fin = null;}}},[_vm._v(" "+_vm._s(_vm.$t("btn.search_cancel"))+" ")])],1)],1)],1)],1),(!_vm.cancel_search)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"12"}},[_c('div',{staticStyle:{"background":"#e0e0e0"}},[_c('v-divider'),_c('h4',{staticStyle:{"color":"#4a4343","margin-left":"10px"}},[_vm._v(" "+_vm._s(_vm.$t("title.search_result"))+" ")]),_c('v-divider')],1)]):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"mr-5",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('fields.search_text'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._l((_vm.unite_list),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"12"}},[_c('unite-details',{attrs:{"unite":item}})],1)})],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }