/* eslint-disable vue/no-side-effects-in-computed-properties */
<template>
  <v-container>
    <v-expansion-panels hover inset light popout>
      <v-expansion-panel active-class>
        <v-expansion-panel-header
          style="background-color: #007bff"
          color="text-white"
        >
          <template v-slot:actions>
            <v-icon color="white"> $expand </v-icon>
          </template>
          <span style="color: white; font-size: 20px">{{ unite.name }}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- header -->
          <v-row
            style="
              background: #e0e0e0;
              padding: 20px;
              border-radius: 5px;
              margin-top: 8px;
              margin-bottom: 8px;
            "
          >
            <v-col class="content-header" cols="12" md="6"> Visa </v-col>
            <v-col class="content-header" cols="12" md="3">
              {{ $t("title.visa_rejected") }}</v-col
            >
            <v-col class="content-header" cols="12" md="3">
              {{ $t("title.visa_valided") }}
            </v-col>
          </v-row>

          <v-row v-for="(data, id) in unite.donnee" :key="id">
            <!-- value     -->
            <v-row v-for="(visa, index) in data" :key="index">
              <v-col cols="12" md="6"> {{   $t("fields."+$t("reverseLocale."+index)+"" )}} </v-col>
              <v-col class="text-left" cols="12" md="3">
                <v-chip
                  close-icon="mdi-check-all"
                  class="m-2 p-2"
                  color="red lighten-1"
                >
                  <span style="color: white">{{ visa.rejeter }} </span>
                </v-chip>
              </v-col>
              <v-col class="text-left" cols="12" md="3">
                <v-chip
                  close-icon="mdi-check-all"
                  class="m-2 p-2"
                  color="green"
                >
                  <span style="color: white">{{ visa.valider }} </span>
                </v-chip>
              </v-col>
            </v-row>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import i18n from "../i18n";

export default {
  name: "UniteDetails",
  props: ["unite"],

  components: {},
  watch: {},
  data: () => ({}),
  computed: {},

  created() {},

  methods: {
    getRecipes() {},
    search_date() {},
  },
};
</script>

<style scoped>
.content-header {
  text-align: left;
  color: #4a4343;
  font-weight: 700;
}
</style>