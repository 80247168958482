<template>
  <div>
    <TheHeader />
    <TheSideBar />
    <div class="wrapper" style="margin-top: 40px">
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-1">
              <!-- /.col -->
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="#">Home</a></li>
                  <li class="breadcrumb-item"><a href="#">Home</a></li>
                </ol>
              </div>
              <!-- /.col -->
            </div>
            <!-- /.row -->
          </div>
          <!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <section class="content mt-4">
          <div class="container-fluid">
            <!-- Main row -->
            <div class="row">
              <section class="col-lg-12 connectedSortable">
                <slot />
              </section>

              <!-- right col -->
            </div>
            <!-- /.row (main row) -->
          </div>
          <!-- /.container-fluid -->
        </section>
        <!-- /.content -->
      </div>
      <!-- /.content-wrapper -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TheFooter from "@/components/TheFooter";
import TheHeader from "@/components/TheHeader";

export default {
  name: "MainLayout",
  props: {
    message: {
      type: String,
    },
  },
  components: {
    TheFooter: TheFooter,
    TheHeader: TheHeader,
  },
  data() {
    return {};
  },
  created() {
 this.$root.$i18n.locale = this.langLocal;

    // this.authenticated;
  },
  components: {},
  computed: {
    ...mapGetters(["authenticated", "langLocal", "isdark"]),
  },
  watch: {
    // authenticated(value) {
    //   if (value === false) {
    //     this.$router.push("/login");
    //   }
    // },
  },
  methods: {
    // logout() {
    //   this.$store.dispatch("logout");
    // },
  },
};
</script>
<style lang="css"></style>
