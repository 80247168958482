<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="/" class="brand-link">
      <img
        src="../../public/logo.png"
        alt="Evisa Logo"
        class="img-circle m-2"
        style="width: 40px; height: 40px"
      />
      <span class="brand-text font-weight-light">Evisa Cameroun</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->

      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <i
            style="color: white; margin: 10px"
            class="fa fa-user"
            aria-hidden="true"
          ></i>
        </div>
        <div class="info_sidebar">
          <a href="/" class="d-block"
            >{{ user.firstname }} {{ user.lastname }}</a
          >
        </div>
      </div>

      <!-- Sidebar Menu -->

      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <li class="nav-item">
            <a @click="logout" class="logout-side nav-link">
              <i class="fas m-1 fa-sign-out"></i>
              <p>{{ $t("btn.logout") }}</p>
            </a>
          </li>

          <li class="nav-item">
            <a href="/"   v-bind:class="[
                    currentRouteName == 'home' ? 'nav-link active' : 'nav-link',
                  ]">
              <i class="fas m-1 fa-home"></i>
              <p>Dashboard</p>
            </a>
          </li>

          <li
            class="nav-item"
            v-bind:class="[
              url_tab_demandes.includes(currentRouteName)
                ? 'menu-is-opening menu-open'
                : '',
            ]"
          >
            <a
             
              class="nav-link"
              v-bind:class="[
                url_tab_demandes.includes(currentRouteName) ? 'active' : '',
              ]"
            >
              <i class="nav-icon fas fa-table"></i>
              <p>
                {{ $t("link.demands") }} :
                <i class="fas m-1 fa-angle-left right"></i>
              </p>
            </a>
            <ul v-if="currentrole == 'Niveau1'" class="nav nav-treeview">
              <li class="nav-item">
                <a
                  href="/demands-niveau1"
                  class="nav-link"
                  v-bind:class="[
                    currentRouteName == 'demandsniveau1' ? 'active' : '',
                  ]"
                >
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{ $t("link.list_demands_level1") }}</p>
                </a>
              </li>
            </ul>
            <ul v-if="currentrole == 'Admin'" class="nav nav-treeview">
              <li class="nav-item">
                <a
                  href="/admin-demands-niveau1"
                  class="nav-link"
                  v-bind:class="[
                    currentRouteName == 'admindemands1' ? 'active' : '',
                  ]"
                >
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{ $t("link.list_demands_level1") }}</p>
                </a>
              </li>
            </ul>
            <ul
              v-if="currentrole == 'Niveau2' || currentrole == 'Admin'"
              class="nav nav-treeview"
            >
              <li class="nav-item">
                <a
                  href="/demands-niveau2"
                  class="nav-link"
                  v-bind:class="[
                    currentRouteName == 'demandsniveau2' ? 'active' : '',
                  ]"
                >
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{ $t("link.list_demands_level2") }}</p>
                </a>
              </li>
            </ul>
          </li>

          <li v-if="currentrole == 'Admin'" class="nav-item">
            <a
              href="/list-agent"
              class="nav-link"
              v-bind:class="[currentRouteName == 'listagent' ? 'active' : '']"
            >
              <i class="fa-solid fa-users-gear"></i>
              <p>
                {{ $t("link.list_agent") }}
              </p>
            </a>
          </li>

          <li
            v-if="currentrole == 'Admin'"
            class="nav-item"
            v-bind:class="[
              url_tab_setting.includes(currentRouteName)
                ? 'menu-is-opening menu-open'
                : '',
            ]"
          >
            <a
              
              class="nav-link"
              v-bind:class="[
                url_tab_setting.includes(currentRouteName) ? 'active' : '',
              ]"
            >
              <i class="fas m-1 fa-solid fa-cog"></i>
              <p>
                {{ $t("title.posts") }}
                <i class="fas m-1 fa-angle-left right"></i>
              </p>
            </a>

            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a
                  href="/list-trait-unit"
                  class="nav-link"
                  v-bind:class="[
                    currentRouteName == 'list-trait-unit' ? 'active' : '',
                  ]"
                >
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{ $t("link.list_unit") }}</p>
                </a>
              </li>
            </ul>

            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a
                  href="/list-trait-post"
                  class="nav-link"
                  v-bind:class="[
                    currentRouteName == 'list-trait-post' ? 'active' : '',
                  ]"
                >
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{ $t("link.list_post") }}</p>
                </a>
              </li>
            </ul>

            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a
                  href="/postpones"
                  class="nav-link"
                  v-bind:class="[
                    currentRouteName == 'postpones' ? 'active' : '',
                  ]"
                >
                  <i class="far fa-circle nav-icon"></i>
            
                  <p>{{$t("btn.gestion_raison")}}</p>
                </a>
              </li>
            </ul>
          </li>

          <li
            v-if="currentrole == 'Admin'"
            class="nav-item"
            v-bind:class="[
              url_tab_liste.includes(currentRouteName)
                ? 'menu-is-opening menu-open'
                : '',
            ]"
          >
            <a
              
              class="nav-link"
              v-bind:class="[
                url_tab_liste.includes(currentRouteName) ? 'active' : '',
              ]"
            >
              <i class="fas m-1 fa-solid fa-list"></i>
              <p>
            
                {{$t("btn.list_of")}} :
                <i class="fas m-1 fa-angle-left right"></i>
              </p>
            </a>

            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a
                  href="/list-recettes"
                  class="nav-link"
                  v-bind:class="[
                    currentRouteName == 'list-recettes' ? 'active' : '',
                  ]"
                >
                  <i class="far fa-circle nav-icon"></i>
                  
                  <p>{{$t("btn.Recettes")}}</p>
                </a>
              </li>
            </ul>

            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a
                  href="/listrdv"
                  class="nav-link"
                  v-bind:class="[currentRouteName == 'listrdv' ? 'active' : '']"
                >
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{$t("btn.Rendez_vous")}}</p>
                </a>
              </li>
            </ul>

            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a
                  href="/listvisas"
                  class="nav-link"
                  v-bind:class="[
                    currentRouteName == 'listvisas' ? 'active' : '',
                  ]"
                >
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{$t("btn.visas")}}</p>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "TheSidebar",
  data() {
    return {
      username: "",
      url_tab_agent: ["listagent"],
      url_tab_setting: ["list-trait-post", "list-trait-unit", "postpones"],
      url_tab_demandes: ["demandsniveau1", "demandsniveau2", "admindemands1"],
      url_tab_liste: ["list-recettes", "listrdv", "listvisas"],
    };
  },
  created() {
    console.log(this.currentrole);
    if (this.user) {
      this.username = this.user.nom + " " + this.user.prenom;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
  watch: {
    authenticated(value, oldvalue) {
      if (this.user) {
        this.username = this.user.nom + " " + this.user.prenom;
      }
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    ...mapGetters(["authenticated", "user", "currentrole"]),
  },
};
</script>

<style>
.user-panel .info_sidebar {
  display: inline-block;
  padding: 5px 5px 5px 10px;
}
.user-panel,
.user-panel .info_sidebar {
  overflow: hidden;
  white-space: nowrap;
}
.logout-side {
  color: #c2c7d0 !important;
  display: none;
}
.v-application ol,
.v-application ul {
  padding-left: 0px !important;
}

@media (max-width: 700px) {
  .logout-side {
    color: #c2c7d0;
    display: block;
  }
}

li.nav-item  a.nav-link {
    color: #c2c7d0 !important;
}

[class*=sidebar-dark-]  .nav-treeview>.nav-item>.nav-link.active, [class*=sidebar-dark-] .nav-treeview>.nav-item>.nav-link.active:focus, [class*=sidebar-dark-] .nav-treeview>.nav-item>.nav-link.active:hover {
    background-color: #6c757d !important;
}
</style>
