var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"main-sidebar sidebar-dark-primary elevation-4"},[_vm._m(0),_c('div',{staticClass:"sidebar"},[_c('div',{staticClass:"user-panel mt-3 pb-3 mb-3 d-flex"},[_vm._m(1),_c('div',{staticClass:"info_sidebar"},[_c('a',{staticClass:"d-block",attrs:{"href":"/"}},[_vm._v(_vm._s(_vm.user.firstname)+" "+_vm._s(_vm.user.lastname))])])]),_c('nav',{staticClass:"mt-2"},[_c('ul',{staticClass:"nav nav-pills nav-sidebar flex-column",attrs:{"data-widget":"treeview","role":"menu","data-accordion":"false"}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"logout-side nav-link",on:{"click":_vm.logout}},[_c('i',{staticClass:"fas m-1 fa-sign-out"}),_c('p',[_vm._v(_vm._s(_vm.$t("btn.logout")))])])]),_c('li',{staticClass:"nav-item"},[_c('a',{class:[
                  _vm.currentRouteName == 'home' ? 'nav-link active' : 'nav-link' ],attrs:{"href":"/"}},[_c('i',{staticClass:"fas m-1 fa-home"}),_c('p',[_vm._v("Dashboard")])])]),_c('li',{staticClass:"nav-item",class:[
            _vm.url_tab_demandes.includes(_vm.currentRouteName)
              ? 'menu-is-opening menu-open'
              : '' ]},[_c('a',{staticClass:"nav-link",class:[
              _vm.url_tab_demandes.includes(_vm.currentRouteName) ? 'active' : '' ]},[_c('i',{staticClass:"nav-icon fas fa-table"}),_c('p',[_vm._v(" "+_vm._s(_vm.$t("link.demands"))+" : "),_c('i',{staticClass:"fas m-1 fa-angle-left right"})])]),(_vm.currentrole == 'Niveau1')?_c('ul',{staticClass:"nav nav-treeview"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:[
                  _vm.currentRouteName == 'demandsniveau1' ? 'active' : '' ],attrs:{"href":"/demands-niveau1"}},[_c('i',{staticClass:"far fa-circle nav-icon"}),_c('p',[_vm._v(_vm._s(_vm.$t("link.list_demands_level1")))])])])]):_vm._e(),(_vm.currentrole == 'Admin')?_c('ul',{staticClass:"nav nav-treeview"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:[
                  _vm.currentRouteName == 'admindemands1' ? 'active' : '' ],attrs:{"href":"/admin-demands-niveau1"}},[_c('i',{staticClass:"far fa-circle nav-icon"}),_c('p',[_vm._v(_vm._s(_vm.$t("link.list_demands_level1")))])])])]):_vm._e(),(_vm.currentrole == 'Niveau2' || _vm.currentrole == 'Admin')?_c('ul',{staticClass:"nav nav-treeview"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:[
                  _vm.currentRouteName == 'demandsniveau2' ? 'active' : '' ],attrs:{"href":"/demands-niveau2"}},[_c('i',{staticClass:"far fa-circle nav-icon"}),_c('p',[_vm._v(_vm._s(_vm.$t("link.list_demands_level2")))])])])]):_vm._e()]),(_vm.currentrole == 'Admin')?_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:[_vm.currentRouteName == 'listagent' ? 'active' : ''],attrs:{"href":"/list-agent"}},[_c('i',{staticClass:"fa-solid fa-users-gear"}),_c('p',[_vm._v(" "+_vm._s(_vm.$t("link.list_agent"))+" ")])])]):_vm._e(),(_vm.currentrole == 'Admin')?_c('li',{staticClass:"nav-item",class:[
            _vm.url_tab_setting.includes(_vm.currentRouteName)
              ? 'menu-is-opening menu-open'
              : '' ]},[_c('a',{staticClass:"nav-link",class:[
              _vm.url_tab_setting.includes(_vm.currentRouteName) ? 'active' : '' ]},[_c('i',{staticClass:"fas m-1 fa-solid fa-cog"}),_c('p',[_vm._v(" "+_vm._s(_vm.$t("title.posts"))+" "),_c('i',{staticClass:"fas m-1 fa-angle-left right"})])]),_c('ul',{staticClass:"nav nav-treeview"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:[
                  _vm.currentRouteName == 'list-trait-unit' ? 'active' : '' ],attrs:{"href":"/list-trait-unit"}},[_c('i',{staticClass:"far fa-circle nav-icon"}),_c('p',[_vm._v(_vm._s(_vm.$t("link.list_unit")))])])])]),_c('ul',{staticClass:"nav nav-treeview"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:[
                  _vm.currentRouteName == 'list-trait-post' ? 'active' : '' ],attrs:{"href":"/list-trait-post"}},[_c('i',{staticClass:"far fa-circle nav-icon"}),_c('p',[_vm._v(_vm._s(_vm.$t("link.list_post")))])])])]),_c('ul',{staticClass:"nav nav-treeview"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:[
                  _vm.currentRouteName == 'postpones' ? 'active' : '' ],attrs:{"href":"/postpones"}},[_c('i',{staticClass:"far fa-circle nav-icon"}),_c('p',[_vm._v(_vm._s(_vm.$t("btn.gestion_raison")))])])])])]):_vm._e(),(_vm.currentrole == 'Admin')?_c('li',{staticClass:"nav-item",class:[
            _vm.url_tab_liste.includes(_vm.currentRouteName)
              ? 'menu-is-opening menu-open'
              : '' ]},[_c('a',{staticClass:"nav-link",class:[
              _vm.url_tab_liste.includes(_vm.currentRouteName) ? 'active' : '' ]},[_c('i',{staticClass:"fas m-1 fa-solid fa-list"}),_c('p',[_vm._v(" "+_vm._s(_vm.$t("btn.list_of"))+" : "),_c('i',{staticClass:"fas m-1 fa-angle-left right"})])]),_c('ul',{staticClass:"nav nav-treeview"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:[
                  _vm.currentRouteName == 'list-recettes' ? 'active' : '' ],attrs:{"href":"/list-recettes"}},[_c('i',{staticClass:"far fa-circle nav-icon"}),_c('p',[_vm._v(_vm._s(_vm.$t("btn.Recettes")))])])])]),_c('ul',{staticClass:"nav nav-treeview"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:[_vm.currentRouteName == 'listrdv' ? 'active' : ''],attrs:{"href":"/listrdv"}},[_c('i',{staticClass:"far fa-circle nav-icon"}),_c('p',[_vm._v(_vm._s(_vm.$t("btn.Rendez_vous")))])])])]),_c('ul',{staticClass:"nav nav-treeview"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:[
                  _vm.currentRouteName == 'listvisas' ? 'active' : '' ],attrs:{"href":"/listvisas"}},[_c('i',{staticClass:"far fa-circle nav-icon"}),_c('p',[_vm._v(_vm._s(_vm.$t("btn.visas")))])])])])]):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"brand-link",attrs:{"href":"/"}},[_c('img',{staticClass:"img-circle m-2",staticStyle:{"width":"40px","height":"40px"},attrs:{"src":require("../../public/logo.png"),"alt":"Evisa Logo"}}),_c('span',{staticClass:"brand-text font-weight-light"},[_vm._v("Evisa Cameroun")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"image"},[_c('i',{staticClass:"fa fa-user",staticStyle:{"color":"white","margin":"10px"},attrs:{"aria-hidden":"true"}})])}]

export { render, staticRenderFns }