/* eslint-disable vue/no-side-effects-in-computed-properties */
<template>
  <MainLayout-component>
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-12">
        <div class="text-center mt-2">
          <v-card
            class="d-flex align-center justify-center mt-4 pa-4 mx-auto mb-4"
            min-height="96"
            outlined
          >
            <div class="text-center">
              {{ $t('title.posts_list') }}
            </div>
          </v-card>
          <v-snackbar
            v-model="editSnackbar"
            :color="editSnackbarColor"
            :timeout="3000"
            style="z-index: 1800;"
            :top="true"
          >
            {{ snackbar_text }}
          </v-snackbar>
        </div>
        <v-data-table
          :headers="getHeaders"
          :items="posts"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items-per-page="15"
          class="elevation-2"
          :search="search"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title></v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>

              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('fields.search_text')"
                single-line
                hide-details
                class="mr-5"
              ></v-text-field>

              <v-dialog
                :eager="true"
                v-model="dialog"
                style="z-index: 1500;"
                max-width="600px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $t('btn.add_post') }}
                  </v-btn>
                </template>
                <v-card>
                  <div class="text-center">
                    <v-snackbar
                      v-model="snackbar"
                      :color="snackbarColor"
                      :timeout="3000"
                      :top="true"
                    >
                      {{ snackbar_text }}
                    </v-snackbar>
                  </div>
                  <v-card-text>
                    <v-container fluid>
                      <div class="d-flex">
                        <v-card-title
                          class="w-100 card-title mb-2 justify-center"
                        >
                          {{ $t('title.add_post') }}
                        </v-card-title>
                      </div>

                      <v-form :eager="true" ref="postForm">
                        <v-row>
                          <v-alert v-if="message" type="error">
                            {{ message }}
                          </v-alert>

                          <v-col cols="12" sm="12">
                            <v-text-field
                              required
                              @keyup="uppercase"
                              :label="$t('auth.homecountry')"
                              :error-messages="paysOrigineErrors"
                              filled
                              @blur="$v.editedItem.pays_origine.$touch()"
                              v-model="editedItem.pays_origine"
                              dense
                            ></v-text-field>

                            <!-- <v-select
                              :disabled="country_desabled"
                              required
                              v-model="editedItem.pays_origine"
                              @blur="$v.editedItem.pays_origine.$touch()"
                              :items="countries"
                              :label="$t('auth.homecountry')"
                              :error-messages="paysOrigineErrors"
                              item-text="pays_origine"
                              item-value="pays_origine"
                            ></v-select> -->
                          </v-col>

                          <v-col cols="12" sm="12">
                            <v-autocomplete
                              class="d-flex ml-auto"
                              cols="12"
                              v-model="editedItem.unite_traitement_id"
                              :items="unites_items"
                              item-text="name"
                              item-value="id"
                              filled
                              @blur="$v.editedItem.unite_traitement_id.$touch()"
                              required
                              label="Unite"
                              :error-messages="uniteErrors"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      {{ $t('btn.cancel') }}
                    </v-btn>
                    <v-btn
                      :loading="isLoading"
                      color="primary"
                      large
                      :disabled="can_submit"
                      @click.prevent="save"
                      type="submit"
                      text
                    >
                      {{ $t('btn.register') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                style="z-index: 1500;"
                v-model="dialogBlock"
                max-width="500px"
              >
                <v-card>
                  <v-card-title
                    class="text-center"
                    style="text-align: center;"
                    v-if="posts[editedIndex]"
                  >
                    {{ posts[editedIndex].pays_origine }}
                  </v-card-title>
                  <p class="text-center text-h8 p-2">
                    {{ $t('validations.confirmation_action') }}
                  </p>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeBlock">
                      {{ $t('btn.cancel') }}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      :loading="isLoading"
                      @click="blockOrDeblockPost"
                    >
                      {{ $t('btn.valide') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon
              color="red"
              v-if="item.deleted_at != null"
              small
              @click="blockPost(item)"
            >
              mdi-lock
            </v-icon>
            <v-icon
              color="green"
              v-if="item.deleted_at == null"
              small
              @click="blockPost(item)"
            >
              mdi-lock-open
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-col>
              <i style="font-size: 30px;" class="fa-solid fa-circle-info"></i>
              <p>Aucune donné</p>
            </v-col>
            <v-col>
              <v-btn text color="primary" @click="getPosts">
                {{ $t('btn.reset') }}
              </v-btn>
            </v-col>
          </template>
        </v-data-table>
      </div>
    </div>
  </MainLayout-component>
</template>
<script>
import MainLayout from '../MainLayout.vue'
import { mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import i18n from '../../i18n'

export default {
  name: 'PostList',
  mixins: [validationMixin],
  validations: {
    editedItem: {
      pays_origine: { required },
      unite_traitement_id: { required },
    },
  },
  components: {
    'MainLayout-component': MainLayout,
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogBlock(val) {
      val || this.closeBlock()
    },
  },
  data: () => ({
    country_desabled: null,
    unites_items: null,
    message: null,
    isLoading: false,
    snackbar: false,
    editSnackbar: false,
    snackbarColor: 'default',
    editSnackbarColor: 'default',
    email_desabled: false,
    snackbar_text: '',
    search: null,
    sortBy: 'created_at',
    sortDesc: true,

    dialog: false,
    dialogBlock: false,
    headers: [],
    posts: [],
    editedIndex: -1,
    editedItem: {
      pays_origine: null,
      unite_traitement_id: null,
    },
    defaultItem: {
      pays_origine: null,
      unite_traitement_id: null,
    },
  }),
  computed: {
    can_submit() {
      if (
        this.$v.editedItem.pays_origine.$invalid ||
        this.$v.editedItem.unite_traitement_id.$invalid
      ) {
        return true
      } else {
        return false
      }
    },
    getHeaders() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.headers = [
        {
          text: i18n.t('headers.homecountry'),
          align: '',
          value: 'pays_origine',
        },
        {
          text: i18n.t('headers.unit'),
          align: '',
          value: 'unite_traitement',
        },

        { text: 'Actions', value: 'actions' },
      ]
      return this.headers
    },

    uniteErrors() {
      const errors = []
      if (!this.$v.editedItem.unite_traitement_id.$dirty) return errors
      !this.$v.editedItem.unite_traitement_id.required &&
        errors.push(i18n.t('validations.unite_required'))
      return errors
    },
    paysOrigineErrors() {
      const errors = []
      if (!this.$v.editedItem.pays_origine.$dirty) return errors
      !this.$v.editedItem.pays_origine.required &&
        errors.push(i18n.t('validations.pays_required'))
      return errors
    },

    ...mapGetters(['errors', 'registered', 'countries', 'langLocal']),
  },

  created() {
    this.$root.$i18n.locale = this.langLocal
    this.getPosts()
    this.getUnites()
    this.$store.dispatch('getcountries')
  },

  methods: {
    uppercase() {
      this.editedItem.pays_origine = this.editedItem.pays_origine.toUpperCase()
    },

    async blockOrDeblockPost() {
      this.isLoading = false
      if (
        this.editedIndex > -1 &&
        this.posts[this.editedIndex]['deleted_at'] == null
      ) {
        const data = new FormData()
        data.append('id', this.posts[this.editedIndex].id)
        data.append('bloque', true)
        await this.$store
          .dispatch('updatePostTrait', data)
          .then((response) => {
            this.isLoading = false
            this.snackbarColor = 'success'
            this.snackbar_text = i18n.t('message.update_success')
            this.snackbar = true
            console.log(this.snackbar)
          })
          .catch((error) => {
            this.isLoading = false
            this.snackbarColor = 'red'
            this.snackbar_text = i18n.t('message.error')
            this.snackbar = true
          })
      }
      if (
        this.editedIndex > -1 &&
        this.posts[this.editedIndex]['deleted_at'] != null
      ) {
        const data = new FormData()
        data.append('id', this.posts[this.editedIndex].id)
        data.append('debloque', true)
        await this.$store
          .dispatch('updatePostTrait', data)
          .then((response) => {
            this.isLoading = false
            this.snackbarColor = 'success'
            this.snackbar_text = i18n.t('message.update_success')
            this.snackbar = true
          })
          .catch((error) => {
            this.isLoading = false
            this.snackbarColor = 'red'
            this.snackbar_text = i18n.t('message.error')
            this.snackbar = true
          })
      }
      this.dialogBlock = false
      this.getPosts()
    },
    blockPost(item) {
      console.log(item)
      this.editedIndex = this.posts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogBlock = true

      console.log()
    },
    async getUnites() {
      await this.$store
        .dispatch('getUnites')
        .then((response) => {
          console.log(response)
          this.unites_items = response
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async getPosts() {
      await this.$store
        .dispatch('getPosts')
        .then((response) => {
          this.posts = response

          console.log(this.posts)
        })
        .catch((error) => {
          console.error(error)
        })
    },
    editItem(item) {
      this.country_desabled = true
      this.editedIndex = this.posts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    blockAgentConfirm() {
      this.posts.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.country_desabled = false
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeBlock() {
      this.dialogBlock = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms)
      })
    },
    async save() {
      if (this.editedIndex > -1) {
        Object.assign(this.posts[this.editedIndex], this.editedItem)
        this.isLoading = true
        this.$v.$touch()
        const data = new FormData()
        console.log(this.posts[this.editedIndex].id)
        data.append('unite_traitements_id', this.editedItem.unite_traitement_id)
        data.append('pays_origine', this.editedItem.pays_origine)
        data.append('id', this.posts[this.editedIndex].id)

        if (!this.$v.$invalid) {
          await this.$store
            .dispatch('updatePostTrait', data)
            .then((response) => {
              this.isLoading = false
              this.editSnackbarColor = 'success'
              this.snackbar_text = i18n.t('message.update_success')
              this.editSnackbar = true
              setTimeout(() => {
                this.close()
              }, 3000)
            })
            .catch((error) => {
              this.isLoading = false
              this.editSnackbarColor = 'red'
              this.snackbar_text = i18n.t('message.error')
              this.editSnackbar = true
            })
        }
        this.getPosts()
      } else {
        this.posts.push(this.editedItem)
        this.isLoading = true
        this.$v.$touch()
        const data = new FormData()
        data.append('unite_traitement_id', this.editedItem.unite_traitement_id)
        data.append('pays_origine', this.editedItem.pays_origine)

        if (!this.$v.$invalid) {
          await this.$store
            .dispatch('addPostTrait', data)
            .then((response) => {
              this.isLoading = false
              this.$refs.postForm.reset()
              this.$v.$reset()
              try {
                if (response.success) {
                  this.snackbarColor = 'success'
                  this.snackbar_text = i18n.t('message.add_success')
                  this.snackbar = true
                  setTimeout(() => {
                    this.close()
                  }, 3000)
                } else {
                  this.snackbarColor = 'red'
                  this.snackbar_text = i18n.t('message.error')
                  this.snackbar = true
                  setTimeout(() => {
                    this.close()
                  }, 3000)
                }
              } catch (error) {
                this.snackbarColor = 'red'
                this.snackbar_text = i18n.t('message.error')
                this.snackbar = true
                setTimeout(() => {
                  this.close()
                }, 3000)
              }
            })
            .catch((error) => {
              this.isLoading = false
              this.snackbarColor = 'red'
              this.snackbar_text = i18n.t('message.error')
              this.snackbar = true
            })
        }
        this.getPosts()
      }
    },
  },
}
</script>
