<template>
  <auth-layout>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex>
          <div class="row justify-content-center">
            <div class="col-md-12 col-lg-12">
              <div class="">
                <div class="login-wrap p-5 p-md-5">
                  <v-card-text class="text-center">
                    403 | This action is UNAUTHORIZED
                  </v-card-text>
                </div>
              </div>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </auth-layout>
</template>

<script>
import i18n from '../../i18n'
import { mapGetters } from 'vuex'
import AuthLayout from '../auth/AuthLayout'

export default {
  name: 'NotAuthorize',

  data() {
    return {}
  },
  components: {
    'auth-layout': AuthLayout,
  },
  methods: {},
  beforeCreate() {},
  created() {
    this.$root.$i18n.locale = this.langLocal
  },
  computed: {
    ...mapGetters(['errors', 'registered', 'countries', 'langLocal']),
  },
  watch: {},
}
</script>
<style scoped>
@import '../../../public/assets/css/style.css';

#contact-grid {
  width: 100%;
  min-height: 50vh;
}

.mobile-position {
  margin: 10px;
}
.card-title {
  color: rgb(63, 53, 99);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.w3l-footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  z-index: 100;
  width: 100%;
}

@media (max-width: 700px) {
}
</style>
