/* eslint-disable vue/no-side-effects-in-computed-properties */
<template>
  <MainLayout-component>
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-12">
        <div class="text-center mt-2">
          <v-card
            class="d-flex align-center justify-center mt-4 pa-4 mx-auto mb-4"
            min-height="96"
            outlined
          >
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <div class="text-center">{{ $t("title.recip_list") }}</div>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            class="d-flex align-center justify-center mt-4 pa-4 mx-auto mb-4"
            min-height="96"
            outlined
          >
            <v-row no-gutters>
              <v-snackbar
                v-model="snackbar"
                :color="snackbarColor"
                :timeout="3000"
                style="z-index: 1800"
                :top="true"
              >
                {{ snackbar_text }}
              </v-snackbar>
              <v-col cols="12" class="m-2" sm="3" md="3">
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_debut"
                       :label="$t('fields.date_start')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date_debut"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" class="m-2" sm="3" md="3">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_fin"
                      :label="$t('fields.enddate')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date_fin"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" class="m-2" sm="2" md="2">
                <v-btn
                  :disabled="$v.$invalid"
                  block
                  @click="search_date"
                  color="primary"
                  class="m-2"
                >
                  Filtrer
                </v-btn>
              </v-col>
              <v-col cols="12" class="m-2" sm="3" md="3">
                <v-btn
                  :disabled="cancel_search"
                  block
                  color="red"
                  class="text-white m-2"
                  @click="
                    cancel_search = true;
                    date_debut = null;
                    date_fin = null;
                  "
                >
                  {{ $t("btn.search_cancel") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </div>
        <v-col class="text-center" v-if="!cancel_search" cols="12" sm="12">
          <div style="background: #e0e0e0">
            <v-divider> </v-divider>
            <h4 style="color: #4a4343; margin-left: 10px">
              {{ $t("title.search_result") }}
            </h4>
            <v-divider> </v-divider>
          </div>
        </v-col>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('fields.search_text')"
              single-line
              hide-details
              class="mr-5"
            ></v-text-field>
          </v-col>

          <v-col cols="12" v-for="item in unite_list" :key="item.id">
            <unite-details :unite="item"> </unite-details>
          </v-col>
        </v-row>
      </div>
    </div>
  </MainLayout-component>
</template>
<script>
import MainLayout from "../MainLayout.vue";
import UniteDetails from "../../components/UniteDetails.vue";
import { mapGetters } from "vuex";
import i18n from "../../i18n";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "RecettesList",

  components: {
    "MainLayout-component": MainLayout,
    "unite-details": UniteDetails,
  },
  mixins: [validationMixin],
  validations: {
    date_debut: { required },
    date_fin: { required },
  },
  data: () => ({
    cancel_search: true,
    menu1: false,
    menu2: false,
    snackbar: false,
    snackbarColor: "default",
    snackbar_text: "",
    unite_list: [],
    unite_list_filtre: [],
    unite_list_no_filtre: [],
    date_fin: null,
    date_debut: null,
    search: null,
  }),
  watch: {
    search(val) {
      if (val) {
        this.unite_list = this.unite_list_filtre.filter(function (element) {
          return element.name.toLowerCase().includes(val.toLowerCase());
        });
      } else {
        this.unite_list = this.unite_list_no_filtre;
      }
    },
  },
  computed: {
    getHeaders() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.headers = [
        {
          text: "Date",
          align: "center",
          value: "pays_origine",
        },
        {
          text: i18n.t("headers.country"),
          align: "center",
          value: "unite_traitement",
        },
        {
          text: i18n.t("headers.recettes"),
          align: "center",
          value: "recettes",
        },
      ];
      return this.headers;
    },

    ...mapGetters(["langLocal", "errors", "registered", "countries"]),
  },

  created() {
 this.$root.$i18n.locale = this.langLocal;
    this.getRecipes();
  },

  methods: {
    async getRecipes() {
      await this.$store
        .dispatch("getUnitesRecettes")
        .then((response) => {
          if (response.length != null) {
            this.unite_list = response;
            this.unite_list_filtre = response;
            this.unite_list_no_filtre = response;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async search_date() {
      const data = new FormData();
      data.append("date_debut", this.date_debut);
      data.append("date_fin", this.date_fin);
      await this.$store
        .dispatch("getUnitesRecettesByDate", data)
        .then((response) => {
          console.log(response);
          if (response.success) {
            this.unite_list = response.data;
            this.unite_list_filtre = response.data;
            this.snackbarColor = "success";
            this.snackbar_text = i18n.t("message.search_success");
            this.snackbar = true;
          } else {
            this.snackbarColor = "red";
            this.snackbar_text = i18n.t("message.error");
            this.snackbar = true;
          }
          this.cancel_search = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
