/* eslint-disable vue/no-side-effects-in-computed-properties */
<template>
  <MainLayout-component>
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-12">
        <div class="text-center mt-2">
          <v-card
            class="d-flex align-center justify-center mt-4 pa-4 mx-auto mb-4"
            min-height="96"
            outlined
          >
            <div class="text-center">
              {{ $t("title.units_list") }}
            </div>
          </v-card>
          <v-snackbar
            v-model="editSnackbar"
            :color="editSnackbarColor"
            :timeout="3000"
            style="z-index: 1800"
            :top="true"
          >
            {{ snackbar_text }}
          </v-snackbar>
        </div>
        <v-data-table
          :headers="getHeaders"
          :items="unites"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items-per-page="5"
          class="elevation-2"
          :search="search"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title></v-toolbar-title>

              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('fields.search_text')"
                single-line
                hide-details
                class="mr-5"
              ></v-text-field>

              <v-dialog
                persistent
                :eager="true"
                v-model="dialog"
                style="z-index: 1500"
                max-width="1500px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $t("btn.add_unit") }}
                  </v-btn>
                </template>
                <v-card>
                  <div class="text-center">
                    <v-snackbar
                      v-model="snackbar"
                      :color="snackbarColor"
                      :timeout="3000"
                      :top="true"
                    >
                      {{ snackbar_text }}
                    </v-snackbar>
                  </div>
                  <v-card-title class="m-2">
                    <v-row class="p-3">
                      <v-col cols="12" sm="12">
                        <div style="background: #e0e0e0">
                          <v-divider> </v-divider>
                          <h4
                            style="
                              text-align: center;
                              color: #4a4343;
                              margin: 20px;
                            "
                          >
                            {{ $t("title.add_unit") }}
                          </h4>
                          <v-divider> </v-divider>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-title>

                  <v-card-text>
                    <v-container fluid fill-height>
                      <v-form :eager="true" ref="postForm">
                        <v-row>
                          <v-alert v-if="message" type="error">
                            {{ message }}
                          </v-alert>

                          <v-col cols="12">
                            <v-autocomplete
                              required
                              v-model="editedItem.type_unite_traitement"
                              @blur="
                                $v.editedItem.type_unite_traitement.$touch()
                              "
                              :items="typespost"
                              :label="$t('fields.post_type')"
                              :error-messages="typePosteErrors"
                              item-text="designation"
                              item-value="id"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              required
                              v-model="editedItem.jours"
                              @blur="$v.editedItem.jours.$touch()"
                              :items="jours_items"
                              :label="$t('fields.jours')"
                              :error-messages="joursErrors"
                              item-text="name"
                              item-value="id"
                              multiple
                            ></v-autocomplete>
                          </v-col>
                          <v-col>
                            <!-- <v-autocomplete
                              class="d-flex ml-auto"
                              :disabled="country_desabled"
                              cols="12"
                              v-model="editedItem.pays"
                              :items="birthCountries"
                              item-text="nom_fr_fr"
                              item-value="nom_fr_fr"
                              filled
                              @blur="$v.editedItem.pays.$touch()"
                              required
                              :label="$t('headers.country')"
                              :error-messages="paysErrors"
                            ></v-autocomplete> -->
                            <v-autocomplete
                              class="d-flex ml-auto"
                              :disabled="country_desabled"
                              cols="12"
                              v-model="editedItem.pays"
                              :items="birthCountries"
                              item-text="nom_fr_fr"
                              item-value="nom_fr_fr"
                              filled
                              @blur="$v.editedItem.pays.$touch()"
                              required
                              :label="$t('headers.country')"
                              :error-messages="paysErrors"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-row>
                              <v-col cols="12" sm="12">
                                <div style="background: #e0e0e0">
                                  <v-divider> </v-divider>
                                  <h4 style="color: #4a4343; margin-left: 10px">
                                    {{ $t("title.morning_work") }}
                                  </h4>
                                  <v-divider> </v-divider>
                                </div>
                              </v-col>

                              <v-col cols="12" md="3">
                                <h4 style="text-align: left; color: #4a4343">
                                  {{ $t("title.opening") }}
                                </h4>
                              </v-col>
                              <v-col cols="12" md="9">
                                <v-menu
                                  ref="menu1"
                                  v-model="menu1"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  :return-value.sync="editedItem.matinopen"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="editedItem.matinopen"
                                      :label="$t('fields.matinopen')"
                                      prepend-icon="mdi-clock-time-four-outline"
                                      readonly
                                      v-bind="attrs"
                                      @blur="$v.editedItem.matinopen.$touch()"
                                      v-on="on"
                                      :error-messages="matinopenErrors"
                                    ></v-text-field>
                                  </template>
                                  <v-time-picker
                                    format="24hr"
                                    min="07:00"
                                    max="20:59"
                                    v-if="menu1"
                                    v-model="editedItem.matinopen"
                                    full-width
                                    @click:minute="
                                      $refs.menu1.save(editedItem.matinopen)
                                    "
                                  ></v-time-picker>
                                </v-menu>
                              </v-col>
                              <v-col cols="12" md="3">
                                <h4 style="text-align: left; color: #4a4343">
                                  {{ $t("title.closing") }}
                                </h4>
                              </v-col>
                              <v-col cols="12" md="9">
                                <v-menu
                                  ref="menu2"
                                  v-model="menu2"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  :return-value.sync="editedItem.matinclose"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="editedItem.matinclose"
                                      @blur="$v.editedItem.matinclose.$touch()"
                                      :label="$t('fields.matinclose')"
                                      prepend-icon="mdi-clock-time-four-outline"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      :error-messages="matincloseErrors"
                                    ></v-text-field>
                                  </template>
                                  <v-time-picker
                                    format="24hr"
                                    min="07:00"
                                    max="20:59"
                                    v-if="menu2"
                                    v-model="editedItem.matinclose"
                                    full-width
                                    @click:minute="
                                      $refs.menu2.save(editedItem.matinclose)
                                    "
                                  ></v-time-picker>
                                </v-menu>
                              </v-col> </v-row
                          ></v-col>
                          <v-col cols="12">
                            <v-row>
                              <v-col cols="12" sm="12">
                                <div style="background: #e0e0e0">
                                  <v-divider> </v-divider>
                                  <h4 style="color: #4a4343; margin-left: 10px">
                                    {{ $t("title.evening_work") }}
                                  </h4>
                                  <v-divider> </v-divider>
                                </div>
                              </v-col>

                              <v-col cols="12" md="3">
                                <h4 style="text-align: left; color: #4a4343">
                                  {{ $t("title.opening") }}
                                </h4>
                              </v-col>
                              <v-col cols="12" md="9">
                                <v-menu
                                  ref="menu3"
                                  v-model="menu3"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  :return-value.sync="editedItem.soiropen"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="editedItem.soiropen"
                                      :label="$t('fields.soiropen')"
                                      prepend-icon="mdi-clock-time-four-outline"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      @blur="$v.editedItem.soiropen.$touch()"
                                      :error-messages="soiropenErrors"
                                    ></v-text-field>
                                  </template>
                                  <v-time-picker
                                    format="24hr"
                                    min="07:00"
                                    max="20:59"
                                    v-if="menu3"
                                    v-model="editedItem.soiropen"
                                    full-width
                                    @click:minute="
                                      $refs.menu3.save(editedItem.soiropen)
                                    "
                                  ></v-time-picker>
                                </v-menu>
                              </v-col>
                              <v-col cols="12" md="3">
                                <h4 style="text-align: left; color: #4a4343">
                                  {{ $t("title.closing") }}
                                </h4>
                              </v-col>
                              <v-col cols="12" md="9">
                                <v-menu
                                  ref="menu4"
                                  v-model="menu4"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  :return-value.sync="editedItem.soirclose"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="editedItem.soirclose"
                                      :label="$t('fields.soirclose')"
                                      prepend-icon="mdi-clock-time-four-outline"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      @blur="$v.editedItem.soirclose.$touch()"
                                      :error-messages="soircloseErrors"
                                    ></v-text-field>
                                  </template>
                                  <v-time-picker
                                    format="24hr"
                                    min="07:00"
                                    max="20:59"
                                    v-if="menu4"
                                    v-model="editedItem.soirclose"
                                    full-width
                                    @click:minute="
                                      $refs.menu4.save(editedItem.soirclose)
                                    "
                                  ></v-time-picker>
                                </v-menu>
                              </v-col> </v-row
                          ></v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              required
                              v-model="editedItem.intervalRdv"
                              @blur="$v.editedItem.intervalRdv.$touch()"
                              :items="interval_items"
                              :label="$t('fields.interval')"
                              :error-messages="intervalRdvErrors"
                              item-text="name"
                              item-value="value"
                            ></v-autocomplete>
                          </v-col>

                          <v-col cols="12">
                            <v-text-field
                              required
                              v-model="editedItem.nombre_agent_traitant"
                              single-line
                              :error-messages="nombreAgentErrors"
                              type="number"
                              min="1"
                              max="20"
                              @blur="
                                $v.editedItem.nombre_agent_traitant.$touch()
                              "
                              :label="$t('fields.nombre_agent')"
                            />
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="red lighten-1"
                      class="text-white"
                      @click="close"
                    >
                      {{ $t("btn.cancel") }}
                    </v-btn>
                    <v-btn
                      :loading="isLoading"
                      color="primary"
                      large
                      @click.prevent="save"
                      type="submit"
                      :disabled="can_submit"
                    >
                      {{ $t("btn.register") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                persistent
                style="z-index: 1500"
                v-model="dialogBlock"
                max-width="500px"
              >
                <v-card>
                  <v-card-title
                    class="text-center"
                    style="text-align: center"
                    v-if="unites[editedIndex]"
                  >
                    {{ unites[editedIndex].ville }}
                  </v-card-title>
                  <p class="text-center text-h8can_submit p-2">
                    {{ $t("validations.confirmation_action") }}
                  </p>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeBlock">
                      {{ $t("btn.cancel") }}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      :loading="isLoading"
                      @click="blockOrDeblockUnit"
                    >
                      {{ $t("btn.valide") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon
              color="red"
              v-if="item.deleted_at != null"
              small
              @click="blockPost(item)"
            >
              mdi-lock
            </v-icon>
            <v-icon
              color="green"
              v-if="item.deleted_at == null"
              small
              @click="blockPost(item)"
            >
              mdi-lock-open
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-col>
              <i style="font-size: 30px" class="fa-solid fa-circle-info"></i>
              <p>{{ $t("title.no_data") }}</p>
            </v-col>
            <v-col>
              <v-btn text color="primary" @click="getUnites">{{
                $t("btn.reset")
              }}</v-btn>
            </v-col>
          </template>
        </v-data-table>
      </div>
    </div>
  </MainLayout-component>
</template>
<script>
import MainLayout from "../MainLayout.vue";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import i18n from "../../i18n";

export default {
  name: "PostList",
  mixins: [validationMixin],
  validations: {
    editedItem: {
      pays: { required },
      type_unite_traitement: { required },
      jours: { required },
      intervalRdv: { required },
      matinopen: { required },
      matinclose: { required },
      soiropen: { required },
      soirclose: { required },
      nombre_agent_traitant: { required },
    },
  },
  components: {
    "MainLayout-component": MainLayout,
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogBlock(val) {
      val || this.closeBlock();
    },
    "editedItem.jours"(val) {
      console.log(val);
    },
  },
  data: () => ({
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    interval_items: [
      {
        name: " 5 Minutes",
        value: "5",
      },
      {
        name: " 10 Minutes",
        value: "10",
      },
      {
        name: " 15 Minutes",
        value: "15",
      },
      {
        name: " 20 Minutes",
        value: "20",
      },
      {
        name: " 25 Minutes",
        value: "25",
      },
      {
        name: " 30 Minutes",
        value: "30",
      },
    ],
    jours_items: [
      {
        name: "LUNDI",
        id: 0,
      },
      {
        name: "MARDI",
        id: 1,
      },
      {
        name: "MERCREDI",
        id: 2,
      },
      {
        name: "JEUDI",
        id: 3,
      },
      {
        name: "VENDREDI",
        id: 4,
      },
      {
        name: "SAMEDI",
        id: 5,
      },
      {
        name: "DIMANCHE",
        id: 6,
      },
    ],
    country_desabled: null,
    unites_items: null,
    message: null,
    isLoading: false,
    snackbar: false,
    editSnackbar: false,
    snackbarColor: "default",
    editSnackbarColor: "default",
    snackbar_text: "",
    search: null,
    sortBy: "created_at",
    sortDesc: true,

    dialog: false,
    dialogBlock: false,
    headers: [],
    unites: [],
    editedIndex: -1,
    editedItem: {
      id: null,
      pays: null,
      type_unite_traitement: null,
      intervalRdv: null,
      matinopen: null,
      matinclose: null,
      soiropen: null,
      soirclose: null,
      nombre_agent_traitant: null,
    },
    defaultItem: {
      id: null,
      pays: null,
      type_unite_traitement: null,
      intervalRdv: null,
      matinopen: null,
      matinclose: null,
      soiropen: null,
      soirclose: null,
      nombre_agent_traitant: null,
    },
  }),

  created() {
     this.$root.$i18n.locale = this.langLocal;
    this.getUnites();
    this.$store.dispatch("getBirthCountries");
    this.$store.dispatch("getTypesPost");
  },
  methods: {
    uppercase() {
      if (this.editedItem.pays) {
        this.editedItem.pays = this.editedItem.pays.toUpperCase();
      }
    },
    getUnitTraitementId(name) {
      let type_post = this.typespost.filter(function (element) {
        return element.designation == name;
      });
      return type_post[0].id;
    },
    async blockOrDeblockUnit() {
      this.isLoading = false;
      if (
        this.editedIndex > -1 &&
        this.unites[this.editedIndex]["deleted_at"] == null
      ) {
        const data = new FormData();
        data.append("id", this.unites[this.editedIndex].id);
        data.append("bloque", true);
        await this.$store
          .dispatch("updateUnitTrait", data)
          .then((response) => {
            this.isLoading = false;
            this.editSnackbarColor = "success";
            this.snackbar_text = i18n.t("message.update_success");
            this.editSnackbar = true;
            console.log(this.snackbar);
          })
          .catch((error) => {
            this.isLoading = false;
            this.editSnackbarColor = "red";
            this.snackbar_text = i18n.t("message.error");
            this.editSnackbar = true;
          });
      }
      if (
        this.editedIndex > -1 &&
        this.unites[this.editedIndex]["deleted_at"] != null
      ) {
        const data = new FormData();
        data.append("id", this.unites[this.editedIndex].id);
        data.append("debloque", true);
        await this.$store
          .dispatch("updateUnitTrait", data)
          .then((response) => {
            this.isLoading = false;
            this.editSnackbarColor = "success";
            this.snackbar_text = i18n.t("message.update_success");
            this.editSnackbar = true;
          })
          .catch((error) => {
            this.isLoading = false;
            this.editSnackbarColor = "red";
            this.snackbar_text = i18n.t("message.error");
            this.editSnackbar = true;
          });
      }
      this.dialogBlock = false;
      this.getUnites();
    },
    blockPost(item) {
      this.editedIndex = this.unites.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogBlock = true;
    },

    async getUnites() {
      await this.$store
        .dispatch("getUnites")
        .then((response) => {
          this.unites = response;

          console.log(this.unites);
          this.unites = this.unites.map((e) =>
            Object.assign(e, {
              deleted_at: e.deleted_at,
              id: e.id,
              pays: e.pays,
              name: e.name,
              type_unite_traitement: this.getUnitTraitementId(e.type_poste),
              type_poste_text: e.type_poste,
              ville: e.ville,
              configrdv: JSON.parse(e.configrdv),
              nombre_agent_traitant: e.nombre_agent_traitant,
            })
          );
        })
        .catch((error) => {
          console.error(error);
        });
    },
    editItem(item) {
      this.country_desabled = true;
      this.editedIndex = this.unites.indexOf(item);
      this.editedItem = Object.assign({}, item);

      if (this.editedItem.configrdv) {
        this.editedItem.intervalRdv = this.editedItem.configrdv.intervalRdv;
        this.editedItem.jours = this.editedItem.configrdv.jours;
        this.editedItem.matinclose = this.editedItem.configrdv.matinclose;
        this.editedItem.matinopen = this.editedItem.configrdv.matinopen;
        this.editedItem.soirclose = this.editedItem.configrdv.soirclose;
        this.editedItem.soiropen = this.editedItem.configrdv.soiropen;
      }

      this.dialog = true;
    },

    blockAgentConfirm() {
      this.unites.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.country_desabled = false;
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeBlock() {
      this.dialogBlock = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    async save() {
      if (this.editedIndex > -1) {
        Object.assign(this.unites[this.editedIndex], this.editedItem);
        this.isLoading = true;
        this.$v.$touch();
        const data = new FormData();

        data.append(
          "type_unite_traitement",
          this.editedItem.type_unite_traitement
        );
        data.append("pays", this.editedItem.pays);
        data.append("matinopen", this.editedItem.matinopen);
        data.append("matinclose", this.editedItem.matinclose);
        data.append("soiropen", this.editedItem.soiropen);
        data.append("soirclose", this.editedItem.soirclose);
        data.append("jours", JSON.stringify(this.editedItem.jours));
        data.append("intervalRdv", this.editedItem.intervalRdv);
        data.append("id", this.unites[this.editedIndex].id);
        data.append(
          "nombre_agent_traitant",
          this.editedItem.nombre_agent_traitant
        );

        if (!this.$v.$invalid) {
          await this.$store
            .dispatch("updateUnitTrait", data)
            .then((response) => {
              if (response.success) {
                this.isLoading = false;
                this.snackbarColor = "success";
                this.snackbar_text = i18n.t("message.update_success");
                this.snackbar = true;
              } else {
                this.isLoading = false;
                this.snackbarColor = "red";
                this.snackbar_text = i18n.t("message.error");
                this.snackbar = true;
              }
            })
            .catch((error) => {
              this.isLoading = false;
              this.snackbarColor = "red";
              this.snackbar_text = i18n.t("message.error");
              this.snackbar = true;
            });
        }
        this.getUnites();
      } else {
        this.isLoading = true;
        this.$v.$touch();
        const data = new FormData();
        data.append(
          "type_unite_traitement",
          this.editedItem.type_unite_traitement
        );
        data.append("pays", this.editedItem.pays);
        data.append("matinopen", this.editedItem.matinopen);
        data.append("matinclose", this.editedItem.matinclose);
        data.append("soiropen", this.editedItem.soiropen);
        data.append("soirclose", this.editedItem.soirclose);
        data.append("jours", JSON.stringify(this.editedItem.jours));
        data.append("intervalRdv", this.editedItem.intervalRdv);
        data.append(
          "nombre_agent_traitant",
          this.editedItem.nombre_agent_traitant
        );

        if (!this.$v.$invalid) {
          await this.$store
            .dispatch("addUnitTrait", data)
            .then((response) => {
              console.log("zrezgerzeae", response);
              this.isLoading = false;
              this.$refs.postForm.reset();
              this.$v.$reset();
              try {
                if (response.data.success) {
                  this.isLoading = false;

                  this.snackbarColor = "success";
                  this.snackbar_text = i18n.t("message.add_success");
                  this.snackbar = true;
                } else {
                  this.isLoading = false;

                  this.snackbarColor = "red";
                  this.snackbar_text = i18n.t("message.error");
                  this.snackbar = true;
                }
              } catch (error) {
                this.isLoading = false;
                this.snackbarColor = "red";
                this.snackbar_text = i18n.t("message.error");
                this.snackbar = true;
              }
            })
            .catch((error) => {
              this.isLoading = false;
              this.snackbarColor = "red";
              this.snackbar_text = i18n.t("message.error");
              this.snackbar = true;
            });
        }
        this.isLoading = false;
        this.getUnites();
      }
    },
  },

  computed: {
    can_submit() {
      if (
        this.$v.editedItem.type_unite_traitement.$invalid ||
        this.$v.editedItem.pays.$invalid ||
        this.$v.editedItem.matinopen.$invalid ||
        this.$v.editedItem.matinclose.$invalid ||
        this.$v.editedItem.soiropen.$invalid ||
        this.$v.editedItem.soirclose.$invalid ||
        this.$v.editedItem.jours.$invalid ||
        this.$v.editedItem.intervalRdv.$invalid ||
        this.$v.editedItem.nombre_agent_traitant.$invalid
      ) {
        return true;
      } else {
        return false;
      }
    },
    getHeaders() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.headers = [
        {
          text: i18n.t("headers.country"),
          align: "",
          value: "pays",
        },
        {
          text: i18n.t("headers.unit_type"),
          align: "",
          value: "type_poste_text",
        },
        { text: "Actions", value: "actions" },
      ];
      return this.headers;
    },

    paysErrors() {
      const errors = [];
      if (!this.$v.editedItem.pays.$dirty) return errors;
      !this.$v.editedItem.pays.required &&
        errors.push(i18n.t("validations.pays_required"));
      return errors;
    },
    typePosteErrors() {
      const errors = [];
      if (!this.$v.editedItem.type_unite_traitement.$dirty) return errors;
      !this.$v.editedItem.type_unite_traitement.required &&
        errors.push(i18n.t("validations.type_poste_required"));
      return errors;
    },
    joursErrors() {
      const errors = [];
      if (!this.$v.editedItem.jours.$dirty) return errors;
      !this.$v.editedItem.jours.required &&
        errors.push(i18n.t("validations.jours_required"));
      return errors;
    },
    intervalRdvErrors() {
      const errors = [];
      if (!this.$v.editedItem.intervalRdv.$dirty) return errors;
      !this.$v.editedItem.intervalRdv.required &&
        errors.push(i18n.t("validations.intervalRdv_required"));
      return errors;
    },

    matinopenErrors() {
      const errors = [];
      if (!this.$v.editedItem.matinopen.$dirty) return errors;
      !this.$v.editedItem.matinopen.required &&
        errors.push(i18n.t("validations.matinopen_required"));
      return errors;
    },

    matincloseErrors() {
      const errors = [];
      if (!this.$v.editedItem.matinclose.$dirty) return errors;
      !this.$v.editedItem.matinclose.required &&
        errors.push(i18n.t("validations.matinclose_required"));
      return errors;
    },

    soiropenErrors() {
      const errors = [];
      if (!this.$v.editedItem.soiropen.$dirty) return errors;
      !this.$v.editedItem.soiropen.required &&
        errors.push(i18n.t("validations.soiropen_required"));
      return errors;
    },

    soircloseErrors() {
      const errors = [];
      if (!this.$v.editedItem.soirclose.$dirty) return errors;
      !this.$v.editedItem.soirclose.required &&
        errors.push(i18n.t("validations.soirclose_required"));
      return errors;
    },

    nombreAgentErrors() {
      const errors = [];
      if (!this.$v.editedItem.nombre_agent_traitant.$dirty) return errors;
      !this.$v.editedItem.nombre_agent_traitant.required &&
        errors.push(i18n.t("validations.nombre_agent_traitant_required"));
      return errors;
    },
    ...mapGetters(["errors", "registered", "birthCountries", "typespost","langLocal"]),
  },
};
</script>
