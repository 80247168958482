/* eslint-disable vue/no-side-effects-in-computed-properties */
<template>
  <MainLayout-component>
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-12">
        <div class="text-center mt-2">
          <v-card
            class="d-flex align-center justify-center mt-4 pa-4 mx-auto mb-4"
            min-height="96"
            outlined
          >
            <div class="text-center">{{ $t("title.appointment_list") }}</div>
          </v-card>
        </div>
        <v-row>
          <v-col cols="12" md="12" sm="12">
            <v-radio-group v-model="radios" class="mx-3 mt-5 pt-2" row>
              <v-row>
                <v-col cols="12" md="3" sm="6">
                  <v-radio
                    color="green"
                    name="radios"
                    :label="$t('title.today')"
                    value="joursj"
                  ></v-radio>
                </v-col>

                <v-col cols="12" md="3" sm="6">
                  <v-radio
                    color="primary"
                    name="radios"
                    :label="$t('title.all')"
                    value="all"
                  ></v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-data-table
          :headers="getHeaders"
          :items="listrdv"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items-per-page="15"
          class="elevation-2"
          :search="search"
        >
          <template v-slot:[`item.unite`]="{ item }">
            {{ item.pays + "--" + item.designation }}
          </template>

          <template v-slot:[`item.date_heure_rdv`]="{ item }">
            {{ formatDate(item.date_heure_rdv) }}
          </template>

          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title></v-toolbar-title>

              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('fields.search_text')"
                single-line
                hide-details
                class="mr-5"
              ></v-text-field>

              <template v-slot:no-data>
                <v-col>
                  <i
                    style="font-size: 30px"
                    class="fa-solid fa-circle-info"
                  ></i>
                  <p>{{ $t("title.no_data") }}</p>
                </v-col>
                <v-col>
                  <v-btn text color="primary" @click="getPosts">{{
                    $t("btn.reset")
                  }}</v-btn>
                </v-col>
              </template>
            </v-toolbar>
          </template>
        </v-data-table>
      </div>
    </div>
  </MainLayout-component>
</template>
<script>
import MainLayout from "../MainLayout.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import i18n from "../../i18n";

export default {
  name: "ListRDV",
  components: {
    "MainLayout-component": MainLayout,
  },

  data: () => ({
    headers: [],
    listrdv: [],
    withoutfiltre: [],
    withfiltre: [],
    sortBy: "date_heure_rdv",
    sortDesc: true,
    search: null,
    radios: "joursj",
  }),
  computed: {
    getHeaders() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.headers = [
        {
          text: i18n.t("auth.lastname"),
          align: "",
          value: "nom",
        },
        {
          text: i18n.t("auth.firstname"),
          align: "",
          value: "prenom",
        },
        {
          // text: "Heure rdv",
         text: i18n.t("auth.heure_rdv"),
          align: "center",
          value: "date_heure_rdv",
        },
        {
          text: i18n.t("headers.unit"),
          align: "",
          value: "unite",
        },
      ];
      return this.headers;
    },

    ...mapGetters(["langLocal", "errors", "registered", "countries","langLocal"]),
  },

  created() {
    this.getRDV();
    this.radios = "joursj";
    this.$root.$i18n.locale = this.langLocal;
    moment.locale(this.langLocal);
  },
  watch: {
    langLocal(val) {
      moment.locale(val);
    },
    radios(val) {
      if (val == "joursj") {
        this.radios = "joursj";
        this.listrdv = this.withfiltre;
      } else if (val == "all") {
        this.radios = "all";
        this.listrdv = this.withoutfiltre;
      }
    },
  },
  methods: {
    formatDate(str) {
      return moment(str).subtract(0, "hour").format("L LTS");
    },

    async getRDV() {
      await this.$store
        .dispatch("getListRDV")
        .then((response) => {
          if (response.data) {
            this.listrdv = response.data;
            this.withoutfiltre = response.data;

            let today = new Date();
            const yyyy = today.getFullYear();
            let mm = today.getMonth() + 1; // Months start at 0!
            let dd = today.getDate();

            if (dd < 10) dd = "0" + dd;
            if (mm < 10) mm = "0" + mm;

            today = yyyy + "-" + mm + "-" + dd;

            let joursj = this.listrdv.filter(function (element) {
              return element.date_heure_rdv.substring(0, 10) == today;
            });

            this.withfiltre = joursj;

            this.listrdv = joursj;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
