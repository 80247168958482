<template>
  <v-container fluid fill-height class="main">
    <v-container>
      <v-main>
        <div
          style="vertical-align: middle"
          class="mx-auto div-lang text-center">
          <v-btn
            @click="changeLanguage(item.id)"
            id="lang-btn"
            text
            class="white--text mt-4"
            v-for="item in languages"
            :key="item.id"
            >{{ item.langue
            }}<flag :iso="item.flag" class="white-text" :class="['m-2']" />
          </v-btn>
        </div>
        <slot />
      </v-main>
    </v-container>
  </v-container>
</template>

<script>
import TheFooter from "@/components/TheFooter";
import TheHeader from "@/components/TheHeader";
import { mapGetters } from "vuex";
export default {
  name: "MainLayout",
  props: {},
  data: () => ({
    languages: [
      { id: 0, name: "fr", flag: "fr", langue: "Français" },
      { id: 1, name: "en", flag: "us", langue: "English" },
    ],
  }),
  components: {
    TheFooter: TheFooter,
    TheHeader: TheHeader,
  },
  methods: {
    changeLanguage(id) {
      this.$store.dispatch("setlanguage", this.languages[id]["name"]);
    },
  },
  created () {
   this.$root.$i18n.locale = this.langLocal;
  },
  computed: {
       ...mapGetters(["errors", "registered", "langLocal", "countries"]),
  }
  
};
</script>
<style scoped>
.div-lang {
  background-color: rgb(37, 51, 114);
  height: 18%;
  resize: both;
  overflow: auto;
  padding: 0px;
  max-width: 40%;
  border-radius: 4px;
}
#lang-btn {
  border: none;
  outline: none;
  font-size: 15px;
}
.lang-container {
  padding: 0px;
  padding-right: 10px;
}
.main {
  background: linear-gradient(
    110deg,
    #fdcd3b 60%,
    rgba(39, 44, 89, 0.7) 60%
  ) !important;
  /* background: rgba(39, 44, 89,0.7) !important; */
  /* background: linear-gradient(3deg, #40795D,#AF272D, #EFD62B) !important;  */
}

@media (max-width: 700px) {
  .div-lang {
      resize: both;
  overflow: auto;
    background-color: rgb(37, 51, 114);
    height: 18%;
    padding: 0px;
    max-width: 100%;
    border-radius: 4px;
  }
}
</style>
