/* eslint-disable vue/no-side-effects-in-computed-properties */
<template>
  <MainLayout-component>
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-12">
        <div class="text-center mt-2">
          <v-card
            class="d-flex align-center justify-center mt-4 pa-4 mx-auto mb-4"
            min-height="96"
            outlined
          >
            <div class="text-center">{{ $t("title.visa_list") }}</div>
          </v-card>
        </div>
        <v-row>
          <v-col cols="12" md="12" sm="12">
            <v-radio-group v-model="radios" class="mx-3 mt-5 pt-2" row>
              <v-row>
                <v-col cols="6" md="3" sm="3">
                  <v-radio
                    color="green"
                    name="radios"
                    :label="$t('title.radio_visa_pending')"
                    value="visas_encours_validite"
                  ></v-radio>
                </v-col>

                <v-col cols="6" md="3" sm="3">
                  <v-radio
                    color="red"
                    name="radios"
                    :label="$t('title.radio_visa_expired')"
                    value="visas_expire"
                  ></v-radio>
                </v-col>

                <v-col cols="6" md="3" sm="3">
                  <v-radio
                    color="info"
                    name="radios"
                    :label="$t('title.radio_visa_valided')"
                    value="visas_valide"
                  ></v-radio>
                </v-col>

                <v-col cols="6" md="3" sm="3">
                  <v-radio
                    color="red"
                    name="radios"
                    :label="$t('title.radio_visa_rejected')"
                    value="visas_rejeter"
                  ></v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-data-table
          :headers="getHeaders"
          :items="listvisas"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items-per-page="15"
          class="elevation-2"
          :search="search"
        >
          <template v-slot:[`item.date_depart`]="{ item }">
            {{ formatDate(item.date_depart) }}
          </template>

          <template v-slot:[`item.date_retour`]="{ item }">
            {{ formatDate(item.date_retour) }}
          </template>

          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title></v-toolbar-title>

              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('fields.search_text')"
                single-line
                hide-details
                class="mr-5"
              ></v-text-field>

              <template v-slot:no-data>
                <v-col>
                  <i
                    style="font-size: 30px"
                    class="fa-solid fa-circle-info"
                  ></i>
                  <p>Aucune donné</p>
                </v-col>
                <v-col>
                  <v-btn text color="primary" @click="getPosts">{{
                    $t("btn.reset")
                  }}</v-btn>
                </v-col>
              </template>
            </v-toolbar>
          </template>
        </v-data-table>
      </div>
    </div>
  </MainLayout-component>
</template>
<script>
import MainLayout from "../MainLayout.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import i18n from "../../i18n";

export default {
  name: "ListRDV",
  components: {
    "MainLayout-component": MainLayout,
  },

  data: () => ({
    headers: [],
    listvisas: [],
    visas_expire: [],
    visas_encours_validite: [],
    visas_valide: [],
    visas_rejeter: [],
    sortBy: null,
    sortDesc: true,
    search: null,
    radios: "visas_encours_validite",
  }),
  computed: {
    getHeaders() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.headers = [
        {
          text: i18n.t("fields.passportnumber"),
          align: "",
          value: "numpasseport",
        },
        {
          text: i18n.t("auth.lastname"),
          align: "",
          value: "nom",
        },
        {
          text: i18n.t("auth.firstname"),
          align: "",
          value: "prenom",
        },
        {
          text: i18n.t("fields.phonenumber"),
          align: "",
          value: "telephone",
        },
        {
          text: i18n.t("fields.date_start"),
          align: "",
          value: "date_depart",
        },
        {
          text: i18n.t("fields.date_end"),
          align: "",
          value: "date_retour",
        },
      ];
      return this.headers;
    },

    ...mapGetters(["langLocal", "errors", "registered", "countries"]),
  },

  created() {
    this.getListVisas();
    this.radios = "visas_encours_validite";
 this.$root.$i18n.locale = this.langLocal;
    moment.locale(this.langLocal);
  },
  watch: {
    langLocal(val) {
      moment.locale(val);
    },
    radios(val) {
      if (val == "visas_encours_validite") {
        this.radios = "visas_encours_validite";
        this.listvisas = this.visas_encours_validite;
      } else if (val == "visas_expire") {
        this.radios = "visas_expire";
        this.listvisas = this.visas_expire;
      } else if (val == "visas_valide") {
        this.radios = "visas_valide";
        this.listvisas = this.visas_valide;
      } else if (val == "visas_rejeter") {
        this.radios = "visas_rejeter";
        this.listvisas = this.visas_rejeter;
      }
    },
  },
  methods: {
    formatDate(str) {
      return moment(str).subtract(0, "hour").format("L");
    },

    async getListVisas() {
      await this.$store
        .dispatch("getListVisas")
        .then((response) => {
          if (response.data) {
            this.listvisas = response.data;

            this.visas_expire = this.listvisas.filter(function (element) {
              return (
                element.status_visa == "EXPIRER" &&
                element.status_demande == "FINAL"
              );
            });
            this.visas_encours_validite = this.listvisas.filter(function (
              element
            ) {
              return (
                element.status_visa == "VALIDER" &&
                element.status_demande == "FINAL"
              );
            });
            this.visas_valide = this.listvisas.filter(function (element) {
              return (
                element.status_demande == "FINAL" &&
                element.status_visa == "SANSFILTRE"
              );
            });
            this.visas_rejeter = this.listvisas.filter(function (element) {
              return (
                element.status_demande == "REJETER" &&
                element.status_visa == "SANSFILTRE"
              );
            });

            this.listvisas = this.visas_encours_validite;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
